import { Action, createReducer, on } from '@ngrx/store';
import { flightSearchFormsAdapter, FlightSearchFormsState, initialFlightSearchFormsState } from './flight-search-forms.state';
import {
  addUpdateFlightSearchDepartureDateData,
  addUpdateFlightSearchFormData,
  addUpdateFlightSearchFormRoutingData,
  initializeFlightSearchFormsState,
} from './flight-search-forms.actions';
import { FlightSearchForm } from '../../../models/flight-search-forms/flight-search-form';

/**
 * Helper function to update or add flight search form data
 */
function updateOrAddFlightSearchFormData(state: FlightSearchFormsState, action, updateFunction) {
  const { featureId, flightSearchFormData } = action;
  const existingFlightSearchForm = state.flightSearchForms.entities[featureId];

  if (!existingFlightSearchForm) {
    const newFlightSearchForm = {
      featureId,
      airports: [flightSearchFormData],
    };
    return {
      ...state,
      flightSearchForms: flightSearchFormsAdapter.setOne(newFlightSearchForm, state.flightSearchForms),
    };
  }

  let updatedForm = existingFlightSearchForm.airports?.map((airportForm) => updateFunction(airportForm, flightSearchFormData));

  const wasUpdated = updatedForm?.some((airportForm) => airportForm.id === flightSearchFormData.id);
  if (!wasUpdated) {
    updatedForm = updatedForm?.length > 0 ? updatedForm : [];
    updatedForm.push(flightSearchFormData);
  }

  const updatedFlightSearchForm = {
    ...existingFlightSearchForm,
    airports: updatedForm,
  };

  return {
    ...state,
    flightSearchForms: flightSearchFormsAdapter.setOne(updatedFlightSearchForm, state.flightSearchForms),
  };
}

/**
 * Handles all state changes, there is no way to change the state without a reducer AND the reducer never
 * modifies state, it clones the state from the store, changes that cloned state in some way, and then replaces
 * the entire old state with the cloned and modified state.
 */
const featureReducer = createReducer(
  initialFlightSearchFormsState,
  /**
   * Reinitialize the flight search forms state
   */
  on(initializeFlightSearchFormsState, (state) => ({ ...state, ...initialFlightSearchFormsState })),
  /**
   * Add/update a flight search form route/date data
   */
  on(addUpdateFlightSearchFormRoutingData, (state, action) =>
    updateOrAddFlightSearchFormData(state, action, (airportForm, flightSearchFormData) =>
      airportForm.id === flightSearchFormData.id
        ? {
            ...flightSearchFormData,
            departureDateValid: airportForm.departureDateValid,
            departureDateValue: airportForm.departureDateValue,
          }
        : airportForm
    )
  ),
  /**
   * Add/update a flight search form departure date data
   */
  on(addUpdateFlightSearchDepartureDateData, (state, action) =>
    updateOrAddFlightSearchFormData(state, action, (airportForm, flightSearchFormData) =>
      airportForm.id === flightSearchFormData.id
        ? {
            ...flightSearchFormData,
            departureAirportValid: airportForm.departureAirportValid,
            departureAirportValue: airportForm.departureAirportValue,
            arrivalAirportValid: airportForm.arrivalAirportValid,
            arrivalAirportValue: airportForm.arrivalAirportValue,
          }
        : airportForm
    )
  ),
  /**
   * Add/update a flight search form data
   */
  on(addUpdateFlightSearchFormData, (state, { featureId, flightSearchFormData }) => {
    const existingFlightSearchForm = state.flightSearchForms.entities[featureId];

    // If the flight search form does not exist, create a new one
    if (!existingFlightSearchForm) {
      const newFlightSearchForm = {
        featureId,
        data: [flightSearchFormData],
      };
      return {
        ...state,
        flightSearchForms: flightSearchFormsAdapter.setOne(newFlightSearchForm, state.flightSearchForms),
      };
    }

    // If the flight search form exists, update the data and keep the existing airport data
    const updatedForm: FlightSearchForm = {
      ...flightSearchFormData,
      airports: existingFlightSearchForm?.airports,
    };

    return {
      ...state,
      flightSearchForms: flightSearchFormsAdapter.setOne(updatedForm, state.flightSearchForms),
    };
  })
);

/**
 * Typescript safe way to export the reducer so that it can be imported in modules
 */
export function flightSearchFormsReducer(state = initialFlightSearchFormsState, action: Action) {
  return featureReducer(state, action);
}
