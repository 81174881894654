import { patternWithMessage } from './patternWithMessage';

const nameRegex = new RegExp(/^[a-zA-Z '-]*$/);
const nameMessage = `Allowed: A-Z - ' spaces`;
/** Validates a name, allows for A-Z, hyphens, spaces and single qoutes. Sets an error message if invalid
 * @example
 * Tom - valid
 * Jones-Smith - valid
 * O'Brien - valid
 * J0nes -invalid
 * TheLongestLastNameYouCouldEverPossiblyHopeToImagine - invalid
 */
export const nameValidator = patternWithMessage(nameRegex, nameMessage);

const alphaWithSpacesRegex = new RegExp(/^[a-zA-Z ]*$/);
const alphaWithSpacesMessage = `Allowed: A-Z spaces`;
/** Validates a name, allows for a-z, A-Z, and spaces. Sets an error message if invalid
 * @example
 * Tom - valid
 * Jones-Smith - valid
 * O'Brien - invalid
 * J0nes -invalid
 * TheLongestLastNameYouCouldEverPossiblyHopeToImagine - valid
 */
export const alphaWithSpacesValidator = patternWithMessage(alphaWithSpacesRegex, alphaWithSpacesMessage);

const alphaNumericRegex =  new RegExp(/^[a-zA-Z0-9]*$/);
const alphaNumericMessage = `Allowed: A-Z 0-9`;
/**
 * Validates that a string contains only alphanumeric characters
 * @example
 * Tom - valid
 * Jones-Smith - invalid
 * O'Brien - invalid
 * J0nes -valid
 * TheLongestLastNameYouCouldEverPossiblyHopeToImagine - valid
 */
export const alphaNumericValidator =  patternWithMessage(alphaNumericRegex, alphaNumericMessage);

const alphaNumericWithSpacesRegex = new RegExp(/^[A-Za-z0-9 ]+$/);
const alphaNumericWithSpacesMessage = `Allowed: A-Z 0-9 spaces`;
/**
 * Validates that a string contains only alphanumeric characters and spaces
 * @example
 * Guest is blind - valid
 * Some Remarks 1234 - valid
 * Remarks @1234$ - invalid
 */
export const alphaNumericWithSpacesValidator =  patternWithMessage(alphaNumericWithSpacesRegex, alphaNumericWithSpacesMessage);

const addressRegex = new RegExp(/^[A-Za-z0-9 .'#/-]*$/);
const addressMessage = `Allowed: A-Z 0-9 . ' # / - spaces`;
/** Validates address line, allows for letters, numbers, spaces, periods, single qoutes, hashtags, forward slash, and hyphen.
 * Sets an error message if invalid
 * @example
 * 123 Main Street - valid
 * Apt #122 - valid
 * 123 Some/Street - valid
 * 123 Champs-Elysees - valid
 * 123 O'Brien Street - valid
 * 123 Main $treet - invalid
 */
export const addressValidator = patternWithMessage(addressRegex, addressMessage);

const contactTracingAddressRegex = new RegExp(/^[A-Za-z0-9 ]*$/);
const contactTracingAddressMessage = `Allowed: A-Z 0-9 spaces`;
/** Validates address line, allows for letters, numbers, spaces, periods, single qoutes, hashtags, forward slash, and hyphen.
 * Sets an error message if invalid
 * @example
 * 123 Main Street - valid
 * Apt #122 - valid
 * 123 Some/Street - valid
 * 123 Champs-Elysees - valid
 * 123 O'Brien Street - valid
 * 123 Main $treet - invalid
 */
export const contactTracingAddressValidator = patternWithMessage(contactTracingAddressRegex, contactTracingAddressMessage);

/**
 * Validates a city name, allows letters, spaces, hyphens, and single qoutes
 */
const cityRegex = new RegExp(/^[a-zA-Z]+(?:[ \-'][a-zA-Z]+)*$/);
const cityMessage = `Allowed: A-Z - ' spaces`;
/** Validates a city name allows letters, spaces, hyphens, and back single qoutes
 * Sets an error message if invalid
 * @example
 * San Francisco - valid
 * Martha's Vineyard - valid
 * Seattle - valid
 * Dover-Foxcroft - valid
 * $eattle - invalid
 */
export const cityValidator = patternWithMessage(cityRegex, cityMessage);

/** This regex pattern came from the enrollment api team, it matches their own backend regex, do not change */
const usaZipRegex = new RegExp(/^\d{5}-\d{4}$|^\d{5}$/);
const usaZipMessage = `Format: XXXXX or XXXXX-XXXX`;
/** Validates zip code for USA zip code, allows for 5 digit zip and 5+4 zip.
 * Sets an error message if invalid
 * @example
 * 12345 - valid
 * 12345-123 - valid
 * 1 - invalid
 * 12345-1 - invalid
 * 12345 1234 - invalid
 */
export const usaZipValidator = patternWithMessage(usaZipRegex, usaZipMessage);

/**
 * The original regex pattern from the enrollment api team was: "^[A-Z]\d[A-Z] \d[A-Z]\d$"
 * we modified it below to accept lower case characters
 */
const canadaZipRegex = new RegExp(/^[A-z]\d[A-z] \d[A-z]\d$/);
const canadaZipMessage = `Format: X1X 1X1`;
/** Validates zip code for Canada zip code, require zip to be format: A1A 1A1.
 * Sets an error message if invalid
 * @example
 * A1A 1A1 - valid
 * b2A 2b2 - valid
 * 1C1 C1C - invalid
 * 111 111 - invalid
 */
export const canadaZipValidator = patternWithMessage(canadaZipRegex, canadaZipMessage);

/** This regex pattern came from the enrollment api team, it matches their own backend regex, do not change */
const mexicoZipRegex = new RegExp(/^\d{5}$/);
const mexicoZipMessage = `Format: XXXXX`;
/** Validates zip code for Mexico zip code, requires 5 digit zip code
 * Sets an error message if invalid
 * @example
 * 12345 - valid
 * 1 - invalid
 * 123456 - invalid
 * 1234A - invalid
 */
export const mexicoZipValidator = patternWithMessage(mexicoZipRegex, mexicoZipMessage);

/** This regex expression is taken directly from Angular's source code (v9.1.11). It changes the end of the
 * regex from )\*$/ to )\*\.[a-zA-Z]{2,}$/ (inserted \.[a-zA-Z]{2,}) to enforce a valid top level domain which the default
 * angular email regex does not do
 */
const emailRegex = new RegExp(
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}$/
);

export const emailRegexString = `^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^._\`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^._\`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*[\.]{1}[a-zA-Z]{2,}$`;

export const usPhoneRegexString = '^[0-9]{3} [0-9]{3} [0-9]{4}$';

export const nonUsPhoneRegexString = `^[0-9]{5,20}$`;

export const optionalEmailRegexString = `^(?:(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^._\`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^._\`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*[\.]{1}[a-zA-Z]{2,})?$`;

export const optionalUsPhoneRegexString = `^(?:[0-9]{3} [0-9]{3} [0-9]{4})?$`;

export const optionalNonUsPhoneRegexString = `^(?:[0-9]{5,20})?$`;

export const airportCodeRegexString = '^[a-zA-Z]{3}$';

export const confirmationCodeRegexString = '^[a-zA-Z]{6}$';

export const iataAirportMessage = 'Must be IATA airport code';

const emailMessage = `Format: example@domain.com`;
// below jsdoc looks weird because of the @ symbol in the examples, had to escape it as code for it to render properly
/** Validates email addresses, requires standard email format including a domain: xxx@xxx.com
 * Sets an error message if invalid
 * @example
 * ```tom@localhost.com``` - valid
 * @example
 * ```tom@localhost``` - invalid
 * @example
 * ```tom``` - invalid
 * @example
 * ```tom@.com``` - invalid
 * @example
 * ```TOM@LOCALHOST.COM``` - valid
 */
export const emailValidator = patternWithMessage(emailRegex, emailMessage);

/**
 * This regex matches alphanumeric plus '/', ',' and '-'. It's intended for use with Waivers, but if it ends
 * up being useful in another circumstance have at it and just rename the regex to make it more generic.
 */
const waiverCommentRegex = new RegExp(/^[a-zA-Z0-9 \/,-]*$/);
const waiverCommentMessage = `Allowed: A-Z 0-9 / , - spaces`;
export const waiverCommentValidator = patternWithMessage(waiverCommentRegex, waiverCommentMessage);

/**
 * This regex matches alphanumeric. It's intended for use with mileage plan numbers, but if it ends
 * up being useful in another circumstance have at it and just rename the regex to make it more generic.
 */
const mileagePlanNumberRegex = new RegExp(/^[a-zA-Z0-9]*$/);
const mileagePlanNumberMessage = `Allowed: A-Z 0-9`;
export const mileagePlanNumberValidator = patternWithMessage(mileagePlanNumberRegex, mileagePlanNumberMessage);

/**
 * This regex matches numeric. It's intended for use with mileage plan numbers, but if it ends
 * up being useful in another circumstance have at it and just rename the regex to make it more generic.
 */
 export const mileagePlanNumberNonAlphaRegex = new RegExp(/^\d*$/);
 const mileagePlanNumberNonAlphaMessage = `Allowed: 0-9`;
 export const mileagePlanNumberNonAlphaValidator = patternWithMessage(mileagePlanNumberNonAlphaRegex, mileagePlanNumberNonAlphaMessage);

/**
 * This regex matches IATA airport code format, 3 characters all caps
 */
const airportCodeRegex = new RegExp(/^[A-Z]{3}$/);
export const airportCodeValidator = patternWithMessage(airportCodeRegex, iataAirportMessage);

const phoneNumberRegex = new RegExp(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/);
const phoneNumberRegexMessage = `Format - (123) 456-7890`;
export const phoneNumberValidator = patternWithMessage(phoneNumberRegex, phoneNumberRegexMessage);

const phoneNumberNoSpecialCharsRegex = new RegExp(/\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/);
const phoneNumberNoSpecialCharsRegexMessage = `Format - 1234567890`;
export const phoneNumberNoSpecialCharsValidator = patternWithMessage(phoneNumberNoSpecialCharsRegex, phoneNumberNoSpecialCharsRegexMessage);

const phoneNumberInternationalRegex = new RegExp(/^\d{5,20}$/);
const phoneNumberInternationalRegexMessage = `Format - 1234567890`;
export const phoneNumberInternationalValidator = patternWithMessage(phoneNumberInternationalRegex, phoneNumberInternationalRegexMessage);

const ticketRegex = new RegExp(/^\d{13}$/);
const ticketRegexMessage = 'Must be a 13 digit ticket number';
export const ticketValidator = patternWithMessage(ticketRegex, ticketRegexMessage);

export const confirmationCodeRegex = new RegExp(/^[a-zA-Z]{6}$/);
const confirmationCodeMessage = 'Format: XXXXXX';
export const confirmationCodeValidator = patternWithMessage(confirmationCodeRegex, confirmationCodeMessage);

const numericRegex = new RegExp(/^\d*$/);
const numericMessage = 'Must contain only numbers';
export const numericValidator = patternWithMessage(numericRegex, numericMessage);

const numericGreaterThanZeroRegex = new RegExp(/^[1-9]\d*$/);
const numericGreaterThanZeroMessage = 'Must contain only numbers greater than zero';
export const numericGreaterThanZeroValidator = patternWithMessage(numericGreaterThanZeroRegex, numericGreaterThanZeroMessage);

const empowermentToolkitNumericMessage = 'Must be the whole dollar amount with only numbers';
export const careKitDollarsValidator = patternWithMessage(numericRegex, empowermentToolkitNumericMessage);

const countryCodeRegex = new RegExp(/^[a-zA-Z ]{2,3}$/);
const countryCodeMessage = 'Format: XX or XXX';
export const countryCodeValidator = patternWithMessage(countryCodeRegex, countryCodeMessage);

export const remarkRegexString = '^[a-zA-Z0-9 \-\.\/\\\\*]+$';



