import { createAction } from '@ngrx/store';
import { Status } from '../../../models/status';
import { mileagePlanProfileServiceFeatureKey } from './mileage-plan-profile-service.state';
import { MileagePlanProfileLookupResponse } from '../../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-response';
import { MileagePlanProfileSearchResponse } from 'src/app/dtos/response/mileage-plan-profile-search-response/mileage-plan-profile-search-response';
import { SearchByNameAndDobRequest } from 'src/app/dtos/request/mileage-plan-profile-search-request/search-by-name-and-dob-request';
import { SearchByPhoneNumberRequest } from 'src/app/dtos/request/mileage-plan-profile-search-request/search-by-phone-number-request';

export const initializeMileagePlanProfileServiceState = createAction(`[${mileagePlanProfileServiceFeatureKey}] Initialize`);

export const mileagePlanProfileSearch = createAction(`[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search`);

export const mileagePlanProfileSearchSuccess = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search success`,
  (response: MileagePlanProfileLookupResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchFailure = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search failure`,
  (response: MileagePlanProfileLookupResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchStatus = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Set mileage plan profile search stability status`,
  (status: Status) => ({
    status,
  })
);

export const mileagePlanProfileSearchByPhoneNumber = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search by phone number`,
  (searchByPhoneNumberRequest: SearchByPhoneNumberRequest) => ({
    searchByPhoneNumberRequest,
  })
);

export const mileagePlanProfileSearchByPhoneNumberSuccess = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search by phone number success`,
  (response: MileagePlanProfileSearchResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchByPhoneNumberFailure = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search by phone number failure`,
  (response: MileagePlanProfileSearchResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchByPhoneNumberStatus = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Set mileage plan profile search by phone number stability status`,
  (status: Status) => ({
    status,
  })
);

export const mileagePlanProfileSearchByNameAndDob = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search by name and dob`,
  (searchByNameAndDobRequest: SearchByNameAndDobRequest) => ({
    searchByNameAndDobRequest,
  })
);

export const mileagePlanProfileSearchByNameAndDobSuccess = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search by name and dob success`,
  (response: MileagePlanProfileSearchResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchByNameAndDobFailure = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Mileage plan profile search by name and dob failure`,
  (response: MileagePlanProfileSearchResponse) => ({
    response,
  })
);

export const mileagePlanProfileSearchByNameAndDobStatus = createAction(
  `[${mileagePlanProfileServiceFeatureKey}] Set mileage plan profile search by name and dob stability status`,
  (status: Status) => ({
    status,
  })
);
