import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SplitFactory, LocalhostFromObject } from '@splitsoftware/splitio-browserjs';
import { fromEvent, Subscription } from 'rxjs';
import { OperatingEnvironment } from 'src/app/models/operating-environment';
import { environment } from '../../../environments/environment';
import { UserData } from '../../dtos/response/login-response/user-data';
import { RootState } from '../../state/state';
import { getUser } from '../login-service/state/login-service.selector';

@Injectable()
export class SplitioService {
  /**
   * The local reference to the Split SDK.
   */
  splitio: SplitIO.ISDK;
  /**
   * The local reference to the Split SDK's Client.
   */
  splitClient: SplitIO.IClient;
  /**
   * Flag to determine if SDK is ready or not.
   */
  isReady = false;
  /**
   * The local reference to the list of Treatments.
   */
  treatments: SplitIO.Treatments;
  /**
   * The local reference to the SDK's ready Observable.
   */
  subscription: Subscription;
  private user: UserData;

  constructor(private store: Store<RootState>) {
    this.store.pipe(select(getUser)).subscribe((user) => {
      this.user = user;
      if (this.user) {
        this.initSdk();
      }
    });
  }

  /**
   * This method initializes the SDK with the required Browser APIKEY
   * and the 'key' according to the Traffic type set (ex.: an user id).
   *
   * @returns void
   */
  initSdk(): void {
    if (environment.name !== 'dev') {
      console.log('ENVIRONMENT NAME: ' + environment.name);
    }
    this.splitio = SplitFactory({
      core: {
        authorizationKey: environment.splitAuthorizationKey,
        key: this.user.username,
      },
      // Mocked features, for use in local development.
      // These do NOT affect the evaluations in test/QA/prod.
      features: {
        RAIN_Agent_Feedback: 'on',
        Initial_Booking: 'on',
        FLIFO_Guest_Friendly_MSG: 'showGuestFriendlyOpsReason',
        RAIN_1023562_SSR_AVIH: 'on',
        PETS_MULTIPLE_PASSENGERS: 'on',
        Mileage_Plan_Maintenance: 'off',
        RAIN_1015414_IB_Contact_Tracing: 'on',
        New_Seat_Map: 'on',
        Caller_Name: 'on',
        Chat_Payment: 'on',
        Cancel_Reservation_WebLink: 'on',
        ACTIVE_DELAYED_SEGMENTS: 'off',
        Initial_Booking_Payment_Page: 'on',
        Initial_Booking_With_Flight_Search: 'on',
        Guest_Journey_Guest_Tab_V2: 'off',
        Initial_Booking_Flight_Search_Lap_Infants: 'off',
        Initial_Booking_Flight_Search_Use_Miles: 'off',
        Guest_Journey_Osi_Tab_V2: 'on'
      },
      /*
         Localhost mode is available for developers to use so that they can test
           Split logic locally or offline using the Mocked features above
         When a developer wants to run in Localhost mode,
           uncomment the configuration below -> sync: { localhostMode: LocalhostFromObject() }
         Split.io Browser SDK Localhost mode information:
           https://help.split.io/hc/en-us/articles/360058730852-Browser-SDK#localhost-mode
         As of 9/15/2022 this breaks the E2Es so we leave it commented out,
           this does not affect Test/QA/Production
      */
      sync: environment.name === OperatingEnvironment.Dev ? { localhostMode: LocalhostFromObject() } : undefined
    });
    this.splitClient = this.splitio.client();
    // Verify if sdk is initialized
    this.verifyReady();

    this.splitClient.setAttributes({
      username: this.user.username,
      homeCity: this.user.sabreAAA,
    });
  }

  /**
   * Function to check if the SDK is ready, subscribe to an Observable
   * and set the isReady flag according to the result.
   *
   * @returns void
   */
  private verifyReady(): void {
    const isReadyEvent = fromEvent(this.splitClient, this.splitClient.Event.SDK_READY);

    this.subscription = isReadyEvent.subscribe({
      next() {
        this.isReady = true;
        if (environment.name !== 'dev') {
          console.log('Split SDK ready: ', this.isReady);
        }
      },
      error(err) {
        if (environment.name !== 'dev') {
          console.log('Split SDK error: ', err);
        }
        this.isReady = false;
      }
    });
  }
}
