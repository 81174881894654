import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { GuestProfileResponse } from '../../dtos/response/mileage-plan-profile-lookup-response/guest-profile-response';
import { MileagePlanProfileLookupResponse } from '../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-response';
import { MileagePlanProfileLookupStatus } from '../../dtos/response/mileage-plan-profile-lookup-response/mileage-plan-profile-lookup-status';
import { SearchByNameAndDobRequest } from 'src/app/dtos/request/mileage-plan-profile-search-request/search-by-name-and-dob-request';
import { SearchByPhoneNumberRequest } from 'src/app/dtos/request/mileage-plan-profile-search-request/search-by-phone-number-request';
import { MileagePlanProfileSearchResponse } from 'src/app/dtos/response/mileage-plan-profile-search-response/mileage-plan-profile-search-response';
import { MileagePlanProfileSearchStatus } from 'src/app/dtos/response/mileage-plan-profile-search-response/mileage-plan-profile-search-status';
import { PersonLookup } from 'src/app/dtos/response/mileage-plan-profile-search-response/person-lookup';

export interface MileagePlanProfileServiceAPI {
  mileagePlanProfileLookup(
    mileagePlanNumber: string,
    autoFillOnLookup: boolean
  ): Observable<MileagePlanProfileLookupResponse>;

  mileagePlanProfileSearchByPhoneNumber(
    searchByPhoneNumberRequest: SearchByPhoneNumberRequest
  ): Observable<MileagePlanProfileSearchResponse>;

  mileagePlanProfileSearchByNameAndDob(
    searchByNameAndDobRequest: SearchByNameAndDobRequest
  ): Observable<MileagePlanProfileSearchResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class MileagePlanProfileService implements MileagePlanProfileServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}

  public mileagePlanProfileLookup(
    mileagePlanNumber: string,
    backgroundLookup: boolean = false
  ): Observable<MileagePlanProfileLookupResponse> {
    const options = {
      headers: new HttpHeaders({
        // By default background lookup is false so that loading gif is shown during lookups.
        // Special case is that we want to lookup Mileage Plan profile in the background after
        // a successfull guest info save, so that we can get the most updated mileage plan data
        // for the guest.
        background: backgroundLookup ? 'true' : 'false',
      }),
    };
    return this.http.get<GuestProfileResponse>(`api/mileage-plan-profile/lookup/${mileagePlanNumber}`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((mileagePlanProfileResponse) => {
        // Full success scenario
        if (
          mileagePlanProfileResponse.accountLookupResponse?.success === true &&
          mileagePlanProfileResponse.memberInfoLookupResponse?.success === true
        ) {
          return { response: mileagePlanProfileResponse, status: MileagePlanProfileLookupStatus.SUCCESS };
        }
        // Partial failure scenario
        else {
          return { response: mileagePlanProfileResponse, status: MileagePlanProfileLookupStatus.PARTIAL_FAILURE};
        }
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ status: MileagePlanProfileLookupStatus.TIMEOUT, error: 'Timeout error'});
        }
        if (404 === err.status) {
          return observableOf({ status: MileagePlanProfileLookupStatus.NO_DATA_RETURNED, error: err.error});
        }
        return observableOf({ status: MileagePlanProfileLookupStatus.SYSTEM_FAILURE, error: err.error});
      })
    );
  }

  public mileagePlanProfileSearchByPhoneNumber(
    searchByPhoneNumberRequest: SearchByPhoneNumberRequest
  ): Observable<MileagePlanProfileSearchResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<PersonLookup>(
      'api/mileage-plan-profile/search/by-phone-number', searchByPhoneNumberRequest, options
    ).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((personLookup) => {
        return { response: personLookup, status: MileagePlanProfileSearchStatus.SUCCESS };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ status: MileagePlanProfileSearchStatus.TIMEOUT, error: 'Timeout error'});
        }
        if (404 === err.status) {
          return observableOf({ status: MileagePlanProfileSearchStatus.NO_DATA_RETURNED, error: err.error});
        }
        return observableOf({ status: MileagePlanProfileSearchStatus.SYSTEM_FAILURE, error: err.error});
      })
    );
  }

  public mileagePlanProfileSearchByNameAndDob(
    searchByNameAndDobRequest: SearchByNameAndDobRequest
  ): Observable<MileagePlanProfileSearchResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'true',
      }),
    };
    return this.http.post<PersonLookup>(
      'api/mileage-plan-profile/search/by-name-and-dob', searchByNameAndDobRequest, options
    ).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((personLookup) => {
        return { response: personLookup, status: MileagePlanProfileSearchStatus.SUCCESS };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ status: MileagePlanProfileSearchStatus.TIMEOUT, error: 'Timeout error'});
        }
        if (404 === err.status) {
          return observableOf({ status: MileagePlanProfileSearchStatus.NO_DATA_RETURNED, error: err.error});
        }
        return observableOf({ status: MileagePlanProfileSearchStatus.SYSTEM_FAILURE, error: err.error});
      })
    );
  }
}
