export enum Splits {
  ACTIVE_DELAYED_SEGMENTS = 'ACTIVE_DELAYED_SEGMENTS',
  Guest_Journey_Guest_Tab_V2 = 'Guest_Journey_Guest_Tab_V2',
  New_Seat_Map = 'New_Seat_Map',
  Initial_Booking_Payment_Page = 'Initial_Booking_Payment_Page',
  Initial_Booking_With_Flight_Search = 'Initial_Booking_With_Flight_Search',
  RAIN_Agent_Feedback = 'RAIN_Agent_Feedback',
  Initial_Booking = 'Initial_Booking',
  Initial_Booking_Flight_Search_Lap_Infants = 'Initial_Booking_Flight_Search_Lap_Infants',
  Initial_Booking_Flight_Search_Use_Miles = 'Initial_Booking_Flight_Search_Use_Miles',
  Guest_Journey_Osi_Tab_V2 = 'Guest_Journey_Osi_Tab_V2',
}
