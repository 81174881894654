import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SplitioService } from '../../../../services/splitio-service/splitio.service';
import { RootState } from '../../../../state/state';
import { Store, select } from '@ngrx/store';
import { getUser } from '../../../../services/login-service/state/login-service.selector';
import { take } from 'rxjs';
import { UserData } from '../../../../dtos/response/login-response/user-data';
import { LoggerService } from '../../../../services/logger-service/logger.service';
import { Splits } from '../../../../services/splitio-service/splits';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Input() menuItems: any;
  @Input() agentName: string;
  @Input() user: UserData;
  @Input() contentClass: string;
  @Input() menuId: string;
  @Output() openDrawerEvent = new EventEmitter<any>();
  @Output() userProfileClickEvent = new EventEmitter<string>();
  @Output() logoutEvent = new EventEmitter();

  attributes: SplitIO.Attributes;
  showClassicShopMenuOption = false;
  agentFeedbackTreatment: string;
  showBookFlightsMenuOption = false;
  showBagJourneyMenuOption = false;

  constructor(
    private store: Store<RootState>,
    private splitioService: SplitioService,
    private cdRef: ChangeDetectorRef,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    // The SDK_READY event will only fire the first time the user lands on the guest tab (per login session),
    // so we need to listen to the SDK_READY event to fire the updateSplits() logic upon initial load.
    // All subsequent ngOnInit() calls will not fire the SDK_READY event since the SDK will already be initialized.
    // This will ensure a scenario like looking up a different PNR will also trigger the logic.
    this.splitioService.splitClient.on(this.splitioService.splitClient.Event.SDK_READY, () => {
      this.updateSplits();
    });
    // The SDK_UPDATE event will fire when a change has been made to a split. This is to account for when we turn
    // the split on/off for users, so that they detect the change in real time.
    this.splitioService.splitClient.on(this.splitioService.splitClient.Event.SDK_UPDATE, () => {
      this.updateSplits();
    });
    this.updateSplits();
  }

  openDrawerHelper(e: Event, tagName: string, id: string) {
    e.preventDefault();
    this.openDrawerEvent.emit({ tagName, id });
  }

  userProfileClick(e: Event, id: string) {
    e.preventDefault();
    this.userProfileClickEvent.emit(id);
  }

  logout(e: Event) {
    e.preventDefault();
    this.logoutEvent.emit();
  }

  openExternalLink(e: Event, link: string, target: string, id: string) {
    this.loggerService.log({
      event: 'NavItemClick',
      username: this.user.username,
      homeCity: this.user.sabreAAA,
      navItem: id,
    });

    e.preventDefault();
    window.open(link, target);
  }

  updateSplits() {
    // Used for Split.io feature flagging
    // 1. Pull username off of user to send up as an attribute to the splits
    this.store.pipe(select(getUser), take(1)).subscribe((user) => (this.attributes = { username: user.username, homeCity: user.sabreAAA }));
    // 2. Get treatment. Treatment values will be 'on', 'off', or 'control'
    const classicShopTreatment: SplitIO.Treatment = this.splitioService.splitClient.getTreatment(Splits.Initial_Booking, this.attributes);
    const agentFeedbackTreatment: SplitIO.Treatment = this.splitioService.splitClient.getTreatment(
      Splits.RAIN_Agent_Feedback,
      this.attributes
    );
    const bookFlightsTreatment: SplitIO.Treatment = this.splitioService.splitClient.getTreatment(
      Splits.Initial_Booking_With_Flight_Search,
      this.attributes
    );
    // 3. Evaluate initial booking treatment. Enable Initial Booking if treatment evaluates to 'on', leave Initial Booking disbled otherwise
    this.showClassicShopMenuOption = classicShopTreatment === 'on';

    // 4. Evaluate agent feedback treatment.
    this.agentFeedbackTreatment = agentFeedbackTreatment;

    // 5. Evaluate book flights treatment.
    this.showBookFlightsMenuOption = bookFlightsTreatment === 'on';

    this.showBagJourneyMenuOption = this.user.isCBSAgent;

    // 6. Force manual change detection to update the template
    this.cdRef.markForCheck();
  }
}
