import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector, createSelectorFactory } from '@ngrx/store';
import { PassengerSSR } from 'src/app/dtos/response/reservation-response/passenger-ssr';
import { SsrType } from '../../../dtos/request/secure-flight-info/ssr-type';
import { ActionCode } from '../../../dtos/response/action-code/action-codes';
import { unconfirmedActionCodes } from '../../../dtos/response/action-code/unconfirmed-action-codes';
import { irropActionCodes } from '../../../dtos/response/action-code/irrop-action-codes';
import { scheduleChangeActionCodes } from '../../../dtos/response/action-code/schedule-change-action-codes';
import { AirgroupAirlineCode } from '../../../dtos/response/flight-availability-response/airgroup-airline-code';
import { ReservationCreationResponse } from '../../../dtos/response/reservation-creation-response/reservation-creation-response';
import { BillingDetails } from '../../../dtos/response/reservation-response/billing-details';
import { ConfirmationStatus } from '../../../dtos/response/reservation-response/confirmation-status';
import { GenericServiceRequest } from '../../../dtos/response/reservation-response/generic-service-request';
import { GroupReservationDetails } from '../../../dtos/response/reservation-response/group-reservation-details';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { PremiumEligibility } from '../../../dtos/response/reservation-response/premium-eligibility';
import { Remark } from '../../../dtos/response/reservation-response/remark';
import { Reservation } from '../../../dtos/response/reservation-response/reservation';
import { ReservationLookupStatus } from '../../../dtos/response/reservation-response/reservation-lookup-status';
import { ReservationResponse } from '../../../dtos/response/reservation-response/reservation-response';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { SpecialServiceRequest } from '../../../dtos/response/reservation-response/special-service-request';
import { WaitListProperties } from '../../../dtos/response/reservation-response/wait-list-properties';
import { AirlineCode } from '../../../models/airlines/airline-code';
import { SSRCode } from '../../../models/ssr/ssr-code';
import { getPaymentLink } from '../../../models/state/model.selectors';
import { Status } from '../../../models/status';
import { getCallerName, selectRouteNestedParam } from '../../../state/selectors';
import { arrayMemoize } from '../../../utils/custom-memoizers';
import { reservationAdapter, reservationServiceFeatureKey, ReservationServiceState } from './reservation-service.state';
import { waitlistActionCodes } from '../../../dtos/response/action-code/waitlist-action-codes';
import { confirmedActionCodes } from '../../../dtos/response/action-code/confirmed-action-codes';
import { PassengerSeat } from '../../../dtos/response/reservation-response/passenger-seat';
import { TaxInfo } from '../../../dtos/response/reservation-response/price-quote/tax-info';
import { LapInfant } from '../../../dtos/response/reservation-response/lap-infant';
import { Refundability } from '../../../dtos/response/reservation-response/refundability';
import { TierStatus } from '../../../models/tier-status';
import { getTierStatusFromText } from '../../../utils/tier-status-helper';
import { MessageKey } from '../../../models/message/message-key';
import { SsrDetail } from 'src/app/dtos/request/ssr-requests/ssr-details';
import { PetSsrInventoryStatus } from 'src/app/dtos/response/ssr-response/pet-ssr-inventory-status';
import { AddSsrSelectedFlight } from 'src/app/dtos/request/ssr-requests/add-ssr-selected-flight';
import { TravelDocument } from 'src/app/dtos/response/reservation-response/secure-flight-info/travel-document';
import { scheduleChangeUnconfirmedActionCodes } from '../../../dtos/response/action-code/schedule-change-unconfirmed-action-codes';
import { scheduleChangeConfirmedActionCodes } from '../../../dtos/response/action-code/schedule-change-confirmed-action-codes';
import { AddDeleteRemarkStatus } from 'src/app/dtos/response/remarks-response/add-delete-remark-status';
import { SeatMapAdvisory } from 'src/app/models/seat-map/seat-map-advisory';
import { AddRainBookingRemarksRequest } from 'src/app/dtos/request/remarks-request/add-rain-booking-remarks-request';
import { FareRule } from 'src/app/dtos/request/remarks-request/fare-rule';
import { ExtraSeatType } from '../../../dtos/response/reservation-response/extra-seat-type';

const getReservationServiceState = createFeatureSelector<ReservationServiceState>(reservationServiceFeatureKey);

const { selectEntities } = reservationAdapter.getSelectors();

export const getAllReservationEntities = createSelector(getReservationServiceState, (state) => selectEntities(state.reservation));

/**
 * Gets a passenger loyalty object
 */
export const getPassengerLoyalty = (confirmationCode: string, passengerHashId: string) =>
  createSelector(
    getAllReservationEntities,
    (entities: Dictionary<ReservationResponse>) =>
      entities[confirmationCode]?.reservation?.passengers?.find((passenger) => passenger.hashId === passengerHashId)?.loyalty ?? null
  );

/**
 * Gets reservation data based on the confirmationCode in the route
 */
export const getRoutedReservation = createSelector(
  getAllReservationEntities,
  selectRouteNestedParam('confcode'),
  (entities: Dictionary<ReservationResponse>, confirmationCode: string): Reservation | null =>
    entities[confirmationCode]?.reservation ?? null
);

/**
 * Get the lookup status of the routed reservation
 */
export const getRoutedReservationStatus = (confirmationCode: string) =>
  createSelector(
    getAllReservationEntities,
    (entities: Dictionary<ReservationResponse>): ReservationLookupStatus | null => entities[confirmationCode]?.status ?? null
  );

/**
 * Get the lookup status of the routed reservation
 */
export const getRoutedReservationSuccessStatus = createSelector(
  getAllReservationEntities,
  selectRouteNestedParam('confcode'),
  (entities: Dictionary<ReservationResponse>, confirmationCode: string): ReservationLookupStatus | null =>
    entities[confirmationCode]?.status ?? null
);

/**
 * Get the lookup CRUD status of the routed reservation
 */
export const getReservationLookupCrudStatus = createSelector(
  getReservationServiceState,
  (state): Status => state.reservationLookupCrudStatus
);

/**
 * Returns a list of mapped message keys for the routed reservation
 */
export const getRoutedReservationMappedMessageKeys = createSelector(
  getRoutedReservation,
  (reservation: Reservation): MessageKey[] => reservation?.mappedMessageKeys ?? []
);

/**
 * Gets confirmationCode in the route. Used for testing since selectRouteNestedParam is un-testable, and un-mockable.
 */
export const getRoutedConfirmationCode = createSelector(
  selectRouteNestedParam('confcode'),
  (confirmationCode: string): string => confirmationCode
);

/**
 * Gets the status of the reservation lookup from the statuscode route param
 */
export const getRoutedReservationLookupStatus = createSelector(
  selectRouteNestedParam('statuscode'),
  (status: string): string => status ?? null
);

/**
 * Gets remarks on a reservation based on the confirmationCode in the route
 */
export const getRemarks = createSelector(getRoutedReservation, (reservation: Reservation): Remark[] => reservation?.remarks ?? []);

/**
 * Get the deleteRemarkStatus property value
 */
export const getDeleteRemarkStatus = createSelector(getReservationServiceState, (state): AddDeleteRemarkStatus => state.deleteRemarkStatus);

/**
 * Get the addRemarkStatus property value
 */
export const getAddRemarkStatus = createSelector(getReservationServiceState, (state): AddDeleteRemarkStatus => state.addRemarkStatus);

/**
 * Gets genericServiceRequests on a reservation based on the confirmationCode in the route
 */
export const getGenericServiceRequests = createSelector(
  getRoutedReservation,
  (reservation: Reservation): GenericServiceRequest[] => reservation?.genericServiceRequests ?? []
);

/**
 * Gets OSI genericServiceRequests on a reservation based on the confirmationCode in the route
 */
export const getOsiGenericServiceRequests = createSelector(
  getRoutedReservation,
  (reservation: Reservation): GenericServiceRequest[] => reservation?.genericServiceRequests?.filter((r) => r.type === 'OSI') ?? []
);

/**
 * Gets the OSIs for the passengers
 */
export const getPassengerOsis = createSelector(
  getRoutedReservation,
  (res: Reservation): SpecialServiceRequest[] => res?.passengers.flatMap((pax) => pax.ssrs.filter((ssr) => ssr.type === 'OSI')) ?? []
);

/**
 * Gets all the OSIs including generic and passenger osis
 */
export const getOsiRequests = createSelector(getOsiGenericServiceRequests, getPassengerOsis, (genericServiceRequests, passengerOsis) => {
  let osiRequests: SpecialServiceRequest[] | GenericServiceRequest[] = [];
  osiRequests = osiRequests.concat(passengerOsis, genericServiceRequests) as any;
  return osiRequests;
});

/**
 * Gets non-OSI genericServiceRequests on a reservation based on the confirmationCode in the route
 */
export const getOtherGenericServiceRequests = createSelector(
  getRoutedReservation,
  (reservation: Reservation): GenericServiceRequest[] => reservation?.genericServiceRequests?.filter((r) => r.type !== 'OSI') ?? []
);

/**
 * Gets if the reservation ticket was sold by another airline based on the confirmationCode in the route
 */
export const getIsTicketIssuedByOtherAirline = createSelector(
  getRoutedReservation,
  (reservation: Reservation): boolean => reservation?.isTicketIssuedByOtherAirline ?? false
);

/**
 * Gets ticket numbers on a reservation based on the confirmationCode in the route
 * Includes VOID tickets
 */
export const getTicketNumbers = createSelectorFactory(arrayMemoize)(
  getRoutedReservation,
  (reservation: Reservation): string[] =>
    reservation?.ticketNumbers?.map((ticket) => ticket.ticketNumber) ?? []
);

/**
 * Gets ticket numbers on a reservation based on the confirmationCode in the route
 * Filters out VOID tickets, VOID tickets can be ignored for most use cases - primarily used for display/historical reference
 */
export const getTicketNumbersNoVoid = createSelectorFactory(arrayMemoize)(
  getRoutedReservation,
  (reservation: Reservation): string[] =>
    reservation?.ticketNumbers?.filter((ticket) => !ticket.isVoid)?.map((ticket) => ticket.ticketNumber) ?? []
);

/**
 * Returns true if the reservation has a ticket number attached, false otherwise
 * Ignores VOID tickets
 */
export const getHasTickets = createSelector(getTicketNumbersNoVoid, (ticketNumbers: string[]): boolean => ticketNumbers.length > 0);

/**
 * Gets Premium Eligibility of a reservation based on the confirmationCode in the route
 */
export const getPremiumEligibility = createSelector(
  getRoutedReservation,
  (reservation: Reservation): PremiumEligibility | null => reservation?.premiumEligibility ?? null
);

/**
 * Gets Group Reservation Details of a reservation based on the confirmationCode in the route
 */
export const getGroupReservationDetails = createSelector(
  getRoutedReservation,
  (reservation: Reservation): GroupReservationDetails | null => reservation?.groupReservationDetails ?? null
);

/**
 * Gets the group reservation name of a reservation based on the confirmationCode in the route
 * If the group reservation name is not found, it will return an empty string
 * If the group reservation name is a Deadhead group, it will return an empty string
 */
export const getGroupReservationName = createSelector(
  getGroupReservationDetails,
  (groupReservationDetails: GroupReservationDetails): string =>
    // Check that the group has a name
    // Check that the group is not a Deadhead group
    groupReservationDetails?.groupName?.length > 0 && !groupReservationDetails.groupName.toLowerCase().startsWith('dhd')
      ? groupReservationDetails.groupName
      : ''
);

/**
 * Uses Group Reservation Details of a reservation to determine whether or not the reservation is a group reservation
 */
export const getIsGroupReservation = createSelector(
  getRoutedReservation,
  (reservation: Reservation): boolean => reservation?.groupReservationDetails?.isGroupReservation ?? false
);

/**
 * Gets whether reservation has been divided or not based on remarks
 */
export const getIsDividedReservation = createSelector(getRoutedReservation, (reservation: Reservation): boolean => {
  const regex = /^DIVIDED\/[A-Z]{3}\d[A-Z0-9]{3}/;
  const divided = reservation?.remarks?.flatMap((remark) => remark.remarkLines).find((remarkLine) => remarkLine.match(regex));
  return divided ? true : false;
});

/**
 * Gets billing details on a reservation excluding any PWCT phone flags
 * PWCT - passenger without contact
 * Possible forms: PWCT, SEAPWCT, PHXPWCT, BOIPWCT, GCTPWCT, etc.
 */
export const getBillingDetailsWithoutPWCT = createSelector(getRoutedReservation, (reservation: Reservation): BillingDetails | null => {
  if (reservation?.billingDetails?.phoneNumbers?.length) {
    const billingDetailsWithoutPWCT: BillingDetails = {
      ...reservation.billingDetails,
      phoneNumbers: reservation.billingDetails.phoneNumbers.filter(
        (phoneNumber) => !phoneNumber.phoneNumber?.includes('PWCT') && !phoneNumber.phoneNumber?.includes('-N')
      ),
    };
    return billingDetailsWithoutPWCT;
  }
  return reservation?.billingDetails ?? null;
});

/**
 * Gets billing details on a reservation
 */
export const getBillingDetailsWithPWCT = createSelector(getRoutedReservation, (reservation: Reservation): BillingDetails | null => {
  return reservation?.billingDetails ?? null;
});

/**
 * Returns true if the reservation has a phone number attached
 * API handles validation of phone fields, phone numbers list will be empty if no valid phone numbers were found
 * Valid phone numbers include (XXX)-XXX-XXXX, XXXXXXXXXX, XXX-XXX-XXXX, and all PWCT (passenger without contact) flags
 */
export const getHasValidPhoneField = createSelector(getBillingDetailsWithPWCT, (billingDetails: BillingDetails): boolean => {
  return billingDetails?.phoneNumbers?.length ? true : false;
});

/**
 * Returns the total fare found in the PQ (price quote) stored on the reservation active in the route
 */
export const getPriceQuoteTotalFareForRoutedReservationUSD = createSelector(
  getRoutedReservation,
  (reservation: Reservation): number | null => reservation?.priceQuote?.fareInfo?.totalFare ?? null
);

/**
 * Returns the base fare found in the PQ (price quote) stored on the reservation active in the route
 */
export const getPriceQuoteBaseFareForRoutedReservationUSD = createSelector(
  getRoutedReservation,
  (reservation: Reservation): number | null =>
    (reservation?.priceQuote?.fareInfo?.baseFareCurrencyCode === 'USD'
      ? reservation?.priceQuote?.fareInfo?.baseFare
      : reservation?.priceQuote?.fareInfo?.equivalentAmountUSD) ?? null
);

/**
 * Returns the taxes found in the PQ (price quote) stored on the reservation active in the route
 */
export const getPriceQuoteTaxesForRoutedReservation = createSelector(
  getRoutedReservation,
  (reservation: Reservation): TaxInfo[] => reservation?.priceQuote?.taxInfo?.filter((taxInfo) => taxInfo.taxType === 'Tax') ?? []
);

/**
 * Returns the total taxes found in the PQ (price quote) stored on the reservation active in the route
 */
export const getPriceQuoteTotalTaxesForRoutedReservationUSD = createSelector(
  getRoutedReservation,
  (reservation: Reservation): number | null => reservation?.priceQuote?.fareInfo?.totalTax ?? null
);

/**
 * BE VERY CAREFUL WITH THIS SELECTOR.
 * It includes the following:
 *    Past dated segments
 *    ARNK segments
 *    Segments with all action codes
 *    COG hidden legs (Change Of Gauge: direct flight where a hidden leg has a different plane type)
 * Gets confirmed and unconfirmed segments on a reservation, including past-dated/"flown" flights
 */
export const getAllSegments = createSelector(getRoutedReservation, (reservation: Reservation): Segment[] => reservation?.allSegments ?? []);

/**
 * get segment by hashId
 */
export const getSegmentById = (segmentHashId: string) =>
  createSelector(
    getAllSegments,
    (segments: Segment[]): Segment | null => segments?.find((segment) => segment.hashId === segmentHashId) ?? null
  );

/**
 * BE VERY CAREFUL WITH THIS SELECTOR.
 * It includes the following:
 *    Past dated segments
 *    Segments with all action codes
 *    COG hidden legs (Change Of Gauge: direct flight where a hidden leg has a different plane type)
 * Gets confirmed and unconfirmed segments on a reservation, including past-dated/"flown" flights
 */
export const getAllSegmentsExcludingARNK = createSelector(
  getRoutedReservation,
  (reservation: Reservation): Segment[] =>
    reservation?.allSegments?.filter(
      (segment) => !segment.isARNK
    ) ?? []
);

/**
 * This mimics the old 'AllSegments' list from the API
 * Gets confirmed and unconfirmed segments on a reservation that are "active" / are NOT past-dated, are not ARNK,
 *  are not COG extracted hidden stops and are not waitlist
 */
export const getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist = createSelector(
  getRoutedReservation,
  (reservation: Reservation): Segment[] => {
    return (
      reservation?.allSegments?.filter((segment) => {
        // Compare UTC departure to UTC now (both represented in milliseconds)
        return (
          new Date(segment.departureDateTimeUtc ?? segment.departureDateTime).getTime() > new Date().getTime() &&
          !segment.isARNK &&
          !segment.displayChangeOfGaugeWarning &&
          !waitlistActionCodes.includes(segment.actionCode)
        );
      }) ?? []
    );
  }
);

/**
 * This mimics the old 'AllSegments' list from the API
 * Gets confirmed and unconfirmed segments on a reservation that are "active" / are NOT past-dated, are not ARNK,
 *  are not COG extracted hidden stops and are not waitlist
 */
export const getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2 = createSelector(
  getRoutedReservation,
  (reservation: Reservation): Segment[] => {
    const segs = reservation?.allSegments?.filter((segment) => {
      // Compare UTC departure to UTC now (both represented in milliseconds)
      const isPastDatedSegment =
        new Date(
          (segment.flightLegInformation?.estimatedTimesUtc?.departure ?? segment.flightLegInformation?.estimatedTimesLocal?.departure)
          ?? (segment.departureDateTimeUtc ?? segment.departureDateTime)
        ).getTime() < new Date().getTime();
      return (
        !isPastDatedSegment &&
        !segment.isARNK &&
        !segment.displayChangeOfGaugeWarning &&
        !waitlistActionCodes.includes(segment.actionCode)
      );
    });
    return segs ?? [];
  }
);

/**
 * Gets confirmed and unconfirmed segments on a reservation that are "active" / are NOT past-dated, and are not ARNK
 * This list can have COG hidden legs that the API has made into 'segments' to allow for seat assignment
 */
export const getAllSegmentsExcludingPastDatedAndARNK = createSelector(getRoutedReservation, (reservation: Reservation): Segment[] => {
  return (
    reservation?.allSegments?.filter((segment) => {
      // Compare UTC departure to UTC now (both represented in milliseconds)
      const isTrue = new Date(segment.departureDateTimeUtc ?? segment.departureDateTime).getTime() > new Date().getTime();
      return isTrue && !segment.isARNK;
    }) ?? []
  );
});

/**
 * Gets confirmed, unconfirmed and delayed segments on a reservation that are "active" / are NOT past-dated, and are not ARNK
 * This list can have COG hidden legs that the API has made into 'segments' to allow for seat assignment
 */
export const getAllSegmentsExcludingPastDatedAndARNKV2 = createSelector(
  getRoutedReservation,
  (reservation: Reservation): Segment[] | null => {
    return (
      reservation?.allSegments?.filter((segment) => {
        // Compare UTC departure to UTC now (both represented in milliseconds)
        const isPastDatedSegment =
          new Date(
            (segment.flightLegInformation?.estimatedTimesUtc?.departure ?? segment.flightLegInformation?.estimatedTimesLocal?.departure)
            ?? (segment.departureDateTimeUtc ?? segment.departureDateTime)
          ).getTime() < new Date().getTime();
        return !isPastDatedSegment && !segment.isARNK;
      }) ?? []
    );
  }
);

/**
 * This mimics the old 'Segments' list from the API
 * Gets all segments on a reservation that are not past dated, not ARNK, and whos action codes are not in the filtered list
 */
export const getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes = createSelector(
  getAllSegmentsExcludingPastDatedAndARNK,
  (segmentsExcludingARNKAndPastDated: Segment[]): Segment[] => {
    return segmentsExcludingARNKAndPastDated?.filter((segment) => !unconfirmedActionCodes.includes(segment.actionCode)) ?? [];
  }
);

/**
 * This mimics the old 'Segments' list from the API
 * Gets all segments on a reservation that are not past dated, not ARNK, and whos action codes are not in the filtered list
 */
export const getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodesV2 = createSelector(
  getAllSegmentsExcludingPastDatedAndARNKV2,
  (segmentsExcludingARNKAndPastDated: Segment[]): Segment[] | null => {
    if (segmentsExcludingARNKAndPastDated !== null) {
      return segmentsExcludingARNKAndPastDated?.filter((segment) => !unconfirmedActionCodes.includes(segment.actionCode)) ?? [];
    }
    return null;
  }
);

/**
 * This mimics the old 'unconfirmedSegments' list from the API
 * Gets all segments on a reservation that are not past dated, not ARNK, and whos action codes are not in the filtered list
 */
export const getAllSegmentsExcludingPastDatedARNKAndConfirmedActionCodes = createSelector(
  getAllSegmentsExcludingPastDatedAndARNK,
  (segmentsExcludingARNKAndPastDated: Segment[]): Segment[] => {
    return segmentsExcludingARNKAndPastDated?.filter((segment) => !confirmedActionCodes.includes(segment.actionCode)) ?? [];
  }
);

/**
 * This mimics the old 'unconfirmedSegments' list from the API
 * Gets all segments on a reservation that are not past dated, not ARNK, and whos action codes are not in the filtered list
 */
export const getAllSegmentsExcludingPastDatedARNKAndConfirmedActionCodesV2 = createSelector(
  getAllSegmentsExcludingPastDatedAndARNKV2,
  (segmentsExcludingARNKAndPastDated: Segment[]): Segment[] => {
    return segmentsExcludingARNKAndPastDated?.filter((segment) => !confirmedActionCodes.includes(segment.actionCode)) ?? [];
  }
);

/**
 * Gets past-dated or "flown" segments
 * CAUTION, this will pick up ARNK segments
 */
export const getAllPastDatedSegments = createSelector(getRoutedReservation, (reservation: Reservation): Segment[] => {
  return (
    reservation?.allSegments?.filter(
      (segment) => new Date(segment.departureDateTimeUtc ?? segment.departureDateTime).getTime() <= new Date().getTime()
    ) ?? []
  );
});

/**
 * Gets past-dated or "flown" segments, ignores ARNK
 */
export const getAllPastDatedSegmentsExcludingARNK = createSelector(getRoutedReservation, (reservation: Reservation): Segment[] => {
  return (
    reservation?.allSegments?.filter(
      (segment) =>
        !segment.isARNK &&
        new Date(segment.departureDateTimeUtc ?? segment.departureDateTime).getTime() <= new Date().getTime()
    ) ?? []
  );
});

/**
 * Gets the count of segments on a reservation that are not past dated, not ARNK, and do not have filtered action codes
 */
export const getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodesCount = createSelector(
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  (segments: Segment[]) => segments?.length ?? null
);

/**
 * Returns true if the count of segments on a reservation that are not past dated, not ARNK, and do not have filtered action codes is > 0
 */
export const hasSegmentsExcludingPastDatedARNKAndSpecificActionCodes = createSelector(
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getAllSegmentsExcludingPastDatedARNKAndConfirmedActionCodes,
  (segments: Segment[], unconfirmedSegments: Segment[]) => (segments?.length || unconfirmedSegments?.length ? true : false)
);

/**
 * Returns true if the count of segments on a reservation that are not past dated, not ARNK, and do not have filtered action codes is > 0
 */
export const hasSegmentsExcludingPastDatedARNKAndSpecificActionCodesV2 = createSelector(
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodesV2,
  getAllSegmentsExcludingPastDatedARNKAndConfirmedActionCodesV2,
  (segments: Segment[], unconfirmedSegments: Segment[]) => (segments?.length || unconfirmedSegments?.length ? true : false)
);

/**
 * Returns true if the reservation has an MM segment in its filtered segments
 */
export const getFilteredAllSegmentsHasMMSegment = createSelector(
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  (segments: Segment[]): boolean =>
    segments && segments.filter((segment) => segment.actionCode === ActionCode.MM).length > 0 ? true : false
);

export const getAllNonOASegmentsExcludingPastDatedARNKAndFilteredActionCodes = createSelector(
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  (segments: Segment[]) => segments?.filter((segment) => AirgroupAirlineCode[segment.operatedByAirlineCode] !== undefined) ?? []
);

export const getFirstSegmentCarrierName = createSelector(
  getAllSegmentsExcludingARNK,
  (segments: Segment[]): string => segments?.[0]?.operatingAirline ?? ''
);

export const getFirstSegmentCarrierCode = createSelector(
  getAllSegmentsExcludingARNK,
  (segments: Segment[]): string => segments?.[0]?.operatedByAirlineCode ?? ''
);

/**
 * Get Seat Map Advisory for the reservation,
 * does not account for seatmap lookup errors
 */
export const getReservationSeatMapAdvisory = createSelector(
  getAllNonOASegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  (nonOASegments: Segment[], allSegments: Segment[]): SeatMapAdvisory | null => {
    if (!allSegments.length) {
      return SeatMapAdvisory.NO_ACTIVE_SEGMENTS;
    } else {
      if (!nonOASegments.length) {
        return SeatMapAdvisory.ALL_OA_SEGMENTS;
      }
    }
    return null;
  }
);

/**
 * CAUTION: this checks all segments, including past-dated, ARNK, COG, and waitlist
 * This is intended for initial booking purposes/pre ticketing
 */
export const getHasSaverFareSegment = createSelector(
  getAllSegments,
  (segments: Segment[]): boolean => segments && segments.filter((segment) => segment.serviceClassCode === 'X').length > 0
);

/**
 * Returns true if the reservation has at least 1 segment, false otherwise.
 */
export const getHasItinerary = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (segments: Segment[]): boolean => segments.length > 0
);

/**
 * Returns true if the reservation has at least 1 segment, false otherwise.
 */
export const getHasItineraryV2 = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2,
  (segments: Segment[]): boolean => segments.length > 0
);

/**
 * Gets segments on a reservation
 */
export const getConfirmedSegments = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (allSegments: Segment[]): Segment[] => allSegments?.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Confirmed) ?? []
);

/**
 * Gets segments on a reservation
 */
export const getConfirmedSegmentsV2 = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2,
  (allSegments: Segment[]): Segment[] => allSegments?.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Confirmed) ?? []
);

/**
 * Gets segments on a reservation with COG hidden legs as segments and waitlist segments
 */
export const getConfirmedSegmentsWithCOG = createSelector(getAllSegmentsExcludingPastDatedAndARNK, (allSegments: Segment[]): Segment[] =>
  allSegments?.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Confirmed)
);

/**
 * Gets segments on a reservation with COG hidden legs as segments and waitlist segments
 */
export const getConfirmedSegmentsWithCOGV2 = createSelector(
  getAllSegmentsExcludingPastDatedAndARNKV2,
  (allSegments: Segment[]): Segment[] => allSegments?.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Confirmed)
);

/**
 * Gets all past-dated and confirmed segments on a reservation
 */
export const getConfirmedAndPastDatedSegments = createSelector(
  getAllPastDatedSegments,
  getConfirmedSegments,
  (pastDatedSegments: Segment[], confirmedSegments: Segment[]): Segment[] => pastDatedSegments.concat(confirmedSegments)
);

/**
 * Gets all unique marketing and operating airline codes for confirmed segments
 */
export const getUniqueAirlineCodes = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (segments: Segment[]): AirlineCode[] => {
    const airlineCodes: AirlineCode[] = [];
    segments?.forEach((segment) => {
      airlineCodes.push(segment.marketedByAirlineCode as AirlineCode);
      airlineCodes.push(segment.operatedByAirlineCode as AirlineCode);
    });
    return [...new Set(airlineCodes)];
  }
);

/**
 * Gets at least one segments on a reservation
 */
export const getHasAConfirmedSegment = createSelector(
  getConfirmedSegments,
  (confirmedSegments: Segment[]): boolean => confirmedSegments.length > 0
);

/**
 * Gets at least one segments on a reservation
 */
export const getHasAConfirmedSegmentV2 = createSelector(
  getConfirmedSegmentsV2,
  (confirmedSegments: Segment[]): boolean => confirmedSegments.length > 0
);

/**
 * Gets a list of flight numbers in the order in which they appear on the reservation
 */
export const getConfirmedSegmentsFlightNumbers = createSelector(getConfirmedSegments, (confirmedSegments: Segment[]): string[] =>
  confirmedSegments.map((segment) => segment.operatingAirlineFlightNumber)
);

export const getConfirmedSegmentsHashIds = createSelector(getConfirmedSegments, (confirmedSegments: Segment[]): string[] =>
  confirmedSegments.map((segment) => segment.hashId)
);

/**
 * Gets segments on a reservation that are not ARNK
 */
export const getConfirmedNonArnkSegments = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (allSegments: Segment[]): Segment[] =>
    allSegments.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Confirmed && !segment.isARNK)
);

/**
 * Gets unconfirmed segments on a reservation
 */
export const getUnconfirmedSegments = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (allSegments: Segment[]): Segment[] => allSegments.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Unconfirmed)
);

/**
 * Gets unconfirmed segments on a reservation
 */
export const getUnconfirmedSegmentsV2 = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2,
  (allSegments: Segment[]): Segment[] => allSegments.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Unconfirmed)
);

/**
 * Gets unconfirmed segments on a reservation with COG hidden legs as segments and waitlist segments
 */
export const getUnconfirmedSegmentsWithCOG = createSelector(getAllSegmentsExcludingPastDatedAndARNK, (allSegments: Segment[]): Segment[] =>
  allSegments?.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Unconfirmed)
);

/**
 * Gets unconfirmed segments on a reservation with COG hidden legs as segments and waitlist segments
 */
export const getUnconfirmedSegmentsWithCOGV2 = createSelector(
  getAllSegmentsExcludingPastDatedAndARNKV2,
  (allSegments: Segment[]): Segment[] => allSegments?.filter((segment) => segment.confirmationStatus === ConfirmationStatus.Unconfirmed)
);

/**
 * Gets an array of hashIds for unconfirmed segments that have been replaced by confirmed segments
 */
export const getReplacedSegmentHashIds = createSelector(getConfirmedSegments, (confirmedSegments: Segment[]): string[] =>
  confirmedSegments
    ?.map((segment) => segment.originalSegmentHashIds ?? [])
    ?.flatMap((hashId) => hashId)
    ?.filter((hashId) => !!hashId)
);

/**
 * Gets an array of hashIds for unconfirmed segments that have been replaced by confirmed segments
 */
export const getReplacedSegmentHashIdsV2 = createSelector(getConfirmedSegmentsV2, (confirmedSegments: Segment[]): string[] =>
  confirmedSegments
    ?.map((segment) => segment.originalSegmentHashIds ?? [])
    ?.flatMap((hashId) => hashId)
    ?.filter((hashId) => !!hashId)
);

/**
 * Returns a list of all segments excluding the unconfirmed segments that have been matched to confirmed segments
 */
export const getAllUnmatchedSegments = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  getReplacedSegmentHashIds,
  (segments: Segment[], replacedSegmentHashIds: string[]): Segment[] =>
    segments.filter((segment) => !replacedSegmentHashIds.includes(segment.hashId))
);

/**
 * Get the placeholder reservation
 */
export const getPlaceholderReservation = createSelector(
  getReservationServiceState,
  (state): ReservationCreationResponse | null => state.placeholderReservation
);

/**
 * Get the reservation's passengers
 *
 */
export const getPassengers = createSelector(getRoutedReservation, (reservation: Reservation): Passenger[] => reservation?.passengers ?? []);

/**
 * Get the reservation's lap infants
 *
 */
export const getLapInfants = createSelector(getPassengers, (passengers: Passenger[]): (LapInfant | undefined)[] => {
  return passengers?.filter((passenger) => passenger.lapInfant)?.map((passenger) => passenger.lapInfant) ?? [];
});

/**
 * Get the reservation's passengers count
 *
 */
export const getPassengersCount = createSelector(getPassengers, (passengers: Passenger[]): number => passengers?.length ?? 0);

/**
 * Returns true if any segments have a waitlist, false otherwise
 */
export const getHasWaitlist = createSelector(getConfirmedSegments, (segments: Segment[]): boolean => {
  for (const segment of segments) {
    if (
      segment.actionCode === ActionCode.HK &&
      (segment.waitList === WaitListProperties.FIRST_CLASS ||
        segment.waitList === WaitListProperties.PREMIUM_AND_FIRST_CLASS ||
        segment.waitList === WaitListProperties.PREMIUM_CLASS)
    ) {
      return true;
    }
  }
  return false;
});

/**
 * Returns true if any passengers have an SSR, false otherwise
 */
export const getHasSsr = createSelector(getPassengers, (passengers: Passenger[]): boolean => {
  for (const passenger of passengers) {
    if (passenger.ssrs.length > 0) {
      return true;
    }
  }
  return false;
});

/**
 * Gets a single passenger by hash id
 */
export const getPassengerByHashId = (hashId: string) =>
  createSelector(getPassengers, (passengers: Passenger[]): Passenger | undefined => passengers.find((p) => p.hashId === hashId));

/**
 * Gets the first travelDocument for a passenger that is not a bio info nor a lap infant document
 */
export const getPassengerExistingInternationalDocumentByHashId = (hashId: string) =>
  createSelector(getPassengerByHashId(hashId), (passenger: Passenger): TravelDocument | undefined =>
    passenger?.secureFlightInfo?.travelDocuments?.find((doc) => doc.documentType != null)
  );

/**
 * Retrieves all SSRs on the reservation
 */
export const getAllSpecialServiceRequests = createSelector(getPassengers, (passengers: Passenger[]): SpecialServiceRequest[] => {
  const flattenedSsrs: SpecialServiceRequest[] = [];

  if (passengers) {
    for (const passenger of passengers) {
      for (const ssr of passenger.ssrs) {
        flattenedSsrs.push(ssr);
      }
    }
  }

  return flattenedSsrs;
});

/**
 * Retreives all OTHS SSRs
 */
export const getAllOtherSpecialServiceRequests = createSelector(
  getAllSpecialServiceRequests,
  (ssrs: SpecialServiceRequest[]): SpecialServiceRequest[] => ssrs.filter((ssr) => ssr.serviceCode === SSRCode.OTHS)
);

/**
 * Retreives all PETC and AVIH SSRs
 */
export const getPetRelatedSpecialServiceRequests = createSelector(
  getAllSpecialServiceRequests,
  (ssrs: SpecialServiceRequest[]): SpecialServiceRequest[] =>
    ssrs.filter((ssr) => ssr.serviceCode === SSRCode.PETC || ssr.serviceCode === SSRCode.AVIH)
);

/**
 * Get current status of PETC and AVIH SSRs
 */
export const getPetRelatedSpecialServiceRequestsStatusMap = createSelector(
  getPetRelatedSpecialServiceRequests,
  (ssrs: SpecialServiceRequest[]): Map<string, SsrDetail[]> => {
    const ssrsSegmentMap = new Map<string, SsrDetail[]>();

    for (const ssr of ssrs) {
      const status = getMostRecentSsrStatus(ssr);
      const ssrDetail = mapSsrToSsrDetail(ssr, status);
      const key = ssr.segmentHashId + '-' + ssr.serviceCode;

      if (ssrsSegmentMap.has(key)) {
        ssrsSegmentMap.get(key)?.push(ssrDetail);
      } else {
        ssrsSegmentMap.set(key, [ssrDetail]);
      }
    }

    return ssrsSegmentMap;
  }
);

/**
 * Gets the primary passenger's last name
 */
export const getPrimaryPassengerLastName = createSelector(getPassengers, (passengers: Passenger[]): string =>
  passengers.length > 0 ? passengers[0].lastName : ''
);

/**
 * Returns passengers and extraSeat passengers as a single level array
 */
export const getPassengersAndExtraSeatPassengers = createSelector(
  getPassengers,
  (passengers: Passenger[]): Passenger[] =>
    passengers?.flatMap((passenger) =>
      [passenger, (passenger.extraSeatRefs ?? []).flat()].filter((flatPassenger) => !!flatPassenger).flat()
    ) ?? []
);

/**
 * Returns true if any extra seat passengers exist, false otherwise
 */
export const getHasExtraSeatPassengers = createSelector(
  getPassengers,
  (passengers: Passenger[]): boolean => passengers?.some((passenger) => passenger.extraSeatRefs?.length > 0) ?? false
);

/**
 * Returns extraSeat passengers for a given passenger id. Defaults to an empty list.
 */
export const getExtraSeatPassengersById = (passengerId: string) =>
  createSelector(
    getPassengers,
    (passengers: Passenger[]): Passenger[] => passengers?.find((passenger) => passenger.id === passengerId)?.extraSeatRefs ?? []
  );

/**
 * Get the count of passengers and extraSeat passengers
 */
export const getPassengersAndExtraSeatPassengersCount = createSelector(
  getPassengersAndExtraSeatPassengers,
  (passengers: Passenger[]): number => passengers?.length ?? 0
);

/**
 * Check if the passenger has EXST/CBBG first name
 */
export const getPassengerCbbgExstFirstName = (hashId: string) =>
  createSelector(
    getPassengerByHashId(hashId),
    (passenger: Passenger): boolean => passenger?.firstName === ExtraSeatType.EXST || passenger?.firstName === ExtraSeatType.CBBG
  );

/**
 * Get the list of CBBG / EXST for a given passenger
 */
export const getPassengerCbbgExstList = (hashId: string) =>
  createSelector(getPassengerByHashId(hashId), (passenger: Passenger): string[] => {
    const cbbg: string[] = [];
    const exst: string[] = [];
    passenger?.extraSeatRefs?.map((seatRef) => {
      if (seatRef.firstName === ExtraSeatType.CBBG) {
        cbbg.push(seatRef.firstName);
      }
    });
    passenger?.extraSeatRefs?.map((seatRef) => {
      if (seatRef.firstName === ExtraSeatType.EXST) {
        cbbg.push(seatRef.firstName);
      }
    });
    return cbbg.concat(exst);
  });

/**
 * Get the list of CBBG / EXST for a given passenger
 * Limited to 99 EXST/CBBG combined
 */
export const getPassengerCbbgExstListLimited = (hashId: string, limit: number = 99) =>
  createSelector(getPassengerCbbgExstList(hashId), (cbbgExstList: string[]): string[] => cbbgExstList?.slice(0, limit) ?? []);

/**
 * Get the count of EXST for a given passenger
 */
export const getPassengerExstCountLimited = (hashId: string, limit: number = 99) =>
  createSelector(getPassengerCbbgExstListLimited(hashId, limit), (cbbgExstList: string[]): number => {
    return cbbgExstList?.filter((ssr) => ssr === ExtraSeatType.EXST).length ?? 0;
  });

/**
 * Get the count of CBBG for a given passenger
 */
export const getPassengerCbbgCountLimited = (hashId: string, limit: number = 99) =>
  createSelector(getPassengerCbbgExstListLimited(hashId, limit), (cbbgExstList: string[]): number => {
    return cbbgExstList?.filter((ssr) => ssr === 'CBBG').length ?? 0;
  });

/**
 * Get the reservation's passengers last names in an array
 */
export const getPassengersLastNames = createSelector(
  getRoutedReservation,
  (reservation: Reservation): string[] => reservation?.passengers.map((passenger) => passenger.lastName) ?? []
);

/**
 * Get the reservation's unique passengers last names in an array
 */
export const getUniquePassengersLastNames = createSelector(getPassengersLastNames, (lastNames: string[]): string[] => [
  ...new Set(lastNames?.map((lastName) => lastName.toUpperCase())),
]);

/**
 * Get the associationRevalError property value
 */
export const getAssociationRevalError = createSelector(getReservationServiceState, (state): boolean => state.associationRevalError);

/**
 * Get the inRevalFlow property value
 */
export const getInRevalFlow = createSelector(getReservationServiceState, (state): boolean => !!state.selectedInvoluntaryChangeOption);

/**
 * Get selected involuntary change option we chose from drop down (IRROP or SC)
 */
export const getSelectedInvoluntaryChangeOption = createSelector(
  getReservationServiceState,
  (state): string => state.selectedInvoluntaryChangeOption
);

/**
 * Gets all segments (confirmed and unconfirmed) on a reservation that have an action code
 * is contained in the involuntary change codes array and has not been replaced by a new segment
 * Works for IRROP since all IRRROP action codes are also Schedule Change codes
 */
export const getInvoluntaryChangeSegments = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  getReplacedSegmentHashIds,
  (allSegments: Segment[], replacementSegmentHashIds: string[]): Segment[] =>
    allSegments
      .filter((segment) => scheduleChangeActionCodes.includes(segment.actionCode)) // remove segments that are not schedule change segs
      .filter((segment) => !replacementSegmentHashIds.includes(segment.hashId)) // remove segments that have been replaced
);

/**
 * Gets all segments (confirmed and unconfirmed) on a reservation that have an action code
 * is contained in the involuntary change codes array and has not been replaced by a new segment
 * Works for IRROP since all IRRROP action codes are also Schedule Change codes
 */
export const getInvoluntaryChangeSegmentsV2 = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2,
  getReplacedSegmentHashIdsV2,
  (allSegments: Segment[], replacementSegmentHashIds: string[]): Segment[] =>
    allSegments
      .filter((segment) => scheduleChangeActionCodes.includes(segment.actionCode)) // remove segments that are not schedule change segs
      .filter((segment) => !replacementSegmentHashIds.includes(segment.hashId)) // remove segments that have been replaced
);

/**
 * Returns true if all involuntary change segments have a departure date that is greater than
 * 6 days out, false otherwise. This is used to determine IRROP eligiblity.
 */
export const getInvoluntaryChangeSegmentsGreaterThanSixDaysDeparture = createSelector(
  getInvoluntaryChangeSegments,
  (allSegments: Segment[]): boolean => {
    const outOfRangeDate = new Date();
    outOfRangeDate.setDate(outOfRangeDate.getDate() + 6);
    return allSegments.every((segment) => new Date(segment.departureDateTime ?? '') > outOfRangeDate);
  }
);

/**
 * Returns true if all involuntary change segments have a departure date that is greater than
 * 6 days out, false otherwise. This is used to determine IRROP eligiblity.
 */
export const getInvoluntaryChangeSegmentsGreaterThanSixDaysDepartureV2 = createSelector(
  getInvoluntaryChangeSegmentsV2,
  (allSegments: Segment[]): boolean => {
    const outOfRangeDate = new Date();
    outOfRangeDate.setDate(outOfRangeDate.getDate() + 6);
    return allSegments.every((segment) => new Date(segment.departureDateTime ?? '') > outOfRangeDate);
  }
);

/**
 * Returns whether or not a reservation contains an HK segment
 */
export const getHasHKSegment = createSelector(getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist, (allSegments: Segment[]): boolean =>
  allSegments.some((segment) => segment.actionCode === ActionCode.HK)
);

/**
 * Returns whether or not a reservation contains an IRROP
 */
export const getHasIRROP = createSelector(getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist, (allSegments: Segment[]): boolean =>
  allSegments.some((segment) => irropActionCodes.includes(segment.actionCode))
);

/**
 * Returns whether or not a reservation contains an IRROP
 */
export const getHasIRROPV2 = createSelector(getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2, (allSegments: Segment[]): boolean =>
  allSegments.some((segment) => irropActionCodes.includes(segment.actionCode))
);

/**
 * Returns whether or not a reservation contains a schedule change
 */
export const getHasScheduleChange = createSelector(getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist, (allSegments: Segment[]): boolean =>
  allSegments.some((segment) => scheduleChangeActionCodes.includes(segment.actionCode))
);

/**
 * Returns whether or not a reservation contains an active schedule change
 * Excludes reservations where all of the segments are HK
 */
export const getHasActiveScheduleChange = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (allSegments: Segment[]): boolean =>
    allSegments.some((segment) => scheduleChangeConfirmedActionCodes.includes(segment.actionCode)) ||
    allSegments.some((segment) => scheduleChangeUnconfirmedActionCodes.includes(segment.actionCode))
);

/**
 * Returns whether or not a reservation contains an active schedule change
 * Excludes reservations where all of the segments are HK
 */
export const getHasActiveScheduleChangeV2 = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2,
  (allSegments: Segment[]): boolean =>
    allSegments.some((segment) => scheduleChangeConfirmedActionCodes.includes(segment.actionCode)) ||
    allSegments.some((segment) => scheduleChangeUnconfirmedActionCodes.includes(segment.actionCode))
);

/**
 * Returns the SsrType for use when adding reservation info.
 * We only need to check marketed airline code because the Sabre sections (AS Facts/General Facts) are based on it alone
 */
export const getSsrType = createSelector(getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist, (allSegments: Segment[]): SsrType => {
  // All segments on the reservation are OA, so add all reservation info to General Facts (OtherAirline type)
  if (allSegments.every((segment) => !(segment.marketedByAirlineCode in AirgroupAirlineCode))) {
    return SsrType.OtherAirline;
  }
  // All segments on the reservation are AS, so add all reservation info to AS Facts (AlaskaAirline type)
  else if (allSegments.every((segment) => segment.marketedByAirlineCode in AirgroupAirlineCode)) {
    return SsrType.AlaskaAirline;
  }
  // Some segments are operated by OA, some segments are operated by AS
  // In this scenario we will make 2 requests, 1 with OtherAirline type and 1 with AlaskaAirline type
  // So we return Both type so the component knows to fire 2 requests
  else {
    return SsrType.Both;
  }
});

/**
 * Returns the schedule change button disabled state
 * SC is disabled if:
 *    There is not an SC scenario detected on the reservation
 *    The ticket(s) are issued by OA (other airline)
 *    The reservation is missing a valid phone field (phone number or PWCT) [will fail reval/reissue without]
 */
export const getScheduleChangeIneligible = createSelector(
  getHasScheduleChange,
  getIsTicketIssuedByOtherAirline,
  getHasValidPhoneField,
  (hasScheduleChange, isTicketIssuedByOA, hasValidPhoneField): boolean => !hasScheduleChange || isTicketIssuedByOA || !hasValidPhoneField
);

/**
 * Returns the IRROP button disabled state
 * IRROP will be disabled if:
 *    There is not IRROP scenario detected on the reservation
 *    The IRROP segment is not within 6 days of departure
 *    The reservation is missing a valid phone field (phone number or PWCT) [will fail reval/reissue without]
 */
export const getIrropIneligible = createSelector(
  getHasIRROP,
  getInvoluntaryChangeSegmentsGreaterThanSixDaysDeparture,
  getHasValidPhoneField,
  (hasIRROP, datesOutOfRange, hasValidPhoneField): boolean => !hasIRROP || datesOutOfRange || !hasValidPhoneField
);

/**
 * Returns the IRROP button disabled state
 * IRROP will be disabled if:
 *    There is not IRROP scenario detected on the reservation
 *    The IRROP segment is not within 6 days of departure
 *    The reservation is missing a valid phone field (phone number or PWCT) [will fail reval/reissue without]
 */
export const getIrropIneligibleV2 = createSelector(
  getHasIRROPV2,
  getInvoluntaryChangeSegmentsGreaterThanSixDaysDepartureV2,
  getHasValidPhoneField,
  (hasIRROP, datesOutOfRange, hasValidPhoneField): boolean => !hasIRROP || datesOutOfRange || !hasValidPhoneField
);

/**
 * Returns the SSR button disabled state
 */
export const getSSRIneligible = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (segments): boolean => segments.length === 0
);

/**
 * Returns the SSR button disabled state
 */
export const getSSRIneligibleV2 = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlistV2,
  (segments): boolean => segments.length === 0
);

/**
 * Returns the change seats button disabled state
 */
export const getSeatChangeIneligible = createSelector(
  getHasActiveScheduleChange,
  getHasHKSegment,
  getCallerName,
  getPaymentLink,
  (hasActiveScheduleChange, hasHKSegment, callerName, paymentLink): boolean =>
    hasActiveScheduleChange || !callerName || !hasHKSegment || !!paymentLink
);

/**
 * Returns the change seats button disabled state
 */
export const getSeatChangeIneligibleV2 = createSelector(
  getHasActiveScheduleChangeV2,
  getHasHKSegment,
  getCallerName,
  getPaymentLink,
  (hasActiveScheduleChange, hasHKSegment, callerName, paymentLink): boolean =>
    hasActiveScheduleChange || !callerName || !hasHKSegment || !!paymentLink
);

/**
 * Returns true if there are any segments operated by a non-airgroup airline, false otherwise
 */
export const getAnySegmentOperatedByNonAirgroupAirline = createSelector(
  getAllSegmentsExcludingPastDatedARNKCOGAndWaitlist,
  (segments): boolean => segments.some((segment) => !(segment.operatedByAirlineCode in AirgroupAirlineCode))
);

/**
 * Gets all the mileage plan numbers on a reservation
 */
export const getMileagePlanForAllPassengersOnActiveReservation = createSelector(
  getRoutedReservation,
  (reservation: Reservation): string[] => {
    const result =
      reservation?.passengers
        ?.filter((passenger) => (passenger.loyalty?.airlineCode ?? '') === 'AS')
        .map((passenger) => passenger.loyalty?.number.toString() ?? '') ?? [];
    return result;
  }
);

/**
 * Gets a passenger redress number
 */
export const getPassengerRedressNumber = (confirmationCode: string, passengerHashId: string) =>
  createSelector(
    getAllReservationEntities,
    (entities: Dictionary<ReservationResponse>) =>
      (
        (((entities[confirmationCode] ?? {})?.reservation ?? {}).passengers ?? []).find((passenger) => passenger.hashId === passengerHashId)
          ?.secureFlightInfo ?? {}
      ).redressNumber ?? null
  );

/**
 * Gets a passenger known traveler number
 */
export const getPassengerKnownTravelerNumber = (confirmationCode: string, passengerHashId: string) =>
  createSelector(
    getAllReservationEntities,
    (entities: Dictionary<ReservationResponse>) =>
      (
        (((entities[confirmationCode] ?? {})?.reservation ?? {}).passengers ?? []).find((passenger) => passenger.hashId === passengerHashId)
          ?.secureFlightInfo ?? {}
      ).knownTravelerNumber ?? null
  );

export const getPassengerSsrConfirmedSegment = (passengerSsr: PassengerSSR) =>
  createSelector(
    getConfirmedSegments,
    (segments: Segment[]): Segment => segments.find((s) => s.hashId === passengerSsr?.ssr?.segmentHashId) ?? ({} as Segment)
  );

export const getSsrsByPassenger = (segmentHashId: string, passengerId: string) =>
  createSelector(getPassengers, (passengers: Passenger[]): SpecialServiceRequest[] => {
    for (const passenger of passengers) {
      if (passenger.id === passengerId) {
        return passenger.ssrs.filter((ssr) => ssr.segmentHashId === segmentHashId || ssr.segmentHashId === undefined);
      }
    }

    return [];
  });

/**
 * Gets the current seat for a provided passenger and segment.
 */
export const getPassengerCurrentSeat = (passengerHashId: string, segmentHashId: string) =>
  createSelector(getPassengersAndExtraSeatPassengers, (passengers: Passenger[]) =>
    passengers.find((pax) => pax.hashId === passengerHashId)?.seats.find((seat) => seat.segmentHashId === segmentHashId)
  );

/**
 * Gets the original seat for a provided passenger and segment.
 */
export const getPassengerOriginalSeat = (passengerHashId: string, segmentHashId: string | null) =>
  createSelector(getPassengersAndExtraSeatPassengers, (passengers: Passenger[]) =>
    passengers.find((pax) => pax.hashId === passengerHashId)?.originalSeats?.find((seat) => seat.segmentHashId === segmentHashId)
  );

/**
 * This selector is used to determine if the currently assigned seat for a passenger
 * is different from the original seat class (e.g., Premium Class, Main Preferred, Exit Row).
 */
export const isSeatsPrimaryPropertyMismatch = (passengerHashId: string, segmentHashId: string) =>
  createSelector(
    getPassengerCurrentSeat(passengerHashId, segmentHashId),
    getPassengerOriginalSeat(passengerHashId, segmentHashId),
    (currentSeat: PassengerSeat, originalSeat: PassengerSeat) => {
      return currentSeat?.primaryProperty !== originalSeat?.primaryProperty;
    }
  );

/**
 * Gets the original seat for a provided passenger and segment.
 */
export const getPassengerOriginalSeatPremium = (passengerHashId: string, segmentHashId: string) =>
  createSelector(getPassengerOriginalSeat(passengerHashId, segmentHashId), (originalSeat: PassengerSeat) =>
    originalSeat?.upsellName === 'Premium' ? true : false
  );

/**
 * Gets the price of the original seat for a provided passenger and segment.
 */
export const getPassengerOriginalSeatPrice = (passengerHashId: string, segmentHashId: string | null) =>
  createSelector(getPassengerOriginalSeat(passengerHashId, segmentHashId), (originalSeat: PassengerSeat) => originalSeat?.upsellPrice ?? 0);

/**
 * Returns status of the cancel reservation operation.
 */
export const getCancelReservationStatus = createSelector(
  getReservationServiceState,
  (state): Status => state?.reservationCancelResponse?.status ?? null
);

/**
 * Returns statusCode of the cancel reservation operation.
 */
export const getCancelReservationStatusCode = createSelector(
  getReservationServiceState,
  (state): number => state?.reservationCancelResponse?.statusCode ?? null
);

/**
 * Returns content of the cancel reservation response.
 */
export const getCancelReservationResponseContent = createSelector(
  getReservationServiceState,
  (state): string => state?.reservationCancelResponse?.content ?? null
);

/**
 * Returns true if the cancel reservation response is Success/No itinerary/PNR not found.
 */
export const getCancelReservationRedirect = createSelector(getCancelReservationResponseContent, (content: string): boolean => {
  if (!content) {
    return false;
  }
  return content.includes('Success') || content.includes('No itinerary') || content.includes('PNR not found') ? true : false;
});

/**
 * Returns true if is Award Booking
 */
export const getIsAwardBooking = createSelector(getAllSegmentsExcludingARNK, (segments: Segment[]): boolean => {
  return segments?.length > 0 && segments.every((segment) => segment?.fareBasisCode?.endsWith('/ASWD') || segment?.fareBasisCode?.endsWith('/PAWD'));
});

/**
 * CAUTION: This checks all segments, regardless of past-dated, ARNK, COG, or waitlist
 * This returns the strictest rule for the reservation
 * Non-refundable is more strict than refundable
 * Refundability for revenue segments is determined by the letter at the end of the fareBasisCode
 *      'N' is non-refundable
 *      'R' is refundable
 * Award segments are always refundable, they will not have the above letter, their fareBasisCode ends with /ASWD or /PAWD
 */
export const getRefundability = createSelector(getAllSegments, (segments: Segment[]): Refundability => {
  // Set unknown refundability for scenarios where we don't have the fareBasisCode or for if the below rules ever change
  // We never want to assume a refundbility status
  let refundability = Refundability.UNKNOWN;
  segments?.forEach((segment) => {
    // Check for award segments first, since they are always refundable and will not have a letter code
    // Then check for 'R' on revenue segments, which identifies a ticket as refundable
    if (segment.fareBasisCode?.endsWith('/ASWD') || segment.fareBasisCode?.endsWith('/PAWD') || segment.fareBasisCode?.endsWith('R')) {
      refundability = Refundability.REFUNDABLE;
    } else if (segment.fareBasisCode?.endsWith('N')) {
      refundability = Refundability.NON_REFUNDABLE;
      // Break if we find a non-refundable segment, since non-refundable is the strictest rule
      return;
    }
  });
  return refundability;
});

/**
 * Returns the FareRule for the AddRainBookingRemarksRequest
 * This is used solely for the AddRainBookingRemarks endpoint. Make sure you are using the correct
 * selector **getRefundability** or **getAddRainBookingRemarksRequestFareRule** when dealing with refundability
 */
export const getAddRainBookingRemarksRequestFareRule = createSelector(
  getAllSegments,
  getHasSaverFareSegment,
  (segments: Segment[], hasSaverFare: boolean): FareRule => {
    if(hasSaverFare) {
      return FareRule.SAVE_FARE;
    }

    if(segments.some((segment) => segment.fareBasisCode?.endsWith('N'))) {
      return FareRule.NON_REFUNDABLE;
    }

    if(segments.some((segment) => segment.fareBasisCode?.endsWith('/PAWD'))) {
      return FareRule.PARTNER_AWARD;
    }

    if(segments.some((segment) => segment.fareBasisCode?.endsWith('/ASWD') || segment.fareBasisCode?.endsWith('R'))) {
      return FareRule.REFUNDABLE;
    }

    return FareRule.UNKNOWN;
  }
);

/**
 * Returns AddRainBookingRemarksRequest for the reservation
 */
export const getAddRainBookingRemarksRequest = createSelector(
  getAddRainBookingRemarksRequestFareRule,
  getFirstSegmentCarrierCode,
  (fareRule: FareRule, airlineCode: string): AddRainBookingRemarksRequest => {
    return {
      fareRule,
      airlineCode,
    };
  }
);

/**
 * Returns whether the reservation has at least 1 passenger with Alaska tier status Gold, Gold75, or Gold100K
 */
export const getHasAlaskaLoyaltyGoldOrHigher = createSelector(
  getPassengers,
  (passengers: Passenger[]): boolean =>
    passengers?.filter((passenger) => {
      if (passenger.loyalty?.tierStatus) {
        const mappedStatus = getTierStatusFromText(passenger.loyalty?.tierStatus);
        return mappedStatus === TierStatus.GOLD_100K || mappedStatus === TierStatus.GOLD_75K || mappedStatus === TierStatus.GOLD;
      }
      return false;
    }).length > 0
);

/**
 * Returns true if the reservation has a TAC
 * TACs will cause all sorts of problems, agents need to remove them before performing any actions
 */
export const getHasTAC = createSelector(
  getRoutedReservation,
  (reservation: Reservation): boolean =>
    reservation?.ticketingInfo?.futureTicketing?.some((futureTicketing) => futureTicketing.code === 'TAC') ?? false
);

/**
 * Returns true if the reservation has an active ticket time limit
 */
export const getHasActiveTicketTimeLimit = createSelector(getRoutedReservation, (reservation: Reservation): boolean => {
  const ticketTimeLimit = reservation?.ticketingInfo?.ticketingTimeLimit?.ticketTimeLimit ?? false;
  return ticketTimeLimit && new Date(ticketTimeLimit) > new Date();
});

/**
 * Returns true if the reservation is a standalone extra seat reservation
 */
export const getIsStandaloneExtraSeatReservation = createSelector(
  getRoutedReservation,
  (reservation: Reservation): boolean => reservation?.isStandaloneExtraSeatReservation ?? false
);

export function isChangedSeat(passengerCurrentSeat: PassengerSeat | undefined, passengerOriginalSeat: PassengerSeat | undefined): boolean {
  return getPassengerSeatRowAndLetter(passengerCurrentSeat) !== getPassengerSeatRowAndLetter(passengerOriginalSeat);
}

export function getPassengerSeatRowAndLetter(passengerSeat: PassengerSeat | undefined): string {
  let ps = '-';
  if (passengerSeat && passengerSeat.row) {
    ps = `${passengerSeat.row}${passengerSeat.letter}`;
  }
  return ps;
}

function getMostRecentSsrStatus(ssr: SpecialServiceRequest): string {
  //map the SSR's most recent Remark status
  const petRemarks = ssr?.parsedDetails?.petRemarks;
  const petRemarkStatus = petRemarks && petRemarks?.length > 0 ? petRemarks[petRemarks.length - 1]?.statusCode : '';
  let status = PetSsrInventoryStatus.PENDING;
  switch (petRemarkStatus) {
    case 'UNCONFIRMED':
      status = PetSsrInventoryStatus.UNCONFIRMED_SSR_ADDED;
      break;
    case 'CONFIRMED':
      status = PetSsrInventoryStatus.CONFIRMED;
      break;
  }
  return status;
}

function mapSsrToSsrDetail(ssr: SpecialServiceRequest, status: string): SsrDetail {
  return {
    ssrId: ssr.id,
    ssrCode: ssr.serviceCode,
    petInventoryStatus: status,
    cannedFreeText: ssr.freeText,
    passengerDetails: {} as Passenger,
    segmentDetails: {
      segmentHashId: ssr.segmentHashId,
      origin: ssr.origin,
      destination: ssr.destination,
      departureDate: ssr.flightDate,
      carrierCode: ssr.vendorCode,
      bookingStatus: ssr.actionCode,
      flightNumber: ssr.flightNumber,
    } as AddSsrSelectedFlight,
  } as SsrDetail;
}
