import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, ActivatedRoute, Event } from '@angular/router';
import { LoginServiceState } from 'src/app/services/login-service/state/login-service.state';
import { Store, select } from '@ngrx/store';
import { getUser } from 'src/app/services/login-service/state/login-service.selector';
import { UserData } from 'src/app/dtos/response/login-response/user-data';
import { Subject, filter, take, takeUntil } from 'rxjs';
import { logout } from '../../../services/login-service/state/login-service.actions';
import { openDrawer } from 'src/app/utils/drawer-helper';
import SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import { RootState } from 'src/app/state/state';
import { getAllBagsAndAncillaryFees } from '../../../services/fee-service/state/fee.actions';
import { getHasItinerary, getIsGroupReservation, getPassengersCount } from '../../../services/reservation-service/state/reservation-service.selectors';
import { addGlobalMessage, resetJetStreamShortMessages } from '../../../services/message-service/state/message.actions';
import { MessageKey } from '../../../models/message/message-key';
import { getTravelAdvisories } from '../../../services/travel-advisories-service/state/travel-advisories-service.actions';
import { getActiveTravelAdvisoriesCount } from '../../../services/travel-advisories-service/state/travel-advisories-service.selectors';
import { UserPermissions } from '../../../dtos/response/login-response/user-permissions';

@Component({
  selector: 'app-common-header-layout',
  templateUrl: './common-header-layout.component.html',
  styleUrls: ['./common-header-layout.component.scss'],
})
export class CommonHeaderLayoutComponent implements OnInit, OnDestroy {
  agentName: string;
  user: UserData;
  selectedValue: string;
  routeUrl: string;
  menuId: string;
  attributes: SplitIO.Attributes;
  hasItinerary: boolean;
  isGroupReservation: boolean;
  passengerCount: number;

  onDestroy$ = new Subject<void>();

  menuItems = [
    { id: 'dashboard', label: 'Dashboard', link: '/home/dashboard', icon: 'dashboard-stroke', iconCategory: 'interface' },
    { id: 'reservation-search', label: 'Reservation search', link: '/search', icon: 'reservation-stroke', iconCategory: 'interface' },
    {
      id: 'classic-shop',
      label: 'Classic SHOP',
      link: 'https://agent.alaskaair.com/planbook/?ImageResCleared=true&token=7d01686f-bcb1-4b01-9fb9-912ce52ea98a',
      linkTarget: '_parent',
      icon: 'plane-up-stroke',
      iconCategory: 'terminal',
      externalLink: true,
    },
    {
      id: 'book-flights',
      label: 'Book flights',
      link: '/book-flights/flight-search',
      icon: 'plane-up-stroke',
      iconCategory: 'terminal',
      isBeta: true
    },
    {
      id: 'guest-profile-search',
      label: 'Guest profile search',
      link: '/mileage-plan-profile-search',
      icon: 'guest-stroke',
      iconCategory: 'interface',
    },
    { id: 'ticket-search', label: 'Ticket search', link: '/coupon-record-search', icon: 'ticket-stroke', iconCategory: 'interface' },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginServiceStore: Store<LoginServiceState>,
    private store: Store<RootState>) {}

  ngOnInit(): void {
    this.store.pipe(select(getHasItinerary)).subscribe((hasItinerary) => {
      this.hasItinerary = hasItinerary;
    });
    this.store.pipe(select(getIsGroupReservation)).subscribe((isGroupReservation) => {
      this.isGroupReservation = isGroupReservation;
    });
    this.store.pipe(select(getPassengersCount)).subscribe((passengerCount) => {
      this.passengerCount = passengerCount;
    });
    this.store.dispatch(getTravelAdvisories());
    this.store.pipe(select(getActiveTravelAdvisoriesCount), takeUntil(this.onDestroy$)).subscribe((advisoryCount) => {
      if (advisoryCount > 0) {
        if (advisoryCount === 1) {
          this.store.dispatch(addGlobalMessage(MessageKey.ONE_TRAVEL_ADVISORY));
        } else {
          this.store.dispatch(addGlobalMessage(MessageKey.MULTIPLE_TRAVEL_ADVISORY, [advisoryCount]));
        }
      }
    });

    // remove any inline styles from body applied by alaska-navigation element on initial load
    document.querySelector('body')?.removeAttribute('style');

    // update the menu indicator when deep linking to reservation summary page
    this.updateActiveMenuIndicatior();

    this.loginServiceStore.pipe(select(getUser), take(1)).subscribe((user: UserData) => {
      this.agentName = `${user.firstName} ${user.lastName}`;
      this.user = user;
      // Check if the agent has limited permissions
      // This can happen if the agent's Sabre profile is setup incorrectly
      if (user.permissions?.length === 0 || !user.sabreAAA) {
        this.store.dispatch(addGlobalMessage(MessageKey.LIMITED_PERMISSIONS, [user.username]));
      }
      // Check if the agent is missing overbook permissions (all agent should have this permission)
      // Overbook EPR = OBCINH
      // Only check if the agent has permissions
      if (user.permissions?.length > 0 && !user.permissions?.includes(UserPermissions.OBCINH)) {
        this.store.dispatch(addGlobalMessage(MessageKey.MISSING_OBCINH_PERMISSION, [user.username]));
      }
    });

    this.router.events.pipe(
      filter((e: Event | RouterEvent): e is RouterEvent => e instanceof NavigationEnd),
      takeUntil(this.onDestroy$)
    )
    .subscribe((e: RouterEvent) => {
      if (e.url.startsWith('/reservation')) {
        this.updateActiveMenuIndicatior();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  openDrawerHelper({ tagName, id }: any) {
    const selector: HTMLElement = document.querySelector(tagName);
    const drawerElement = document.querySelector('#' + id);
    if (drawerElement) {
      drawerElement.removeAttribute('selected');
    }
    if (tagName !== '#resourceCenter' && selector) {
      selector.addEventListener('toggle', () => {
        this.updateActiveMenuIndicatior();
      });
    }
    if (tagName === '#drawerBaggage' && this.hasItinerary && !this.isGroupReservation && this.passengerCount < 9) {
      this.store.dispatch(resetJetStreamShortMessages());
      this.store.dispatch(getAllBagsAndAncillaryFees());
    }
    openDrawer(tagName);
  }

  logout() {
    this.loginServiceStore.dispatch(logout());
  }

  userProfileClick(id) {
    document.querySelector('#' + id)?.removeAttribute('selected');
  }

  updateActiveMenuIndicatior() {
    this.route.firstChild?.data.pipe(take(1)).subscribe((val) => {
      if (val && Object.keys(val).length > 0) {
        this.updateActiveMenuIndicatorHelper(val.menuId);
      } else {
        // this following code snippet is to extract route data from nested child lazy modules
        this.updateActiveMenuIndicatorHelper(this.traverseChildRouteTree(this.route.firstChild));
      }
    });
  }

  updateActiveMenuIndicatorHelper(menuId: string) {
    if (menuId) {
      this.menuId = menuId;
      const menuItem = document.querySelector(`#${menuId}`);
      if (menuItem && !menuItem.hasAttribute('selected')) {
        menuItem.setAttribute('selected', 'true');
      }
    }
  }

  // extract activated route data from child modules
  traverseChildRouteTree(route: ActivatedRoute | null) {
    if (!route || !route.routeConfig) {
      return;
    }

    if (route.routeConfig.data && Object.keys(route.routeConfig.data).length > 0) {
      return route.routeConfig.data?.menuId;
    }

    return this.traverseChildRouteTree(route.firstChild);
  }
}
