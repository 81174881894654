import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PassengerFormsState, lapInfantFormsAdapter, passengerFormsAdapter, passengerFormsFeatureKey } from './passenger-forms.state';
import { PassengerForm } from '../../../models/passenger-forms/passenger-form';
import { LapInfantForm } from 'src/app/models/passenger-forms/lap-infant-form';
import { ContactInfoForm } from '../../../models/passenger-forms/contact-info-form';
import {
  getIsStandaloneExtraSeatReservation,
  getPassengerCbbgExstFirstName,
} from 'src/app/services/reservation-service/state/reservation-service.selectors';
import { MileagePlanAutoFillStatus } from 'src/app/models/mileage-plan-auto-fill-status';
import { getMileagePlanOutageMessageActive } from 'src/app/services/message-service/state/message.selectors';
import { MileagePlanForm } from 'src/app/models/passenger-forms/mileage-plan-form';
import { ContactTracingForm } from 'src/app/models/passenger-forms/contact-tracing-form';
import { CountryList } from '../../../models/countries/country-list';
import { getAllOKCoupons } from 'src/app/services/ticket-service/state/ticket-service.selectors';
import { CouponStatusData } from 'src/app/dtos/response/vcr-response/coupon-status-data';

/**
 * Get adapter selection methods to allow access to the passenger form entities in the store
 */
const { selectAll } = passengerFormsAdapter.getSelectors();

/**
 * Get the entire passenger forms info slice of state
 */
const getPassengerFormsState = createFeatureSelector<PassengerFormsState>(passengerFormsFeatureKey);

/**
 * Gets all passenger form data
 */
export const getPassengerForms = createSelector(getPassengerFormsState, (state) =>
  state?.passengerForms ? passengerFormsAdapter.getSelectors().selectAll(state?.passengerForms) : []
);

/**
 * Gets all lap infant form data
 */
export const getLapInfantForms = createSelector(getPassengerFormsState, (state) =>
  state?.lapInfantForms ? lapInfantFormsAdapter.getSelectors().selectAll(state?.lapInfantForms) : []
);

/**
 * Gets passenger forms where the hasPrepopulatedData flag is true
 */
export const getFormsWithPrepopulatedData = createSelector(
  getPassengerForms,
  (passengerForms) => passengerForms?.filter((passengerForm) => passengerForm.hasPrepopulatedData) ?? []
);

/**
 * For a given infant form, return the hasPrepopulatedData flag
 */
export const getInfantHasPrepopulatedData = (infantHashId) =>
  createSelector(getLapInfantForm(infantHashId), (infantForm) => infantForm?.hasPrepopulatedData ?? false);

/**
 * Gets all saved passenger form data
 * If the form has been saved before, only check if the form has been modified
 */
export const getSavedPassengerForms = createSelector(
  getPassengerForms,
  getIsStandaloneExtraSeatReservation,
  (passengerForms: PassengerForm[], isStandaloneExtraSeatReservation: boolean) => {
    // If the reservation is a standalone extra seat reservation, return all passenger forms
    // Standlone extra seat reservations do not require any data and should be treated as saved
    if (isStandaloneExtraSeatReservation) {
      return passengerForms;
    }
    return (
      passengerForms?.filter(
        (passengerForm) => !isDataChanged(passengerForm) && (passengerForm.formSaved || passengerForm.hasPrepopulatedData)
      ) ?? []
    );
  }
);

/**
 * Returns true when a form has unsaved changes
 * If the form has lastSavedData (it had reservation data or had been saved once) then we compare the data to that
 *  Otherwise, we check if the form has been saved via the formSaved property
 */
export const getFormHasUnsavedChanges = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) =>
    passengerForm.lastSavedData ? isDataChanged(passengerForm) : !passengerForm.formSaved
  );

export const getInfantFormHasUnsavedChanges = (infantHashId: string) =>
  createSelector(getLapInfantForm(infantHashId), (infantForm: LapInfantForm) =>
    infantForm?.lastSavedData ? isDataChanged(infantForm, true) : !infantForm?.formSaved
  );

export const getSavedPassengerFormsCount = createSelector(
  getSavedPassengerForms,
  (passengerForms: PassengerForm[]) => passengerForms?.length ?? 0
);

/**
 * Gets infant formSaved slice of state
 */
export const getInfantFormSaved = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state?.lapInfantForms?.entities[passengerHashId]?.formSaved ?? false);

/**
 * Gets all passenger contact info form data
 */
export const getContactInfoForm = createSelector(getPassengerFormsState, (state) => {
  return state?.contactInfoForm ?? {};
});

/**
 * Returns whether the guest conatact info save button should be enabled based on whether all guest contact info is filled out
 */
export const getSaveContactButtonEnabled = createSelector(getContactInfoForm, (contactInfoForm) => contactInfoForm?.formValid ?? false);

/**
 * Returns whether the guest conatact info form has been saved
 */
export const getContactInfoSaved = createSelector(getContactInfoForm, (contactInfoForm) => contactInfoForm?.formSaved ?? false);

/**
 * Returns whether all of the passenger forms have been saved at least once with the minimum required data
 */
export const getAreAllPassengerFormsMinimumRequiredSaved = createSelector(
  getPassengerForms,
  getIsStandaloneExtraSeatReservation,
  getContactInfoSaved,
  (passengerForms: PassengerForm[], isStandaloneExtraSeatReservation: boolean, reservationInformationSaved: boolean) => {
    // If the reservation is a standalone extra seat reservation, return true if the reservation information is saved
    // Standlone extra seat reservations do not require any data and should be treated as saved
    if (isStandaloneExtraSeatReservation && reservationInformationSaved) {
      return true;
    }

    if (passengerForms == null || passengerForms.length <= 0) {
      return false;
    }

    const minimumRequiredSaved = passengerForms.every(
      (passengerForm) =>
        !!passengerForm?.lastSavedData?.bio?.dateOfBirth &&
        !!passengerForm?.lastSavedData?.bio?.gender &&
        !!passengerForm?.lastSavedData?.bio?.firstName &&
        !!passengerForm?.lastSavedData?.bio?.lastName
    );

    return minimumRequiredSaved ?? false;
  }
);

/**
 * Returns the list of infant count
 */
export const getInfantIds = createSelector(getPassengerFormsState, (state) => state?.infantIds ?? []);

/**
 * Gets whether a passenger's emeregncy contact form is shown
 */
export const showPassengerFormGuestEmergencyContactForm = (passengerHashId: string) =>
  createSelector(
    getPassengerFormsState,
    (state) => state.passengerForms?.entities[passengerHashId]?.emergencyContactForm?.showForm ?? false
  );

/**
 * Gets whether a passenger's international document form is shown
 */
export const showPassengerFormInternationalDocumentsForm = (passengerHashId: string) =>
  createSelector(
    getPassengerFormsState,
    (state) => state.passengerForms?.entities[passengerHashId]?.internationalDocumentsForm?.showForm ?? false
  );

/**
 * Gets whether a passenger's contact tracing form is shown
 */
export const showPassengerFormContactTracingForm = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state.passengerForms?.entities[passengerHashId]?.contactTracingForm?.showForm ?? false);

/**
 * Gets whether a passenger's mileage plan form is saved
 */
export const getPassengerMileagePlanFormSaved = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state.passengerForms?.entities[passengerHashId]?.mileagePlanSaved ?? false);

/**
 * Gets the saved mileage plan number for a passenger
 */
export const getPassengerSavedMileagePlanNumber = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state.passengerForms?.entities[passengerHashId]?.savedMileagePlanNumber ?? false);

/**
 * Gets whether a passenger's emeregncy contact form is saved
 */
export const getPassengerEmergencyContactFormSaved = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state.passengerForms?.entities[passengerHashId]?.emergencyContactForm?.saved ?? false);

/**
 * Gets whether a passenger's international document form is saved
 */
export const getPassengerInternationalDocumentsSaved = (passengerHashId: string) =>
  createSelector(
    getPassengerFormsState,
    (state) => state.passengerForms?.entities[passengerHashId]?.internationalDocumentsForm?.saved ?? false
  );

/**
 * Gets whether a passenger's contact tracing form is saved
 */
export const getPassengerContactTracingFormSaved = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state.passengerForms?.entities[passengerHashId]?.contactTracingForm?.saved ?? false);

/**
 * Gets a passenger form for a given passenger hashID
 */
export const getPassengerForm = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state.passengerForms?.entities[passengerHashId] ?? null);

/**
 * Gets a infant form for a given infant hashID
 */
export const getLapInfantForm = (passengerHashId: string) =>
  createSelector(getPassengerFormsState, (state) => state?.lapInfantForms?.entities[passengerHashId] ?? null);

/**
 * Gets whether the bio form for a passenger form is valid
 */
export const getBioFormValid = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => {
    if (
      passengerForm.bioForm?.firstName &&
      passengerForm.bioForm?.firstNameValid &&
      passengerForm.bioForm?.lastName &&
      passengerForm.bioForm?.lastNameValid &&
      passengerForm.bioForm?.dateOfBirth &&
      passengerForm.bioForm?.dateOfBirthValid &&
      passengerForm.bioForm?.gender &&
      passengerForm.bioForm?.genderValid
    ) {
      return true;
    }
    return false;
  });

/**
 * Gets whether the mileage plan form for a passenger form is valid
 */
export const getBioMiddleNameFormValid = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => {
    // Check for optional optional bio middle name
    if (passengerForm.bioForm?.middleName) {
      // If middle name is present, it must be valid
      return passengerForm.bioForm.middleNameValid;
    }
    // Middle name is optional, so no input is a valid state
    return true;
  });

/**
 * Gets whether the mileage plan form for a passenger form is valid
 */
export const getMileagePlanFormValid = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => {
    // Check for optional mileage plan data
    if (passengerForm.mileagePlanForm) {
      // If any mileage plan data is present
      // Check mileage program validity as well since it is a select and will always have a value
      if (
        passengerForm.mileagePlanForm.mileagePlanNumber ||
        (passengerForm.mileagePlanForm.mileageProgram && passengerForm.mileagePlanForm.mileageProgramValid)
      ) {
        // If mileage plan data is present, it must have a number and program
        if (passengerForm.mileagePlanForm.mileagePlanNumber && passengerForm.mileagePlanForm.mileageProgram) {
          // If a mileage plan full dataset is present, it must have valid data
          return passengerForm.mileagePlanForm.mileagePlanNumberValid && passengerForm.mileagePlanForm.mileageProgramValid;
        } else {
          // Mileage plan form is partially filled form out
          // Mileage plan data must be fully present and valid
          return false;
        }
      }
    }
    // Mileage plan form is optional, so no inputs is a valid state
    return true;
  });

/**
 * Gets whether the ktn form for a passenger form is valid
 */
export const getKtnFormValid = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => {
    // Check for optional ktn data
    if (passengerForm.ktnRedressForm?.ktn) {
      // If any ktn data is present
      // Check ktn country code validity as well since it is a select and will always have a value
      if (
        passengerForm.ktnRedressForm.ktn.number ||
        (passengerForm.ktnRedressForm.ktn.countryCode && passengerForm.ktnRedressForm.ktn.countryCodeValid)
      ) {
        // If ktn data is present, it must have a number and country code
        if (passengerForm.ktnRedressForm.ktn.number && passengerForm.ktnRedressForm.ktn.countryCode) {
          // If a ktn full dataset is present, it must have valid data
          return passengerForm.ktnRedressForm.ktn.numberValid && passengerForm.ktnRedressForm.ktn.countryCodeValid;
        } else {
          // Ktn is partially filled form out
          // Ktn data must be fully present and valid
          return false;
        }
      }
    }
    // Ktn form is optional, so no inputs is a valid state
    return true;
  });

/**
 * Gets whether the ktn form for a passenger form is valid
 */
export const getRedressFormValid = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => {
    // Check for optional redress data
    if (passengerForm.ktnRedressForm?.redress) {
      // If any redress data is present
      // Check redress country code validity as well since it is a select and will always have a value
      if (
        passengerForm.ktnRedressForm.redress.number ||
        (passengerForm.ktnRedressForm.redress.countryCode && passengerForm.ktnRedressForm.redress.countryCodeValid)
      ) {
        // If redress data is present, it must have a number and country code
        if (passengerForm.ktnRedressForm.redress.number && passengerForm.ktnRedressForm.redress.countryCode) {
          // If a redress full dataset is present, it must have valid data
          return passengerForm.ktnRedressForm.redress.numberValid && passengerForm.ktnRedressForm.redress.countryCodeValid;
        } else {
          // Redress is partially form filled out
          // Redress data must be fully present and valid
          return false;
        }
      }
    }
    return true;
  });

/**
 * Gets whether the ktn/redress form for a passenger form is valid
 */
export const getKtnRedressFormValid = (passengerHashId: string) =>
  createSelector(
    getKtnFormValid(passengerHashId),
    getRedressFormValid(passengerHashId),
    (ktnFormValid: boolean, redressFormValid: boolean) => {
      return ktnFormValid && redressFormValid;
    }
  );

/**
 * Gets whether the emergency contact form for a passenger form is valid
 */
export const getEmergencyContactFormValid = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => {
    // Check for optional emergency contact data
    if (passengerForm.emergencyContactForm?.showForm) {
      // If emergency contact data has any data present
      // Ignore phone country code since it will always have data (it defaults to US)
      if (
        passengerForm.emergencyContactForm.firstName ||
        passengerForm.emergencyContactForm.lastName ||
        passengerForm.emergencyContactForm.phoneNumber?.phoneNumber
      ) {
        // If emergency contact data has any data present, it must have a complete set of  data
        if (
          passengerForm.emergencyContactForm.firstName &&
          passengerForm.emergencyContactForm.lastName &&
          passengerForm.emergencyContactForm.phoneNumber?.phoneCountryCode &&
          passengerForm.emergencyContactForm.phoneNumber?.phoneCountryCodeValid &&
          passengerForm.emergencyContactForm.phoneNumber?.phoneNumber
        ) {
          // If emergency contact data has a full dataset, it must have valid data
          return (
            passengerForm.emergencyContactForm.firstNameValid &&
            passengerForm.emergencyContactForm.lastNameValid &&
            passengerForm.emergencyContactForm.phoneNumber?.phoneCountryCodeValid &&
            passengerForm.emergencyContactForm.phoneNumber?.phoneNumberValid
          );
        } else {
          // Emergency contact form is partially filled out
          // Emergency contact data must be fully present and valid
          return false;
        }
      }
    }
    // Emergency contact form is optional, so no inputs is a valid state
    return true;
  });

/**
 * Gets whether the emergency contact form for a passenger form is valid
 */
export const getInternationalDocumentsFormValid = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => {
    // Check for optional international documents data
    if (passengerForm.internationalDocumentsForm) {
      // If international documents data has any data present
      // Ignore document type since it will always have data (it defaults to passport)
      // Check country of citizenship and country of residence validity as well since they are selects and will always have a value
      if (
        (passengerForm.internationalDocumentsForm.countryOfCitizenship &&
          passengerForm.internationalDocumentsForm.countryOfCitizenshipValid) ||
        (passengerForm.internationalDocumentsForm.countryOfResidence && passengerForm.internationalDocumentsForm.countryOfResidenceValid) ||
        passengerForm.internationalDocumentsForm.documentNumber ||
        passengerForm.internationalDocumentsForm.expirationDate
      ) {
        // If international documents has any data present, it must have a complete set of  data
        // Ignore expiration date for now since it can be blank, it's validity will be checked below
        if (
          passengerForm.internationalDocumentsForm.countryOfCitizenship &&
          passengerForm.internationalDocumentsForm.countryOfResidence &&
          passengerForm.internationalDocumentsForm.documentNumber
        ) {
          // If international documents has a full dataset, it must have valid data
          // Expiration date validity is handled even for Consulate and Deportee document types (handled in the component)
          return (
            passengerForm.internationalDocumentsForm.countryOfCitizenshipValid &&
            passengerForm.internationalDocumentsForm.countryOfResidenceValid &&
            passengerForm.internationalDocumentsForm.documentTypeValid &&
            passengerForm.internationalDocumentsForm.documentNumberValid &&
            passengerForm.internationalDocumentsForm.expirationDateValid
          );
        } else {
          // International documents form is partially filled out
          // International documents data must be fully present and valid
          return false;
        }
      }
    }
    // International documents form is optional, so no inputs is a valid state
    return true;
  });

export const getContactTracingEmailValid = (passengerHashId: string) =>
  createSelector(getPassengerContactTracingForm(passengerHashId), (conatactTracingForm: ContactTracingForm) => {
    // if email is present, then check email validity,
    // otherwise return true, as email is optional
    if (conatactTracingForm?.email) {
      return conatactTracingForm.emailValid;
    }
    return true;
  });

export const getContactTracingPhoneValid = (passengerHashId: string) =>
  createSelector(getPassengerContactTracingForm(passengerHashId), (conatactTracingForm: ContactTracingForm) => {
    // if phone number is present, then check both phone number and phone country code validity,
    // otherwise return true, as phone number is optional
    if (conatactTracingForm?.phoneNumber?.phoneNumber) {
      return conatactTracingForm.phoneNumber.phoneNumberValid && conatactTracingForm.phoneNumber.phoneCountryCodeValid;
    }
    return true;
  });

export const getContactTracingAddressValid = (passengerHashId: string) =>
  createSelector(getPassengerContactTracingForm(passengerHashId), (conatactTracingForm: ContactTracingForm) => {
    // if phone number is present, then check both phone number and phone country code validity,
    // otherwise return true, as phone number is optional
    if (conatactTracingForm?.address) {
      if (!!conatactTracingForm.address.inTransit) {
        return true;
      }
      if (
        conatactTracingForm.address.addressLine1 ||
        conatactTracingForm.address.city ||
        conatactTracingForm.address.state ||
        conatactTracingForm.address.zipCode
      ) {
        return (
          conatactTracingForm.address.addressLine1Valid &&
          conatactTracingForm.address.cityValid &&
          conatactTracingForm.address.stateValid &&
          conatactTracingForm.address.zipCodeValid
        );
      }
    }
    return true;
  });

export const getContactTracingFormValid = (passengerHashId: string) =>
  createSelector(
    getContactTracingEmailValid(passengerHashId),
    getContactTracingPhoneValid(passengerHashId),
    getContactTracingAddressValid(passengerHashId),
    (emailValid: boolean, phoneValid: boolean, addressValid: boolean) => {
      return emailValid && phoneValid && addressValid;
    }
  );

export const getPassengerBioForm = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => passengerForm?.bioForm ?? null);

export const getPassengerKtnRedressForm = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => passengerForm?.ktnRedressForm ?? null);

export const getPassengerMileagePlanForm = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => passengerForm?.mileagePlanForm ?? null);

export const getPassengerContactTracingForm = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => passengerForm?.contactTracingForm ?? null);

export const getPassengerFormAutoFillTriggered = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => passengerForm?.autoFill ?? false);

export const getPassengerMPFormAutoFillStatus = (passengerHashId: string) =>
  createSelector(
    getMileagePlanOutageMessageActive,
    getPassengerMileagePlanForm(passengerHashId),
    (mpOutageActive: boolean, mileagePlanForm: MileagePlanForm): MileagePlanAutoFillStatus => {
      if (mpOutageActive) {
        // Mileage Plan Service Outage
        return MileagePlanAutoFillStatus.MP_SERVICE_OUTAGE;
      }
      if (!mileagePlanForm?.mileageProgram || !mileagePlanForm?.mileageProgramValid) {
        // No Mileage Plan Program
        return MileagePlanAutoFillStatus.NO_MP;
      }
      if (mileagePlanForm?.mileageProgram !== 'AS') {
        // Non AS Mileage Plan Program selected
        return MileagePlanAutoFillStatus.NON_AS_MP;
      }
      if (mileagePlanForm?.mileageProgram === 'AS') {
        // "Alaska Airlines" selected as Mileage Program
        if (!mileagePlanForm?.mileagePlanNumber || !mileagePlanForm?.mileagePlanNumberValid) {
          // MP number empty OR invalid
          return MileagePlanAutoFillStatus.NO_MP_NUMBER;
        }
      }
      return MileagePlanAutoFillStatus.ENABLED;
    }
  );

/**
 * Selector to check if there is a mismatch between the passenger's name and the available coupons.
 *
 * This selector takes a passenger's hash ID and returns a boolean indicating whether there is a name mismatch.
 * It uses two other selectors: `getPassengerBioForm` and `getAllOKCoupons`.
 *
 * @param passengerHashId - The hash ID of the passenger.
 * @returns - A memoized selector that returns `true` if there is a name mismatch, otherwise `false`.
 *
 * The selector performs the following steps:
 * 1. Retrieves the passenger form data using the `getPassengerBioForm` selector.
 * 2. Retrieves all available coupons using the `getAllOKCoupons` selector.
 * 3. Checks if the passenger form and coupons are available.
 * 4. Finds a coupon that matches the passenger's first and last names (case-insensitive).
 * 5. Returns `true` if no matching coupon is found, indicating a name mismatch.
 * 6. Returns `false` if a matching coupon is found or if the passenger form or coupons are not available.
 */
export const getPassengerNameMismatch = (passengerHashId: string) =>
  createSelector(
    getPassengerBioForm(passengerHashId),
    getAllOKCoupons,
    (passengerForm: PassengerForm, coupons: CouponStatusData[]): boolean => {
      if (passengerForm && coupons && coupons.length > 0) {
        const coupon = coupons.find((c) => {
          return (
            c.firstName?.toLocaleUpperCase() === passengerForm.firstName?.toLocaleUpperCase() &&
            c.lastName?.toLocaleUpperCase() === passengerForm.lastName?.toLocaleUpperCase()
          );
        });
        return !coupon;
      }
      return false;
    }
  );

export const areAllFormsValid = (passengerHashId: string) =>
  createSelector(
    getBioFormValid(passengerHashId),
    getBioMiddleNameFormValid(passengerHashId),
    getMileagePlanFormValid(passengerHashId),
    getKtnRedressFormValid(passengerHashId),
    getEmergencyContactFormValid(passengerHashId),
    getInternationalDocumentsFormValid(passengerHashId),
    getContactTracingFormValid(passengerHashId),
    getPassengerCbbgExstFirstName(passengerHashId),
    (
      bioFormValid: boolean,
      bioMiddleNameFormValid: boolean,
      mileagePlanFormValid: boolean,
      ktnRedressFormValid: boolean,
      emergencyContactFormValid: boolean,
      internationalDocumentsFormValid: boolean,
      contactTracingFormValid: boolean,
      cbbgExstFirstName: boolean
    ) => {
      return (
        bioFormValid &&
        bioMiddleNameFormValid &&
        mileagePlanFormValid &&
        ktnRedressFormValid &&
        emergencyContactFormValid &&
        internationalDocumentsFormValid &&
        contactTracingFormValid &&
        !cbbgExstFirstName
      );
    }
  );

/**
 * Returns true if the guest save button for a passenger form should be enabled
 */
export const enablePassengerFormSaveButton = (passengerHashId: string) =>
  createSelector(
    getPassengerForm(passengerHashId),
    areAllFormsValid(passengerHashId),
    (passengerForm: PassengerForm | undefined, allFormsValid: boolean) => {
      // Evaluate passengerForm as a boolean to check if it is not null or undefined by using the !! operator
      // The first ! negates the value, converting it to a boolean.
      // The second ! negates the value again, converting it back to the original truthiness, but now as a boolean.
      // So !!null and !!undefined would be false.
      return !!passengerForm && allFormsValid;
    }
  );

/**
 * Gets whether the bio form for an infant form is valid
 */
export const getLapInfantBioFormValid = (passengerHashId: string) =>
  createSelector(getLapInfantForm(passengerHashId), (lapInfantForm: LapInfantForm) => {
    if (
      lapInfantForm &&
      lapInfantForm.bioForm?.firstName &&
      lapInfantForm.bioForm?.firstNameValid &&
      lapInfantForm.bioForm?.lastName &&
      lapInfantForm.bioForm?.lastNameValid &&
      lapInfantForm.bioForm?.dateOfBirth &&
      lapInfantForm.bioForm?.dateOfBirthValid &&
      lapInfantForm.bioForm?.gender &&
      lapInfantForm.bioForm?.genderValid
    ) {
      return true;
    }
    return false;
  });

/**
 * Gets whether the middle name form for an infant form is valid
 */
export const getLapInfantBioMiddleNameFormValid = (passengerHashId: string) =>
  createSelector(getLapInfantForm(passengerHashId), (lapInfantForm: LapInfantForm) => {
    // Check for optional optional bio middle name
    if (lapInfantForm?.bioForm?.middleName) {
      // If middle name is present, it must be valid
      return lapInfantForm.bioForm.middleNameValid;
    }
    // Middle name is optional, so no input is a valid state
    return true;
  });

/**
 * Gets whether the associated passenger field for an infant form is valid
 */
export const getLapInfantAssociatedPassengerValid = (passengerHashId: string) =>
  createSelector(getLapInfantForm(passengerHashId), (lapInfantForm: LapInfantForm) => {
    if (lapInfantForm?.associatedPassengerHashId && lapInfantForm?.associatedPassengerHashIdValid) {
      return true;
    }
    return false;
  });

/**
 * Returns true if the guest save button for an infant form should be enabled
 */
export const enableLapInfantFormSaveButton = (passengerHashId: string) =>
  createSelector(
    getLapInfantForm(passengerHashId),
    getLapInfantBioFormValid(passengerHashId),
    getLapInfantBioMiddleNameFormValid(passengerHashId),
    getLapInfantAssociatedPassengerValid(passengerHashId),
    (passengerForm: PassengerForm, bioFormValid: boolean, bioMiddleNameFormValid: boolean, associatedPassengerValid: boolean) => {
      let enableGuestSaveButton = false;
      if (passengerForm) {
        // Check for require bio data and associated passenger
        // It is required at a minimum for every form
        if (bioFormValid && associatedPassengerValid) {
          enableGuestSaveButton = bioMiddleNameFormValid;
        }
      }
      return enableGuestSaveButton;
    }
  );

/**
 * Returns the passenger forms with bio data for passengers over age 13
 */
export const getSavedPassengersFormsOverAge13 = createSelector(getSavedPassengerForms, (passengerForms: PassengerForm[]) => {
  const passengerFormsOverAge13: PassengerForm[] = [];
  passengerForms?.forEach((passengerForm) => {
    if (passengerForm?.bioForm?.dateOfBirth) {
      const dobString = passengerForm?.bioForm?.dateOfBirth;
      const dob = new Date(dobString);
      const thirteenYearsAgo = new Date();
      thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);
      if (dob < thirteenYearsAgo) {
        passengerFormsOverAge13.push(passengerForm);
      }
    }
  });
  return passengerFormsOverAge13;
});

// get the expanded state of the reservation information form
export const getReservationInfoExpanded = createSelector(
  getContactInfoForm,
  (contactInfoForm: ContactInfoForm) => contactInfoForm?.formExpanded ?? false
);

// get the expanded state of the passenger form
export const getPassengerFormExpanded = (passengerHashId: string) =>
  createSelector(getPassengerForm(passengerHashId), (passengerForm: PassengerForm) => passengerForm?.formExpanded ?? false);

// get the expanded state of the infant form
export const getInfantFormExpanded = (passengerHashId: string) =>
  createSelector(getLapInfantForm(passengerHashId), (lapInfantForm: LapInfantForm) => lapInfantForm?.formExpanded ?? false);

/**
 * Returns how many forms are collapsed from the passenger, lap infant, and guest contact forms
 * Used to determine Collapse/Expand All button states
 */
export const getCollapsedFormCount = createSelector(
  getPassengerForms,
  getLapInfantForms,
  getContactInfoForm,
  (passengerForms: PassengerForm[], lapInfantForms: LapInfantForm[], contactInfoForm: ContactInfoForm) => {
    const collapsedPassengerForms = passengerForms?.filter((passengerForm) => !passengerForm.formExpanded)?.length ?? 0;
    const collapsedLapInfantForms = lapInfantForms?.filter((lapInfantForm) => !lapInfantForm.formExpanded)?.length ?? 0;

    let collapsedContactInfoForm = 0;
    if (contactInfoForm?.formExpanded === false) {
      collapsedContactInfoForm = 1;
    }
    return collapsedPassengerForms + collapsedLapInfantForms + collapsedContactInfoForm;
  }
);

function isDataChanged(passengerForm: PassengerForm | LapInfantForm, forInfant = false): boolean {
  // If lastSavedData is empty, then the data is being added for the first time
  if (!passengerForm?.lastSavedData || Object.keys(passengerForm?.lastSavedData).length === 0) {
    return true;
  }

  if (forInfant) {
    return isBioDataChanged(passengerForm, forInfant) || isAssociatedPassengerChanged(passengerForm);
  }

  return (
    isBioDataChanged(passengerForm) ||
    isMileagePlanDataChanged(passengerForm) ||
    isKtnOrRedressDataChanged(passengerForm) ||
    isEmergencyContactDataChanged(passengerForm) ||
    isInternationalDocumentDataChanged(passengerForm) ||
    isContactTracingFormDataChanged(passengerForm)
  );
}

function isAssociatedPassengerChanged(lapInfantForm: LapInfantForm): boolean {
  return lapInfantForm?.lastSavedData?.associatedPassengerHashId !== lapInfantForm?.associatedPassengerHashId;
}

export function isBioDataChanged(passengerForm: PassengerForm | LapInfantForm, forInfant = false): boolean {
  const changed = bioDataChangeCheck(passengerForm, forInfant);
  if (changed) {
    return true;
  }

  const propertiesToCheck = ['dateOfBirth', 'firstName', 'lastName', 'middleName', 'gender'];
  for (const property of propertiesToCheck) {
    // Property being filled out for the first time
    if (!passengerForm?.lastSavedData?.bio?.[property] && !!passengerForm?.bioForm?.[property]) {
      return true;
    }
    // Property value has changed
    if (
      passengerForm?.lastSavedData?.bio?.[property] &&
      passengerForm?.bioForm?.[property] !== passengerForm?.lastSavedData?.bio?.[property]
    ) {
      return true;
    }
  }
  return false;
}

function bioDataChangeCheck(passengerForm: PassengerForm, forInfant: boolean): boolean {
  // Check for scenario where bio data is entered for the first time and not been saved
  if (!forInfant) {
    // We can ignore first and last name since they will always be present for guests
    if (
      !passengerForm?.lastSavedData?.bio?.dateOfBirth &&
      !passengerForm?.lastSavedData?.bio?.gender &&
      (!!passengerForm?.bioForm?.gender || !!passengerForm?.bioForm?.dateOfBirth || !!passengerForm?.bioForm?.middleName)
    ) {
      return true;
    }
  } else if (forInfant) {
    // Check everything for infant since all fields are required
    if (
      !passengerForm?.lastSavedData?.bio &&
      (!!passengerForm?.bioForm?.gender ||
        !!passengerForm?.bioForm?.dateOfBirth ||
        !!passengerForm?.bioForm?.middleName ||
        !!passengerForm?.bioForm?.firstName ||
        !!passengerForm?.bioForm?.lastName)
    ) {
      return true;
    }
  }
  return false;
}

function isMileagePlanDataChanged(passengerForm: PassengerForm): boolean {
  // Check for scenario where mp data is entered for the first time and not been saved
  // We can just check for the program since it is required before number
  if (!passengerForm?.lastSavedData?.mileagePlan && !!passengerForm?.mileagePlanForm?.mileageProgram) {
    return true;
  }
  const propertiesToCheck = ['mileageProgram', 'mileagePlanNumber'];
  for (const property of propertiesToCheck) {
    if (
      passengerForm?.lastSavedData?.mileagePlan?.[property] &&
      passengerForm?.mileagePlanForm?.[property] !== passengerForm?.lastSavedData?.mileagePlan?.[property]
    ) {
      return true;
    }
  }
  return false;
}

function isKtnOrRedressDataChanged(passengerForm: PassengerForm): boolean {
  // Check for scenario where ktn/redress data is entered for the first time and not been saved
  // We can just check for the country code since it is required before number
  if (
    !passengerForm?.lastSavedData?.ktnRedress &&
    (!!passengerForm?.ktnRedressForm?.ktn?.countryCode || !!passengerForm?.ktnRedressForm?.redress?.countryCode)
  ) {
    return true;
  }
  return ktnRedressDataChangeCheck(passengerForm);
}

function ktnRedressDataChangeCheck(passengerForm: PassengerForm): boolean {
  const propertiesToCheck = ['number', 'countryCode'];
  for (const property of propertiesToCheck) {
    // Property getting filled out for the first time
    if (!passengerForm?.lastSavedData?.ktnRedress?.ktn?.[property] && !!passengerForm?.ktnRedressForm?.ktn?.[property]) {
      return true;
    }
    // Property value has changed
    if (
      passengerForm?.lastSavedData?.ktnRedress?.ktn?.[property] &&
      passengerForm?.ktnRedressForm?.ktn?.[property] !== passengerForm?.lastSavedData?.ktnRedress?.ktn?.[property]
    ) {
      return true;
    }
    // Property getting filled out for the first time
    if (!passengerForm?.lastSavedData?.ktnRedress?.redress?.[property] && !!passengerForm?.ktnRedressForm?.redress?.[property]) {
      return true;
    }
    // Property value has changed
    if (
      passengerForm?.lastSavedData?.ktnRedress?.redress?.[property] &&
      passengerForm?.ktnRedressForm?.redress?.[property] !== passengerForm?.lastSavedData?.ktnRedress?.redress?.[property]
    ) {
      return true;
    }
  }
  return false;
}

function isEmergencyContactDataChanged(passengerForm: PassengerForm): boolean {
  // Check for scenario where emergency contact data is entered for the first time and not been saved
  // We can just check for phoneNumber here since it will have data when the form is displayed
  if (!passengerForm?.lastSavedData?.emergencyContact && passengerForm?.emergencyContactForm?.phoneNumber) {
    return true;
  }
  // Check name separately
  const propertiesToCheckName = ['firstName', 'lastName'];
  for (const property of propertiesToCheckName) {
    if (
      passengerForm?.lastSavedData?.emergencyContact?.[property] &&
      passengerForm?.emergencyContactForm?.[property] !== passengerForm?.lastSavedData?.emergencyContact?.[property]
    ) {
      return true;
    }
  }
  // Check phone number separately
  const propertiesToCheckPhoneNumber = ['phoneNumber', 'phoneCountryCode'];
  for (const property of propertiesToCheckPhoneNumber) {
    if (
      passengerForm?.lastSavedData?.emergencyContact?.phoneNumber?.[property] &&
      passengerForm?.emergencyContactForm?.phoneNumber?.[property] !==
        passengerForm?.lastSavedData?.emergencyContact?.phoneNumber?.[property]
    ) {
      return true;
    }
  }
  return false;
}

function isInternationalDocumentDataChanged(passengerForm: PassengerForm): boolean {
  // Check for scenario where international document data is entered for the first time and not been saved
  // We can just check for document type since it will have data when the form is displayed
  if (
    !passengerForm?.lastSavedData?.internationalDocument &&
    passengerForm?.internationalDocumentsForm?.documentType !== null &&
    passengerForm?.internationalDocumentsForm?.documentType !== undefined &&
    passengerForm?.internationalDocumentsForm?.documentType >= 0
  ) {
    return true;
  }

  const propertiesToCheck = ['countryOfCitizenship', 'countryOfResidence', 'documentType', 'documentNumber', 'expirationDate'];
  for (const property of propertiesToCheck) {
    if (
      passengerForm?.lastSavedData?.internationalDocument?.[property] &&
      passengerForm?.internationalDocumentsForm?.[property] !== passengerForm?.lastSavedData?.internationalDocument?.[property]
    ) {
      return true;
    }
  }
  return false;
}

function isContactTracingFormDataChanged(passengerForm: PassengerForm): boolean {
  // Check for scenario where contact tracing data is entered for the first time and not been saved
  // We can just check if the form is displayed since the the agent must remove the form if they don't want to save it
  if (!passengerForm?.lastSavedData?.contactTracing && passengerForm?.contactTracingForm?.showForm) {
    return true;
  }

  // Check email
  if (
    passengerForm?.lastSavedData?.contactTracing?.email &&
    passengerForm?.contactTracingForm?.email !== passengerForm?.lastSavedData?.contactTracing?.email
  ) {
    return true;
  }

  // Check phone number
  if (checkContactTracingPhoneDataChanges(passengerForm)) {
    return true;
  }

  // Check address items
  const addressPropertiesToCheck = ['addressLine1', 'city', 'state', 'zipCode', 'inTransit'];
  for (const property of addressPropertiesToCheck) {
    if (
      passengerForm?.lastSavedData?.contactTracing?.address?.[property] &&
      passengerForm?.contactTracingForm?.address?.[property] !== passengerForm?.lastSavedData?.contactTracing?.address?.[property]
    ) {
      return true;
    }
  }
  return false;
}

function checkContactTracingPhoneDataChanges(passengerForm: PassengerForm): boolean {
  // Format phone number before checking
  // We do this because we cannot pull country code out of pre-loaded data
  const phoneCountryCode = !!passengerForm?.contactTracingForm?.phoneNumber?.phoneCountryCode
    ? CountryList.find((country) => country.code === passengerForm?.contactTracingForm?.phoneNumber?.phoneCountryCode)?.phoneExtension
    : undefined;
  const phoneToCheck =
    !!phoneCountryCode && !!passengerForm?.contactTracingForm?.phoneNumber?.phoneNumber
      ? `${phoneCountryCode}${passengerForm?.contactTracingForm?.phoneNumber?.phoneNumber}`
      : passengerForm?.contactTracingForm?.phoneNumber?.phoneNumber;

  // Check phone number
  if (phoneToCheck && !!phoneToCheck && phoneToCheck !== passengerForm?.lastSavedData?.contactTracing?.phoneNumber?.phoneNumber) {
    return true;
  }

  return false;
}
