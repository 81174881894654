import { createReducer, Action, on } from '@ngrx/store';
import { addRainBookingRemarksComplete, resetAddRainBookingRemarksStatus, setInitialBookingCurrentFlightSearchId, setRainBookingRemarksAdded, updateFareRulesCheckboxState } from './initial-booking.actions';
import { initialInitialBookingEntityState } from './initial-booking.state';
import { initializeReservationServiceState } from 'src/app/services/reservation-service/state/reservation-service.actions';
import { initialReservationServiceState } from 'src/app/services/reservation-service/state/reservation-service.state';
import { AddDeleteRemarkStatus } from 'src/app/dtos/response/remarks-response/add-delete-remark-status';

const featureReducer = createReducer(
  initialInitialBookingEntityState,
  on(initializeReservationServiceState, (state) => ({ ...state, ...initialReservationServiceState })),
  on(addRainBookingRemarksComplete, (state, { status }) => {
    return {
      ...state,
      addRainBookingRemarksStatus: status,
    };
  }),

  on(resetAddRainBookingRemarksStatus, (state) => {
    return {
      ...state,
      addRainBookingRemarksStatus: AddDeleteRemarkStatus.NONE,
    };
  }),

  on(setRainBookingRemarksAdded, (state) => {
    return {
      ...state,
      rainBookingRemarksAdded: true,
    };
  }),

  on(updateFareRulesCheckboxState, (state, { checked }) => {
    return {
      ...state,
      fareRulesAgreed: checked,
    };
  }),
  on(setInitialBookingCurrentFlightSearchId, (state, { flightSearchId }) => {
    return {
      ...state,
      currentFlightSearchId: flightSearchId,
    };
  })
);

export function initialBookingReducer(state = initialInitialBookingEntityState, action: Action) {
  return featureReducer(state, action);
}
