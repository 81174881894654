import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { FlightShoppingService } from '../flight-shopping.service';
import {
  loadFlightShoppingFrapiResultsComplete,
  loadFlightShoppingFrapiResultsInitialBooking,
  loadFlightShoppingResults,
  loadFlightShoppingResultsComplete,
} from './flight-shopping-service.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { FlightShoppingFrapiRequest } from '../../../dtos/request/flight-shopping-frapi-request/flight-shopping-frapi-request';

@Injectable()
export class FlightShoppingServiceEffects {
  constructor(private actions$: Actions, private service: FlightShoppingService) {}

  loadFlightShoppingResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFlightShoppingResults),
      mergeMap((action) =>
        this.service.getFlightShoppingResults(action.request).pipe(
          map((result) => {
            return result.response
              ? loadFlightShoppingResultsComplete(result.success, result.response)
              : loadFlightShoppingResultsComplete(result.success, null);
          })
        )
      ),
      catchError(() => of(loadFlightShoppingResultsComplete(false, null)))
    )
  );

  loadFlightShoppingFrapiResultsInitialBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFlightShoppingFrapiResultsInitialBooking),
      mergeMap((action) => {
        return this.service.getFlightShoppingFrapi(action.featureId, action.request).pipe(
          map((result) => {
            return loadFlightShoppingFrapiResultsComplete(result);
          }),
          catchError(() => of(loadFlightShoppingFrapiResultsComplete({ id: action.featureId, success: false })))
        );
      })
    )
  );
}
