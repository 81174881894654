import { Injectable } from '@angular/core';
import { MessageKey } from '../../../models/message/message-key';
import { ReservationResponse } from '../../../dtos/response/reservation-response/reservation-response';
import { FareType } from 'src/app/dtos/response/vcr-response/fare-type';
import { addStackedMessage, addStackedMessageNoDuplicate } from 'src/app/services/message-service/state/message.actions';
import { TicketDesignator } from 'src/app/models/tickets/ticket-designator';
import { PaymentLinkResponse } from 'src/app/dtos/response/payment-link-response/payment-link-response';
import { GenericServiceRequest } from 'src/app/dtos/response/reservation-response/generic-service-request';
import { Reservation } from 'src/app/dtos/response/reservation-response/reservation';
import { SpecialServiceRequest } from 'src/app/dtos/response/reservation-response/special-service-request';
import { BookingType } from 'src/app/dtos/response/reservation-response/booking-type';
import { Segment } from 'src/app/dtos/response/reservation-response/segment';
import { TicketingInfo } from 'src/app/dtos/response/reservation-response/ticketing-info';
import { AirlinesByTicketPrefix, OneWorldAirlinesByTicketPrefix } from 'src/app/models/airlines/airlines';
import { ActionCode } from 'src/app/dtos/response/action-code/action-codes';
import { AirlineCode } from 'src/app/models/airlines/airline-code';
import { confirmedActionCodes } from 'src/app/dtos/response/action-code/confirmed-action-codes';
import { SegmentUseCase } from 'src/app/use-cases/segment.use-case';
import { RootState } from 'src/app/state/state';
import { Store } from '@ngrx/store';
import { bulkOpaqueDesignators } from '../../../models/tickets/bulk-opaque-ticket-designators';
import { Ticket } from '../../../dtos/response/reservation-response/ticket';
import { ExtraSeatType } from '../../../dtos/response/reservation-response/extra-seat-type';

@Injectable({
  providedIn: 'root',
})
export class ReservationMessageConverter {
  constructor(private store: Store<RootState>, private segmentUseCase: SegmentUseCase) {}

  public convertInitialBookingReservationLookupMessages(reservationResponse: ReservationResponse): ReservationResponse {
    // Check that a reservation model exists
    if (reservationResponse?.reservation) {
      reservationResponse.reservation.mappedMessageKeys = [];
      // Check if the reservation has a price quote
      // Sometimes the price quote will exist but it will have an error message
      if (
        reservationResponse?.reservation.priceQuote === null ||
        reservationResponse?.reservation.priceQuote === undefined ||
        reservationResponse?.reservation.priceQuote?.errorMessage
      ) {
        reservationResponse.reservation.mappedMessageKeys.push(MessageKey.INITIAL_BOOKING_NO_PRICE_QUOTE);
      }

      if (
        !reservationResponse?.reservation?.isStandaloneExtraSeatReservation &&
        reservationResponse?.reservation?.passengers?.some((p) => p.firstName === ExtraSeatType.EXST || p.firstName === ExtraSeatType.CBBG)
      ) {
        reservationResponse.reservation.mappedMessageKeys.push(MessageKey.CBBG_EXST_LINK_FAILURE);
      }
    }
    return reservationResponse;
  }

  public dispatchReservationAdvisories(
    reservation: Reservation,
    filteredSegments: Segment[],
    osis: SpecialServiceRequest[] | GenericServiceRequest[]
  ): void {
    if (reservation) {
      this.dispatchReservationSegmentAdvisories(reservation, filteredSegments);
      if (reservation.ticketNumbers?.length > 0) {
        this.dispatchTicketAdvisories(reservation.ticketNumbers);
      }

      if (reservation.bookingTypes?.includes(BookingType.Commercial)) {
        this.store.dispatch(addStackedMessage(MessageKey.COMMERCIAL_ACCOUNT));
      }

      if (reservation.bookingTypes?.includes(BookingType.Deadhead)) {
        this.store.dispatch(addStackedMessage(MessageKey.DEADHEAD));
      } else if (reservation.groupReservationDetails?.isGroupReservation) {
        this.store.dispatch(addStackedMessage(MessageKey.GROUP_RESERVATION));
      }

      this.dispatchTicketingInfoAdvisories(reservation.ticketingInfo);

      const remarks = reservation.remarks?.flatMap((remark) => remark.remarkLines);
      if (osis?.length > 0 || remarks?.length > 0) {
        this.dispatchRemarkAdvisories(osis, remarks);
      }
    }
  }

  public dispatchFareTypeAdvisories(fareTypes: FareType[]): void {
    if (fareTypes.includes(FareType.BEREAVEMENT)) {
      this.store.dispatch(addStackedMessage(MessageKey.BEREAVEMENT_FARE));
    }
    if (fareTypes.includes(FareType.COMPANION)) {
      this.store.dispatch(addStackedMessage(MessageKey.COMPANION_FARE));
    }
  }

  public dispatchTicketDesignatorAdvisories(ticketDesignators: string[]): void {
    const hasBulkOpaqueDesignator = ticketDesignators.some((designator) => bulkOpaqueDesignators.includes(designator));
    if (hasBulkOpaqueDesignator) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.BULK_OPAQUE));
    }

    const hasFlightPass = ticketDesignators.some((designator) => designator === TicketDesignator.JTPK);
    if (hasFlightPass) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.FLIGHT_PASS));
    }

    const hasPartnerAward = ticketDesignators.some((designator) => designator === TicketDesignator.PAWD);
    if (hasPartnerAward) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.PARTNER_AWARD));
    }

    const hasAlaskaAward = ticketDesignators.some((designator) => designator === TicketDesignator.ASWD);
    if (hasAlaskaAward) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.ALASKA_AWARD));
    }
  }

  public dispatchFareCalcAdvisories(fareCalcs: string[]): void {
    const hasBtFareCalc = fareCalcs.some((calc) => calc.includes(' BT '));
    if (hasBtFareCalc) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.BULK_OPAQUE));
    }
  }

  public dispatchActivePaymentLinkAdvisory(activePaymentLink: PaymentLinkResponse) {
    if (activePaymentLink.paymentUrl && activePaymentLink.expirationTime > 0) {
      this.store.dispatch(addStackedMessage(MessageKey.ACTIVE_CHAT_PAYMENT_LINK));
    }
  }

  private dispatchReservationSegmentAdvisories(reservation: Reservation, filteredSegments: Segment[]) {
    if (filteredSegments) {
      if (filteredSegments?.filter((segment) => this.segmentUseCase.isSaverFare(segment)).length > 0) {
        this.store.dispatch(addStackedMessage(MessageKey.SAVER_FARE_CLASS));
      }

      if (filteredSegments?.length <= 0) {
        this.store.dispatch(addStackedMessage(MessageKey.NO_ITINERARY));
      }

      if (
        filteredSegments?.filter(
          (segment) => segment.actionCode === ActionCode.MM || segment.actionCode === ActionCode.SA || segment.actionCode === ActionCode.SB
        ).length > 0
      ) {
        this.store.dispatch(addStackedMessage(MessageKey.STANDBY_RESERVATION));
      }

      // Schedule change advisory
      // Schedule change will be indicated on a segment by action code SC, WK, TK, UN, or UC
      // FIX this, some of these are getting filtered out of the segment list so they don't get checked here
      if (
        filteredSegments?.filter(
          (segment) =>
            segment.actionCode === ActionCode.SC ||
            segment.actionCode === ActionCode.WK ||
            segment.actionCode === ActionCode.TK ||
            segment.actionCode === ActionCode.UN ||
            segment.actionCode === ActionCode.UC
        ).length > 0 ||
        reservation.allSegments
          ?.filter((seg) => !confirmedActionCodes.includes(seg.actionCode))
          .filter(
            (segment) =>
              segment.actionCode === ActionCode.SC ||
              segment.actionCode === ActionCode.WK ||
              segment.actionCode === ActionCode.TK ||
              segment.actionCode === ActionCode.UN ||
              segment.actionCode === ActionCode.UC
          ).length > 0
      ) {
        this.store.dispatch(addStackedMessage(MessageKey.INVOLUNTARY_CHANGE));
      }

      this.evaluateCodeshareAdvisories(filteredSegments);
    }
  }

  private dispatchTicketingInfoAdvisories(ticketingInfo: TicketingInfo) {
    const hasTAC = ticketingInfo?.futureTicketing?.some((futureTicketing) => futureTicketing.code === 'TAC');
    if (hasTAC) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.TAC));
    }

    const ticketTimeLimit = ticketingInfo?.ticketingTimeLimit?.ticketTimeLimit;
    if (ticketTimeLimit && new Date(ticketTimeLimit) > new Date()) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.TICKET_TIME_LIMIT));
    }
  }

  private evaluateCodeshareAdvisories(filteredSegments: Segment[]) {
    const ranges = [
      { start: 4000, end: 4799 },
      { start: 5000, end: 5249 },
      { start: 5500, end: 5525 },
      { start: 5526, end: 5549 },
      { start: 5550, end: 5649 },
      { start: 5700, end: 5799 },
      { start: 5800, end: 5899 },
      { start: 5900, end: 5999 },
      { start: 6000, end: 6999 },
      { start: 7310, end: 7609 },
      { start: 7610, end: 7709 },
      { start: 7710, end: 7999 },
      { start: 8001, end: 8100 },
      { start: 8801, end: 8999 },
      { start: 9000, end: 9000 },
    ];
    const asMarketedFlights = filteredSegments.filter((segment) => segment.marketedByAirlineCode === AirlineCode.AS);
    const flightNumbers = asMarketedFlights.map((segment) => segment.marketingAirlineFlightNumber);

    const numbersInRange = flightNumbers.filter((number) => {
      const num = parseInt(number, 10);
      return ranges.some((range) => num >= range.start && num <= range.end);
    });

    if (numbersInRange.length > 0) {
      this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.CODESHARE));
    }
  }

  private dispatchRemarkAdvisories(osis: SpecialServiceRequest[] | GenericServiceRequest[], remarks: string[]) {
    if (osis?.length > 0) {
      // If any OSI contains the below strings, dispatch state of alaska advisory
      const stateOfAlaskaOsis = [
        'STATE OF ALASKA TRAVELER OK TO CHG',
        'NO CHANGES PERMITTED REFER BACK TO USTRAVEL',
        'AS TRAVELER NOT ALLOWED TO MAKE CHANGES. REFER BACK TO AGENCY.',
      ];
      const hasStateOfAlaskaOsi = osis.some((osi) => stateOfAlaskaOsis.some((stoaOsi) => osi.freeText.toUpperCase().includes(stoaOsi)));
      if (hasStateOfAlaskaOsi) {
        this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.STATE_OF_ALASKA_FARE));
      }

      // If any OSI contains the below strings, dispatch government fare advisory
      const governmentOsis = [
        'AS US CONGRESSWOMAN',
        'AS US CONGRESSMAN',
        'AS US CONGRESSPERSON',
        'AS US CONGRESSPEOPLE',
        'AS US REPRESENTATIVE',
        'AS US LEGISLATOR',
        'AS US SENATOR',
        'AS STAFF MEMBER OF SENATOR',
        'AS STAFF MEMBER OF REPRESENTATIVE',
        'AS STAFF OF SENATOR',
        'AS STAFF OF REPRESENTATIVE',
        'AS GOVPNR',
      ];
      const hasGovernmentOsi = osis.some((osi) => governmentOsis.some((govOsi) => osi.freeText.toUpperCase().includes(govOsi)));
      if (hasGovernmentOsi) {
        this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.GOVERNMENT_FARE));
      }
    }

    if (remarks?.length > 0) {
      // If any remark contains the below strings, dispatch government fare advisory
      const govermentRemarks = ['GOV FARE', 'GOV PNR', 'GOV RESERVATION'];
      const hasGovernmentRemark = remarks.some((remark) => govermentRemarks.some((govRemark) => remark.toUpperCase().includes(govRemark)));
      if (hasGovernmentRemark) {
        this.store.dispatch(addStackedMessageNoDuplicate(MessageKey.GOVERNMENT_FARE));
      }
    }
  }

  private dispatchTicketAdvisories(ticketNumbers: Ticket[]) {
    // Ignore VOID tickets
    const filtertedTickets = ticketNumbers.filter((ticket) => !ticket.isVoid)?.map((ticket) => ticket.ticketNumber);
    if (filtertedTickets.length > 0) {
      // it is possible that other tickets in the array have a different prefix but this is due to
      // human error and is rare, we decided against handling this edge case in the MVP for this feature
      // but we will likely need to handle it eventually
      const ticketPrefix = filtertedTickets[0].slice(0, 4);
      const ticketKey = filtertedTickets[0].slice(0, 3);
      if (ticketPrefix !== '0272' && ticketPrefix.startsWith('027')) {
        this.store.dispatch(addStackedMessage(MessageKey.OUTSIDE_CHANNELS));
      } else if (ticketPrefix !== '0272') {
        // OneWorld Airline
        if (OneWorldAirlinesByTicketPrefix.has(ticketKey)) {
          this.store.dispatch(
            addStackedMessage(MessageKey.ONEWORLD_AIRLINE_TICKET_SOURCE, [OneWorldAirlinesByTicketPrefix.get(ticketKey).airline])
          );
        }
        // Other airline (not OneWorld)
        else {
          this.store.dispatch(
            addStackedMessage(MessageKey.OTHER_AIRLINE_TICKET_SOURCE, [
              AirlinesByTicketPrefix.has(ticketKey) ? AirlinesByTicketPrefix.get(ticketKey).airline : ` an unknown carrier ${ticketKey}`,
            ])
          );
        }
      }
    }
  }
}
