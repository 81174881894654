import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Format } from 'src/app/models/message/format';
import { MessageStyle } from 'src/app/models/message/message-style';
import { RichMessage } from 'src/app/models/message/rich-message';
import { RootState } from 'src/app/state/state';
import { MessageKey } from '../../models/message/message-key';
import { MessageType } from '../../models/message/message-type';
import { getRoutedConfirmationCode } from '../reservation-service/state/reservation-service.selectors';
import { MessageDetail } from '../../models/message/MessageDetail';

@Injectable({
  providedIn: 'root',
})
export class MessageFactoryService {
  constructor(private store: Store<RootState>) {}

  /**
   * Builds a Message for the given key
   *
   * @param key The key for the message requested
   * @param messageArgs (optional) Format arguments to provide to the message
   */
  public getRichMessage(key: MessageKey, messageArgs?: any[], messageDetails?: MessageDetail): RichMessage | null {
    let richMessage: RichMessage | null = {} as RichMessage;
    let confirmationCode = '';

    // By default we assume messages are closable
    richMessage.closable = true;
    richMessage.style = MessageStyle.LONG;
    richMessage.key = key;
    richMessage.id = `${new Date().getTime()}${Math.random()}`;
    // Priority is set by the enum order
    richMessage.priority = key;

    /*
     * These are in no particular order
     * But try to keep them grouped by the type of message they are for ease of readin
     */
    switch (key) {
      case MessageKey.PASSWORD_DECRYPTION_ERROR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Unable to complete action.';
        richMessage.mainText =
          'RAIN did not receive necessary Sabre ID verification. Try to refresh Sabre ID verification by logging out and back in again.';
        break;
      // Dashboard ssr tab messages - start
      case MessageKey.SSR_DELETE_FAILED:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Unable to remove SSR.';
        richMessage.mainText = 'Refresh the reservation and try again.';
        break;
      case MessageKey.PET_SSR_DELETE_FAILED_ON_GENERAL_ERROR:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Pet cancellation remark not added to reservation and pet is holding space.';
        richMessage.mainText = 'Call Reservation Lead for help with manual removal of pet from inventory.';
        richMessage.links = [
          {
            linkText: 'Reservation Lead',
            route: 'https://alaskaair.sharepoint.com/sites/customercare/pages/sub.aspx?i=15925',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PET_SSR_DELETE_FAILED_ON_PAST_DATED_PNR:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Pet not removed.';
        richMessage.mainText = 'The itinerary is past-dated and pet cannot be removed.';
        break;
      case MessageKey.PET_SSR_DELETE_FAILED_ON_SIMUTANEOUS_CHANGES:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText =
          'Multiple changes occurred at the same time on the reservation. Call Reservation Lead for help with manual removal of pet from inventory.';
        richMessage.mainText = 'Call Reservation Lead for help with manual removal of pet from inventory.';
        richMessage.links = [
          {
            linkText: 'Reservation Lead',
            route: 'https://alaskaair.sharepoint.com/sites/customercare/pages/sub.aspx?i=15925',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PET_SSR_DELETE_FAILED_ON_UNKNOWN_ERROR:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Ruff time removing pet from inventory due to an unknown error.';
        richMessage.mainText =
          'Pet cancellation remark not added to reservation and pet is holding space. Call Reservation Lead for help with manual removal of pet from inventory.';
        richMessage.links = [
          {
            linkText: 'Reservation Lead',
            route: 'https://alaskaair.sharepoint.com/sites/customercare/pages/sub.aspx?i=15925',
            externalLink: true,
          },
        ];
        break;
      // Dashboard ssr tab messages - end

      case MessageKey.LIMITED_PERMISSIONS:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Limited RAIN functionality due to missing Sabre permissions.';
        richMessage.mainText = this.getFormattedString(
          'RAIN did not receive necessary permissions from Sabre ID: {0}. Try to refresh permissions by logging out and back in again. Still seeing this message? Submit a detailed report through Contact Center Technology.',
          messageArgs ?? []
        );
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            linkText: 'Submit a detailed report through Contact Center Technology.',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.MISSING_OBCINH_PERMISSION:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Limited RAIN functionality due to missing Sabre permissions.';
        richMessage.mainText = this.getFormattedString(
          "RAIN did not receive necessary permissions from Sabre to enable overbooking a flight's class of service. Reach out to a Reservations Supervisor to request a review of Sabre ID: {0} permissions.",
          messageArgs ?? []
        );
        richMessage.closable = false;
        break;
      case MessageKey.ONE_TRAVEL_ADVISORY:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Active Travel Advisory:';
        richMessage.mainText = `Visit Travel Advisories page on AlaskaAir.com to learn more.`;
        richMessage.closable = true;
        richMessage.links = [
          {
            linkText: 'Visit Travel Advisories page on AlaskaAir.com to learn more.',
            route:
              'https://www.alaskaair.com/content/advisories/travel-advisories?int=AS_HomePage_AdvisoryBR_L2||2020_CV_AW||-prodID:Awareness&lid=HomePage_AdvisoryBR_Waiver#flex',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.MULTIPLE_TRAVEL_ADVISORY:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = this.getFormattedString('{0} Active Travel Advisory:', messageArgs ?? []);
        richMessage.mainText = `Visit Travel Advisories page on AlaskaAir.com to learn more.`;
        richMessage.closable = true;
        richMessage.links = [
          {
            linkText: 'Visit Travel Advisories page on AlaskaAir.com to learn more.',
            route:
              'https://www.alaskaair.com/content/advisories/travel-advisories?int=AS_HomePage_AdvisoryBR_L2||2020_CV_AW||-prodID:Awareness&lid=HomePage_AdvisoryBR_Waiver#flex',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.NO_NAME_NUMBER:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = `The name field can't be determined.`;
        richMessage.mainText = `Reissue the ticket in IMAGE.`;
        richMessage.closable = true;
        break;
      case MessageKey.INVOLUNTARY_CHANGE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = `Flight(s) affected by schedule change or irregular operation (IRROP). Advise the guest of changes, verify coupon statuses, and refund Premium Class fee(s), if required.`;
        richMessage.links = [
          {
            linkText: 'schedule change',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16284',
            externalLink: true,
          },
          {
            linkText: 'irregular operation',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16283',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.ACTIVE_CHAT_PAYMENT_LINK:
        this.store.pipe(select(getRoutedConfirmationCode)).subscribe((confCode) => (confirmationCode = confCode));

        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Attention:';
        richMessage.mainText = `This reservation has an active chat payment link that can be resent to the guest: View link`;
        richMessage.closable = false;
        richMessage.links = [
          {
            route: `/reservation/${confirmationCode}/payment-link/view`,
            linkText: 'View link',
          },
        ];
        break;
      case MessageKey.GENERAL:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = 'A unknown system error has occured. If it persists, contact support';
        richMessage.emphasisText = 'System Error:';
        break;
      case MessageKey.NO_SEGMENTS:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = `No active flights on itinerary.`;
        richMessage.emphasisText = 'Seat assignment is not available. ';
        richMessage.closable = false;
        break;
      case MessageKey.NO_PREMIUM_PRICES:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = `Choose seat that does not require payment or assign the seat and add a waiver.`;
        richMessage.emphasisText = 'Paid seat pricing cannot be fetched. ';
        richMessage.closable = false;
        break;
      case MessageKey.SAVE_SYSTEM_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = `An unknown error occurred. Skip seat assignment.`;
        richMessage.emphasisText = 'Seats not saved. ';
        break;
      case MessageKey.AIRPORT_CHECK_IN_WINDOW:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = `Check-in is in progress. An agent at the airport must assign seats or the guest may complete self-serve check-in to select available seats.`;
        richMessage.emphasisText = 'Seats not saved. ';
        richMessage.links = [
          {
            linkText: 'self-serve check-in',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?id=16749',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.SEGMENT_OUT_OF_ORDER:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = `Itinerary is not in order by: flight segment, date of travel, departure/arrival city, and/or minimum connection time. Place itinerary in order, refresh RAIN, and assign seats. Visit Reading History and Action Codes CCM page to learn more.`;
        richMessage.emphasisText = 'Seats not saved. ';
        richMessage.links = [
          {
            linkText: 'Reading History and Action Codes',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16614',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.UNKNOWN_SEAT_AREA:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = `The cabin type not is not recognized. Advise guest they'll be assigned seats at
        check-in or by a CSA at the airport.`;
        richMessage.emphasisText = 'Unable to display:';
        richMessage.closable = false;
        break;
      case MessageKey.SAVE_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = 'Verify seats and re-save.';
        richMessage.emphasisText = 'Seats experienced a delay and did not save. ';
        break;
      case MessageKey.SEAT_MAP_LOAD_ERROR:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Seat map cannot be fetched and may be unavailable. `;
        richMessage.mainText = this.getFormattedString('{0} Skip seat assignment.', messageArgs ?? []);
        richMessage.closable = false;
        break;
      case MessageKey.SEAT_MAP_LOAD_SYSTEM_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = `Skip seat assignment.`;
        richMessage.emphasisText = 'Seat map cannot be fetched and may be unavailable. ';
        richMessage.closable = false;
        break;
      case MessageKey.SEAT_MAP_LOAD_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Seat map cannot be fetched and may be unavailable. `;
        richMessage.mainText = 'Skip seat assignment.';
        richMessage.closable = false;
        break;
      case MessageKey.TRANSACTION_SUCCESSFUL:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Transaction successful:';
        richMessage.mainText = 'Updates are reflected below.';
        break;
      case MessageKey.MANUAL_ASSOCIATION_REVAL_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Ticket revalidation failed:';
        richMessage.mainText = 'Manually revalidate the ticket in IMAGE using the created reservation.';
        break;
      case MessageKey.MANUAL_ASSOCIATION_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Transaction successful:';
        richMessage.mainText = 'a placeholder reservation has been successfully created.';
        break;
      case MessageKey.TICKET_API_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText =
          'Refresh the page to try retrieving the VCR again, or use IMAGERES to review the VCR. Visit Electronic Ticket CCM page to learn more.';
        richMessage.emphasisText = 'Virtual Coupon Record (VCR) experienced turbulence and cannot be shown.';
        richMessage.links = [
          {
            linkText: 'Electronic Ticket',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16331',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'Electronic Ticket',
            format: Format.BOLD,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_INTERNAL_SERVER_ERROR:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'The reissue system is currently down.';
        richMessage.mainText =
          'The flight itinerary has been changed so exit the process and refresh the reservation before trying to reissue again. If the error persists contact Call Center Technology.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_DELETE_OSI_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'FCAA Other Supplementary Information (OSI) not removed.';
        richMessage.mainText =
          'Original FCAA OSI with previous flight details not auto-deleted from reservation. Use IMAGERES to manually remove OSIs that are not needed (SF5/f6, Alt + I). Visit First Class as Ancillary CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'First Class as Ancillary',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16768',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_ADD_OSI_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'FCAA Other Supplementary Information (OSI) not added.';
        richMessage.mainText =
          'FCAA OSI with current flight details not auto-added to reservation. Advise the guest to check-in at the airport on day-of-travel. Visit First Class as Ancillary CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'First Class as Ancillary',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16768',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Ticket reissue successful.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REVAL_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Ticket revalidation failed:';
        richMessage.mainText = 'Manually revalidate the ticket in IMAGE.';
        richMessage.closable = true;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_ITINERARY_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Itinerary update failed:';
        richMessage.mainText = 'Click the "ACCEPT CHANGES" button to try again.';
        richMessage.closable = true;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REVAL_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Ticket revalidation unsuccessful.';
        richMessage.mainText =
          'Ticket experienced a delay and did not revalidate. Verify flight selection and retry ticket revalidation by clicking "CONTINUE".';
        richMessage.closable = true;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_NEED_PHONE_FIELD:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Itinerary update failed:';
        richMessage.mainText = 'Please add a phone field, refresh, and try again.';
        richMessage.closable = true;
        break;
      case MessageKey.EXTRA_SEAT_ERROR:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Attention:';
        richMessage.mainText =
          'Unable to determine the guest that is connected to the Extra Seat (EXST) or Cabin Seat Baggage (CBBG). Some changes to the reservation may encounter errors. Visit Seating CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Seating',
            route: ' https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16192',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_FLIFO:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Unable to find flight information for one or more segments and cannot update reservation itinerary.';
        richMessage.mainText = 'Return to Guest Journey in RAIN and use IMAGERES to complete involuntary change.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_CONNECTION_TIME_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Itinerary update failed:';
        richMessage.mainText = this.getFormattedString('{0}', messageArgs ?? []);
        richMessage.closable = true;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_INVALID_DEPARTURE_DATE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Itinerary update failed:';
        richMessage.mainText = 'Invalid departure date.';
        richMessage.closable = true;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_VCR_PNR_NAME_DO_NOT_MATCH:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Failed to reissue.';
        richMessage.mainText =
          'The flight itinerary has changed. Reissue failed because VCR and PNR name(s) do not match. Please update passenger name(s) and reissue ticket.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_PNR_IN_AAA:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Failed to reissue.';
        richMessage.mainText = 'The PNR in the AAA has been modified.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_OTHER_FEE_MULTIPLE_REPEATS:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Failed to reissue.';
        richMessage.mainText =
          'All unused coupons must be part of the reissue. The flight itinerary has been changed so exit the process, refresh the reservation, and select all unflown flights to be reissued.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_PARTIAL_REISSUE_FAILURE_WITH_FAILED_ASSOCIATION:
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_PARTIAL_REISSUE_FAILURE_WITH_SUCCESSFUL_ASSOCIATION:
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_ASSOCIATION_TIMEOUT:
      case MessageKey.ASSOCIATION_INTERNAL_SERVER_ERROR:
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_DEFAULT:
      case MessageKey.INVOLUNTARY_CHANGE_OG_TICKET_LOOKUP_FAILURE:
      case MessageKey.INVOLUNTARY_CHANGE_GENERATE_REQUEST_FAILURE:
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_TIMEOUT:
      case MessageKey.INVOLUNTARY_CHANGE_FLIGHT_AVAILABILITY_UNKNOWN_FAILURE:
      case MessageKey.INVOLUNTARY_CHANGE_FLIGHT_AVAILABILITY_SYSTEM_FAILURE:
      case MessageKey.INVOLUNTARY_CHANGE_FLIGHT_AVAILABILITY_TIMEOUT:
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_FAILED_RES_LOOKUP_ASSOCIATION:
      case MessageKey.ITIN_UPDATE_INVALID_FLIGHT_NUMBER:
      case MessageKey.ITIN_UPDATE_INVALID_ORIGIN:
      case MessageKey.ITIN_UPDATE_INVALID_DESTINATION:
      case MessageKey.ITIN_UPDATE_INTERNAL_SERVER_ERROR:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = messageDetails?.emphasisText ?? '';
        richMessage.mainText = messageDetails?.mainText ?? '';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_PARTIAL_TRANSFER_SSR:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Failed to transfer some SSRs.';
        richMessage.mainText = 'Manually transfer the missing SSRs.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REISSUE_FAILED_TRANSFER_SSR:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Failed to transfer SSRs.';
        richMessage.mainText = 'Manually transfer the SSRs.';
        richMessage.closable = false;
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REVAL_SUCCESS_FLIGHT_NOT_DISPLAYED:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Ticket revalidation successful';
        richMessage.mainText =
          "New flights couldn't be displayed below. Continue without seats to see the new flights on the guest journey before making additional changes.";
        break;
      case MessageKey.INVOLUNTARY_CHANGE_REVAL_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Ticket revalidation successful';
        richMessage.mainText = '';
        richMessage.closable = false;
        break;
      case MessageKey.ADD_SDC_OSI_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'An error occurred:';
        richMessage.mainText = `The SDC OSI wasn't added successfully, please add it manually.`;
        richMessage.closable = false;
        break;
      case MessageKey.ITIN_UPDATE_UNKNOWN_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'An unknown error occurred.';
        richMessage.mainText =
          'If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.closable = false;
        break;
      case MessageKey.SDC_FLIGHT_REQUEST_FAILED:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Flight shopping request failed.';
        richMessage.mainText =
          'If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.closable = false;
        break;
      case MessageKey.SDC_FEE_PRICING_UNAVAILABLE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Fee pricing unavailable.';
        richMessage.mainText =
          'If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.closable = false;
        break;
      case MessageKey.REVAL_ELIGIBILITY_TYPE_NO_TICKETS:
      case MessageKey.REVAL_ELIGIBILITY_TYPE_COUPONS_NOT_OK:
      case MessageKey.REVAL_ELIGIBILITY_TYPE_OPERATED_BY_OA:
      case MessageKey.REVAL_ELIGIBILITY_TYPE_CHANGE_OF_ROUTING:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = messageDetails?.emphasisText ?? '';
        richMessage.mainText = messageDetails?.mainText ?? '';
        richMessage.closable = false;
        break;
      /**
       * Start add MP failures
       */
      case MessageKey.ADD_MP_INELIGIBLE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Mileage plan number cannot be added to reservation.';
        richMessage.mainText = 'Itinerary has Meal Listing ("MM") flight action code.';
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16614',
            linkText: 'Meal Listing',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.ADD_MP_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number experienced a delay and did not save.';
        richMessage.mainText = 'Refresh the page and try adding the mileage plan number again.';
        break;
      case MessageKey.ADD_MP_DOES_NOT_EXIST_FOR_AIRLINE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = this.getFormattedString('Mileage plan number {0} does not exist on {1}.', messageArgs ?? []);
        richMessage.mainText = 'Verify and re-enter mileage plan number or continue without a mileage plan number.';
        break;
      case MessageKey.ADD_MP_DATA_EXISTS_FOR_PASSENGER:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved.';
        richMessage.mainText =
          'Guest already has a mileage plan number added to this reservation. ' +
          'Change the added mileage plan number by removing the existing number and saving a new number.';
        break;
      case MessageKey.ADD_MP_NAME_MISMATCH:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved.';
        richMessage.mainText = this.getFormattedString(
          'Name on the ticket does not match the name on the mileage plan account: {0}. Verify mileage plan number, try a different number, or continue without a mileage plan number. Visit Passenger Name Record (PNR) CCM page to learn more.',
          messageArgs ?? []
        );
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16319',
            linkText: 'Passenger Name Record',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.ADD_MP_NO_AGREEMENT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved.';
        richMessage.mainText = this.getFormattedString(
          'No active ticketing agreement between Alaska Airlines and {0}. Try a different mileage plan number or continue without a mileage plan number.',
          messageArgs ?? []
        );
        break;
      case MessageKey.ADD_MP_SIMULATENOUS_CHANGES:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved.';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation. Refresh the page, verify and re-enter the mileage plan number.';
        break;
      /**
       * End add MP failures - Start remove MP failures
       */
      case MessageKey.REMOVE_MP_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number experienced a delay and did not remove.';
        richMessage.mainText = 'Refresh the page and try removing the mileage plan number again.';
        break;
      case MessageKey.REMOVE_MP_NOT_FOUND_IN_CONTEXT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not removed.';
        richMessage.mainText = 'Refresh the page, verify and re-enter the mileage plan number.';
        break;
      case MessageKey.REMOVE_MP_SIMULATENOUS_CHANGES:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not removed.';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation. Refresh the page, verify and re-enter the mileage plan number.';
        break;
      /**
       * End remove MP failures
       */
      case MessageKey.SECURE_FLIGHT_INFO_NO_ITIN:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure flight info failed:';
        richMessage.mainText = 'There are no active flights on this itinerary.';
        richMessage.closable = false;
        break;
      case MessageKey.SECURE_FLIGHT_INFO_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure flight info failed:';
        richMessage.mainText = this.getFormattedString('{0}', messageArgs ?? []);
        richMessage.closable = true;
        break;
      case MessageKey.SECURE_FLIGHT_INFO_PARTIAL_SUCCESS:
        richMessage.type = MessageType.WARN;
        richMessage.mainText =
          'Techy wires crossed and partial Secure Flight information has saved successfully. Use IMAGERES to add/change unsaved Secure Flight information.';
        richMessage.closable = true;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16334',
            linkText: 'Use IMAGERES to add/change unsaved Secure Flight information.',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.SECURE_FLIGHT_INFO_UNKNOWN:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText =
          'A connected service is experiencing turbulence and cannot save Secure Flight information. Add/change Secure Flight information using IMAGERES.';
        richMessage.closable = true;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16334',
            linkText: 'Add/change Secure Flight information using IMAGERES.',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.SECURE_FLIGHT_INFO_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = 'Attempt to update secure travel information timed out, please try again.';
        richMessage.closable = true;
        break;
      case MessageKey.CONTACT_TRACING_INFO_NO_ITIN:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing info failed:';
        richMessage.mainText = 'There are no active flights on this itinerary.';
        richMessage.closable = true;
        break;
      case MessageKey.CONTACT_TRACING_INFO_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing info failed:';
        richMessage.mainText = this.getFormattedString('{0}', messageArgs ?? []);
        richMessage.closable = true;
        break;
      case MessageKey.UNCAUGHT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'An uncaught error has occurred:';
        richMessage.mainText = 'If the error persists report it to Contact Center Technology.';
        richMessage.closable = true;
        break;
      case MessageKey.COMPANION_FARE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Guests ticketed on companion fare.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16300',
            linkText: 'companion fare',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.SAVER_FARE_CLASS:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = `Guests ticketed on Saver Fare.`;
        richMessage.closable = false;
        // Remember to set externalLink: true when it is external link
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16683',
            linkText: 'Saver Fare',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.OUTSIDE_CHANNELS:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Reservation ticketed by another source.';
        richMessage.closable = false;
        break;
      case MessageKey.BEREAVEMENT_FARE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Guests ticketed on bereavement fare.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16349',
            linkText: 'bereavement fare',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.NO_ITINERARY:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'No active flights on this itinerary.';
        richMessage.closable = false;
        break;
      case MessageKey.GROUP_RESERVATION:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Guests ticketed on group reservation.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16535',
            linkText: 'group reservation',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.ONEWORLD_AIRLINE_TICKET_SOURCE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = this.getFormattedString(
          'Reservation ticketed by {0}, part of the <b>one</b>world® Alliance.',
          messageArgs ?? []
        );
        richMessage.closable = false;
        break;
      case MessageKey.OTHER_AIRLINE_TICKET_SOURCE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = this.getFormattedString('Reservation ticketed by {0}.', messageArgs ?? []);
        richMessage.closable = false;
        break;
      case MessageKey.COMMERCIAL_ACCOUNT:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Reservation ticketed with a commercial account.';
        richMessage.closable = false;
        break;
      case MessageKey.BULK_OPAQUE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText =
          'Reservation ticketed as restricted, discounted fare. Visit Bulk / Opaque Fares icon CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16350',
            linkText: 'Bulk / Opaque Fares',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.FLIGHT_PASS:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText =
          'Guests ticketed on Flight Pass fare and restrictions may apply. Visit Flight Pass General icon CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16634',
            linkText: 'Flight Pass General',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.STATE_OF_ALASKA_FARE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText =
          'Guests ticketed on State of Alaska (STOA) fare and restrictions may apply. Visit Government Fares icon CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16355',
            linkText: 'Government Fares',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.GOVERNMENT_FARE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText =
          'Guests ticketed on government fare and restrictions may apply. Visit Government Fares icon CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16355',
            linkText: 'Government Fares',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.PARTNER_AWARD:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText =
          'Guests ticketed on partner award fare. Visit Partner Award General Information icon CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16462',
            linkText: 'Partner Award General Information',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.ALASKA_AWARD:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Guests ticketed on Alaska award icon.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16152',
            linkText: 'Alaska award',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.TICKET_TIME_LIMIT:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Ticket time limit is active. Visit Passenger Name Record icon (PNR) CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16319',
            linkText: 'Passenger Name Record',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.TAC:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory: Reservation has invalid ticket time limit (TAC).';
        richMessage.mainText =
          'Changes to the reservation may be unsuccessful. Use IMAGERES to remove TAC by marking the reservation as ticketed (SF5/f7).';
        richMessage.closable = false;
        break;
      case MessageKey.FCAA:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory: Guests purchased FCAA.';
        richMessage.mainText = 'Visit First Class as Ancillary icon (FCAA) CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16768',
            linkText: 'First Class as Ancillary',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.CODESHARE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Reservation has one or more codeshare flights icon.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16221',
            linkText: 'codeshare flights',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.STANDBY_RESERVATION:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Guests booked on standby travel.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16394',
            linkText: 'standby travel',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.DEADHEAD:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation advisory:';
        richMessage.mainText = 'Guests booked as deadhead crew member.';
        richMessage.closable = false;
        break;
      case MessageKey.MINOR_IN_MINOR_SEAT:
        richMessage.type = MessageType.WARN;
        richMessage.mainText = 'Advise UMNR guests that they may be reseated to an aisle seat in a UMNR row, per AS policy.';
        richMessage.emphasisText = this.getFormattedString('{0}, {1}:', messageArgs ?? []);
        break;
      case MessageKey.PREMIUM_CLASS_DOWNGRADE:
        richMessage.type = MessageType.WARN;
        richMessage.mainText = `Advise guest that switching from an upgraded seat to a main cabin seat means
          forfeiting their seat upgrade fee and miss out on the in-flight benefits.`;
        richMessage.emphasisText = this.getFormattedString('{0}, {1}:', messageArgs ?? []);
        break;
      case MessageKey.HANDICAP:
        richMessage.type = MessageType.WARN;
        richMessage.mainText =
          'Disabled guest seat selected. Advise non-qualifying guests that they may be reseated to accommodate disabled guests.';
        richMessage.emphasisText = this.getFormattedString('{0}, {1}:', messageArgs ?? []);
        break;

      case MessageKey.ADULT_IN_MINOR_SEAT:
        richMessage.type = MessageType.WARN;
        richMessage.mainText = 'Advise the guest they may be moved to accommodate junior jetsetters.';
        richMessage.emphasisText = this.getFormattedString('{0}, {1}:', messageArgs ?? []);
        break;
      case MessageKey.WINDOWLESS_WINDOW:
        richMessage.type = MessageType.WARN;
        richMessage.mainText = `This seat has no window, or only partial view.`;
        richMessage.emphasisText = this.getFormattedString('{0}, {1}:', messageArgs ?? []);
        break;
      case MessageKey.NO_CONTIGUOUS:
        richMessage.type = MessageType.ERROR;
        richMessage.mainText = this.getFormattedString(
          `No consecutive seats available for {0}.
        Advise {0} guest to see CSA at airport for check-in.`,
          messageArgs ?? []
        );
        break;
      case MessageKey.OTHER_AIRLINE:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Ticket issued by another airline. ';
        richMessage.mainText = `Paid seat purchase not supported when ticket is issued by another airline. `;
        break;
      case MessageKey.UNSUPPORTED_CARRIER:
        richMessage.type = MessageType.WARN;
        richMessage.mainText = 'Operating carrier must assign seats. Visit Partners CCM page to learn more.';
        richMessage.emphasisText = 'Flight operated by partner/another airline. ';
        richMessage.links = [
          {
            linkText: 'Partners',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16511',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.INSIDE_CHECK_IN_WINDOW:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Check-in is in progress. ';
        richMessage.mainText =
          'An agent at the airport must assign seats or the guest may complete self-serve check-in to select available seats.';
        richMessage.links = [
          {
            linkText: 'self-serve check-in',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?id=16749',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.UNKNOWN_CITY_CODE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'New or unknown city code detected and cannot be fetched. ';
        richMessage.mainText =
          'Skip seat assignment and use IMAGERES to assign seats for this segment. Flight details have been icon auto-magically icon logged for our tech teams to review.';
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'sparkle-filled',
              color: 'white',
            },
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.EXTRA_SEAT_NO_SSRS:
        richMessage.type = MessageType.WARN;
        richMessage.mainText = 'Seat assignment restrictions may apply. Visit Seating CCM page to learn more.';
        richMessage.emphasisText = 'Extra Seat (EXST) and/or Cabin Seat Baggage (CBBG) Special Service Request (SSR) must be added. ';
        richMessage.links = [
          {
            linkText: 'Seating',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16192',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.EXIT_ROW_WITH_MINOR:
        richMessage.type = MessageType.WARN;
        richMessage.mainText =
          'Child on reservation and guests may not meet emergency exit row requirements. Federal Law requires that guests seated in the exit row must be at least 15 years old, meet and agree to all exit row requirements. Visit Emergency Exit Row icon CSM page for requirements.';
        richMessage.textFormats = [
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link',
              color: '#0074c8',
            },
          },
        ];
        richMessage.links = [
          {
            route: 'https://www.alaskasworld.com/groundops/csm/vol2/emergency_exit_row.htm',
            linkText: 'Emergency Exit Row ',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.CAREKIT_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Unable to send compensation.';
        richMessage.mainText =
          'If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.closable = true;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            linkText: 'submitting a detailed report through Contact Center Technology',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.CAREKIT_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Compensation has been requested successfully.';
        richMessage.closable = true;
        break;
      // SSR Messages - start
      case MessageKey.ADD_SSR_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'SSRs successfully added to reservation.';
        richMessage.closable = false;
        break;
      case MessageKey.ADD_SSR_PARTIAL_SUCCESS:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Not all SSRs were added successfully.';
        richMessage.mainText = 'See details below.';
        richMessage.closable = false;
        break;
      case MessageKey.ADD_SSR_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Failed to add SSRs to reservation.';
        break;
      // PETC & AVIH messages - start
      case MessageKey.UNHANDLED_PETS_SSR_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Pets not confirmed for travel.';
        richMessage.mainText =
          'An unknown error occurred. Review requested pet details, remove any failed or successful pet requests, exit "Summary" page, and try adding the pet again. Visit Animals CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126',
            linkText: 'Animals',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PETS_SSR_ADD_REMARKS_FAILED:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Pets not confirmed for travel.';
        richMessage.mainText =
          'Required reservation remarks experienced a delay and failed to auto-add to the reservation. SSR not added and inventory could not be scanned. Exit "Summary" page and add SSRs again. Visit Animals CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126 ',
            linkText: 'Animals CCM',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PETS_SSR_FULL_SUCCESS:
        richMessage.closable = false;
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Furry friends cleared for flight! Pets confirmed and SSRs added successfully.';
        richMessage.mainText = 'You are PAW-fect!';
        break;
      case MessageKey.PETS_SSR_FULL_CREATION_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Pets not confirmed for travel.';
        richMessage.mainText =
          'All SSRs failed to add to the reservation and inventory could not be scanned. Exit "Summary" page and add SSR again. Visit Animal CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126 ',
            linkText: 'Animals CCM',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PETS_SSR_PARTIAL_CREATION_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Some pets are not confirmed for travel.';
        richMessage.mainText =
          'Some SSRs failed to add to the reservation for one or more flights and inventory could not be scanned. If new flights are needed, remove all successful pet requests and add all SSRs again. Visit Animals CCM page to learn more.';
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126 ',
            linkText: 'Animals CCM',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PETS_QUEUE_CALL_FAILED:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Pets not confirmed for travel.';
        richMessage.mainText =
          'Failed to scan pet inventory space. Remove all SSRs, exit "Summary" page and add SSR again. Visit Animals CCM page to learn more.';
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126 ',
            linkText: 'Animals CCM',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PETS_SSR_PARTIAL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Some pets are not confirmed for travel.';
        richMessage.mainText =
          'Requested pet space is unavailable on one or more flights. If new flights are needed, remove all SSRs, including those that were successful. Visit Animals CCM page to learn more.';
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126 ',
            linkText: 'Animals CCM',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.PETS_SSR_FULL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Pets not confirmed for travel.';
        richMessage.mainText = 'Requested pet space is unavailable on all flights. Remove all SSRs. Visit Animals CCM page to learn more.';
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126 ',
            linkText: 'Animals CCM',
            externalLink: true,
          },
        ];
        break;
      // PETC & AVIH messages - end
      // SSR Messages - end

      case MessageKey.NON_REV:
        richMessage.type = MessageType.WARN;
        richMessage.mainText = `Seat map unavailable due to stand-by listing.
            Advise the guest they'll be assigned a seat by a CSA at the airport the day of departure.`;
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_PROCESSING_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Card issuer cannot process payment successfully.`;
        richMessage.mainText = `Try payment again by re-entering card details, using a different card, or refer guest to card issuer.`;
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_INVALID_CARD_NUMBER_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Digit check! Payment is not successful.`;
        richMessage.mainText = `Verify and re-enter the card number.`;
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_INVALID_COUPONS_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Purchase is not successful.`;
        richMessage.mainText = `Itinerary flight segments are out of order and do not match the Virtual Coupon Record (VCR). Reverse changes, use IMAGERES to place itinerary segments in order, and retry purchase.`;
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_CARD_EXPIRED_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Card issuer cannot process payment successfully.`;
        richMessage.mainText = `Verify and re-enter the card expiration date.`;
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_DO_NOT_HONOR_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Card issuer cannot process payment successfully.`;
        richMessage.mainText = `Do not honor purchase. Try payment again by re-entering card details, using a different card, or refer guest to card issuer.`;
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_UNCAUGHT_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `An unknown error occurred and purchase is not successful.`;
        richMessage.mainText = `Try payment again by re-entering card details or using a different card. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.`;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            linkText: 'submitting a detailed report through Contact Center Technology.',
            externalLink: true,
          },
        ];
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_SYSTEM_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Purchase is not successful.`;
        richMessage.mainText = `The ticketing service is experiencing turbulence and is unavailable. Reverse changes and try to purchase at a later time. Error has been logged.`;
        richMessage.closable = false;
        break;
      case MessageKey.PURCHASE_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Purchase is not successful.`;
        richMessage.mainText =
          'Payment experienced a delay and took longer than expected. Try payment again by verifying card details or using a different card.';
        richMessage.closable = false;
        break;

      case MessageKey.CBBG_EXST_LINK_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Guest information cannot be saved`;
        richMessage.mainText = `Unable to link seated guest to Cabin Seat Baggage (CBBG) or Extra Seat (EXST). A new reservation is required. Use the "Cancel" button to remove existing flight selection, return to Dashboard, select "Book flights" and use correct naming format for CBBG/EXST. Visit Seating CCM page to learn more.`;
        richMessage.closable = false;
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16192',
            linkText: 'Seating',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'Seating',
            format: Format.BOLD,
          },
        ];
        break;
      /**
       * Guest Save Messages
       */
      case MessageKey.BIO_INFO_CHECK_ITIN:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure Flight Information (SFI) not saved';
        richMessage.mainText = 'No active flights on itinerary.';
        richMessage.closable = false;
        break;
      case MessageKey.BIO_INFO_DOB_ALREADY_COLLECTED:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure Flight Information (SFI) not saved';
        richMessage.mainText =
          'Date of birth (DOB) previously added to reservation. Update DOB by removing existing DOB from reservation. Then verify and re-enter DOB to save.';
        break;
      case MessageKey.BIO_INFO_INVALID_NAME_NUMBER:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure Flight Information (SFI) not saved';
        richMessage.mainText = 'Unable to determine name field of guest and cannot add SFI. Use IMAGERES to add SFI.';
        break;
      case MessageKey.BIO_INFO_NAME_MISMATCH:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Name mismatch detected.';
        richMessage.mainText = 'First/last name in Secure Flight Information (SFI) and Virtual Coupon Record (VCR) must match travel documentation. Verify first/last name and update SFI/VCR. Visit Secure Flight CCM page to learn more.';
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16334',
            linkText: 'Secure Flight',
            occurrences: [2],
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'Secure Flight',
            occurrences: [2],
            format: Format.BOLD,
          },
        ];
        break;
      case MessageKey.BIO_INFO_SIMULTANEOUS_CHANGES:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure Flight Information (SFI) not saved';
        richMessage.mainText = 'Multiple changes occurred at the same time on the reservation. Refresh the page, verify and re-enter SFI.';
        break;
      case MessageKey.BIO_INFO_TIME_LIMIT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure Flight Information (SFI) not saved';
        richMessage.mainText =
          'Reservation has invalid ticket time limit (TTL). Use IMAGERES to remove ticket time limit by marking the reservation as ticketed (SF5/f7). After removing ticket time limit, verify and re-enter SFI.';
        break;
      case MessageKey.BIO_INFO_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure Flight Information (SFI) not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-enter SFI. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            linkText: 'submitting a detailed report through Contact Center Technology.',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.BIO_INFO_REMOVE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Secure Flight Information (SFI) not removed';
        richMessage.mainText = 'Verify and re-enter SFI.';
        break;
      case MessageKey.MILEAGE_PLAN_DOES_NOT_EXIST:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText = 'The mileage plan number does not exist for the provided airline.';
        break;
      case MessageKey.MILEAGE_PLAN_DATA_EXISTS:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText = 'Guest already has a mileage plan assigned to this reservation.';
        break;
      case MessageKey.MILEAGE_PLAN_NAME_MISMATCH:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText = 'Guest name does not match the name on the mileage plan.';
        break;
      case MessageKey.MILEAGE_PLAN_NO_AGREEMENT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText = 'There is no active ticketing agreement between Alaska Airlines and the provided airline.';
        break;
      case MessageKey.MILEAGE_PLAN_SIMULTANEOUS_CHANGES:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation. Refresh the page, verify and re-enter mileage plan.';
        break;
      case MessageKey.MILEAGE_PLAN_SERVICE_ERROR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText =
          'The service is experiencing turbulence. Verify and re-enter mileage plan number or continue without a mileage plan number.';
        break;
      case MessageKey.MILEAGE_PLAN_PAST_DATE_PNR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText = 'The itinerary is past-dated and a mileage plan number cannot be added.';
        break;
      case MessageKey.MILEAGE_PLAN_TKT_TIME_LIMIT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText =
          'Reservation has invalid ticket time limit (TTL). Use IMAGERES to remove ticket time limit by marking the reservation as ticketed (SF5/f7). After removing ticket time limit, refresh the page, verify and re-enter the mileage plan number.';
        break;
      case MessageKey.MILEAGE_PLAN_INVLD_TAC_DATE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText =
          'Reservation has invalid ticket time limit (TAC). Use IMAGERES to remove TAC by marking the reservation as ticketed (SF5/f7). After removing TAC, refresh the page, verify and re-enter the mileage plan number.';
        break;
      case MessageKey.MILEAGE_PLAN_NEED_ADDRESS:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText =
          'Reservation is ticketed without an address which is required to add a mileage plan number. Add an address to the reservation to add a mileage plan number.';
        break;
      case MessageKey.MILEAGE_PLAN_UPDATE_RESERVATION_PROCESSING_ERROR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText =
          'Reservation took a detour while trying to update. Verify and re-enter mileage plan number or continue without a mileage plan number.';
        break;
      case MessageKey.MILEAGE_PLAN_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-enter mileage plan number or continue without a mileage plan number. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.MILEAGE_PLAN_REMOVE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage plan number not removed';
        richMessage.mainText = 'Verify and re-enter mileage plan number or continue without a mileage plan number.';
        break;
      case MessageKey.KTN_CHECK_ITIN:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Known Traveler Number (KTN) not saved';
        richMessage.mainText = 'No active flights on itinerary.';
        break;
      case MessageKey.KTN_PNR_UPDATED:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Known Traveler Number (KTN) not saved';
        richMessage.mainText = 'Multiple changes occurred at the same time on the reservation. Refresh the page, verify and re-enter KTN.';
        break;
      case MessageKey.KTN_INVALID_COUNTRY_CODE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Known Traveler Number (KTN) not saved';
        richMessage.mainText = 'Invalid country code detected. Verify and re-enter number or continue without a KTN.';
        break;
      case MessageKey.KTN_INVALID_TAC_DATE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Known Traveler Number (KTN) not saved';
        richMessage.mainText =
          'Reservation has invalid ticket time limit (TAC). Use IMAGERES to remove TAC by marking the reservation as ticketed (SF5/f7). After removing TAC, verify and re-enter KTN.';
        break;
      case MessageKey.KTN_PNR_SAVE_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Known Traveler Number (KTN) not saved';
        richMessage.mainText = 'KTN experienced a delay and did not save. Verify and re-save KTN.';
        break;
      case MessageKey.KTN_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Known Traveler Number (KTN) not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-enter number or continue without a KTN. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.KTN_REMOVE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Known Traveler Number (KTN) not removed';
        richMessage.mainText = 'Verify and re-enter number or continue without a KTN.';
        break;
      case MessageKey.REDRESS_CHECK_ITIN:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Redress number not saved';
        richMessage.mainText = 'No active flights on itinerary.';
        break;
      case MessageKey.REDRESS_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Redress number not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-enter Redress number or continue without a Redress number. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.REDRESS_REMOVE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Redress number not removed';
        richMessage.mainText = 'Verify and re-enter Redress number or continue without a Redress number.';
        break;
      case MessageKey.INTERNATIONAL_DOCS_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'International document(s) not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-enter international document or continue without an international document. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.INTERNATIONAL_DOCS_BAD_REQUEST:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'International document(s) not saved';
        richMessage.mainText =
          'Guest information entered and transferred by SHOP is resulting in turbulence with saving International Documents. Continue without International Documents by removing the section, verify, and save all other Secure Flight Information (SFI). Add applicable International Documents before ticketing using IMAGERES (SF5/f6, Alt + A). Visit Secure Flight CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Secure Flight',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16334',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.INTERNATIONAL_DOCS_REMOVE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'International documents not removed';
        richMessage.mainText = 'Verify and re-enter international document information or continue without them.';
        break;
      case MessageKey.INTERNATIONAL_DOCS_COUNTRY_OF_RESIDENCE_REMOVE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Country of Residence not removed from international documents';
        richMessage.mainText = 'Verify and re-enter international document information or try removing them again.';
        break;
      case MessageKey.EMERGENCY_CONTACT_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Emergency contact not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-enter emergency contact or continue without an emergency contact. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.EMERGENCY_CONTACT_REMOVE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Emergency contact not removed';
        richMessage.mainText = 'Verify and re-enter emergency contact or continue without one.';
        break;
      case MessageKey.CONTACT_TRACING_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not saved';
        richMessage.mainText =
          'Contact tracing experienced a delay and did not save. Verify and re-save contact tracing information or continue without contact tracing details.';
        break;
      case MessageKey.CONTACT_TRACING_PNR_UPDATED:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not saved';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation and contact tracing information was not saved. Refresh the page, verify and re-enter contact tracing information.';
        break;
      case MessageKey.CONTACT_TRACING_TAC_DATE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not saved';
        richMessage.mainText =
          'Reservation has invalid ticket time limit (TAC). Use IMAGERES to remove TAC by marking the reservation as ticketed (SF5/f7). After removing TAC, refresh the page, verify and re-enter contact tracing information.';
        break;
      case MessageKey.CONTACT_TRACING_ADD_EMAIL_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not saved';
        richMessage.mainText =
          'An unknown error occurred and contact tracing email was not saved. Verify and re-save contact tracing information or continue without contact tracing details. Visit International Documentation CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'International Documentation',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16262#contact-tracing',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'email',
            format: Format.BOLD,
          },
        ];
        break;
      case MessageKey.CONTACT_TRACING_ADD_PHONE_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not saved';
        richMessage.mainText =
          'An unknown error occurred and contact tracing phone number was not saved. Verify and re-save contact tracing information or continue without contact tracing details. Visit International Documentation CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'International Documentation',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16262#contact-tracing',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'phone number',
            format: Format.BOLD,
          },
        ];
        break;
      case MessageKey.CONTACT_TRACING_ADD_ADDRESS_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not saved';
        richMessage.mainText =
          'An unknown error occurred and contact tracing address was not saved. Verify and re-save contact tracing information or continue without contact tracing details. Visit International Documentation CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'International Documentation',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16262#contact-tracing',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'address',
            format: Format.BOLD,
          },
        ];
        break;
      case MessageKey.GUEST_SAVE_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Save not successful';
        richMessage.mainText =
          'Guest information experienced a delay and did not save. Verify or re-enter all guest information and try to save again.';
        break;
      case MessageKey.LAP_INFANT_PARTIAL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Lap infant partially saved';
        richMessage.mainText = 'Techy wires crossed and lap infant information is not fully saved. Verify and re-save lap infant details.';
        break;
      case MessageKey.LAP_INFANT_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Lap infant experienced a delay and did not save';
        richMessage.mainText = 'Verify and re-save lap infant details.';
        break;
      case MessageKey.LAP_INFANT_ASSOCIATED:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Lap infant Secure Flight Information (SFI) not saved';
        richMessage.mainText = 'An adult or parent aged 13 and above has been previously saved and designated to hold the lap infant.';
        break;
      case MessageKey.LAP_INFANT_REMOVE_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Lap infant not removed';
        richMessage.mainText = 'Verify and try to remove lap infant again.';
        break;
      case MessageKey.LAP_INFANT_REMOVE_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Lap infant experienced a delay and did not remove';
        richMessage.mainText = 'Verify and try to remove lap infant again.';
        break;
      case MessageKey.LAP_INFANT_UNKNOWN:
      case MessageKey.LAP_INFANT_UNKNOWN_WITH_LINK: // Lap infant Add catch all
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Lap infant not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-enter lap infant details. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.LAP_INFANT_REMOVE_UNKNOWN_WITH_LINK: // Lap infant Remove catch all
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Lap infant not removed';
        richMessage.mainText =
          'An unknown error occurred. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.AUTO_FILL_MP_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Auto-fill experienced a delay and did not save';
        richMessage.mainText =
          'Secure Flight Information (SFI) was not transferred from Alaska Mileage PlanTM. Verify Mileage PlanTM number and try auto-fill again or add required SFI manually and save.';
        richMessage.textFormats = [
          {
            targetText: 'TM',
            format: Format.SUPERSCRIPT,
          },
          {
            targetText: 'TM',
            format: Format.SMALLER,
          },
        ];
        break;
      case MessageKey.AUTO_FILL_MP_FULL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Auto-fill transfer unsuccessful';
        richMessage.mainText =
          'Secure Flight Information (SFI) was not transferred from Alaska Mileage PlanTM. Add required SFI manually and save.';
        richMessage.textFormats = [
          {
            targetText: 'TM',
            format: Format.SUPERSCRIPT,
          },
          {
            targetText: 'TM',
            format: Format.SMALLER,
          },
        ];
        break;
      case MessageKey.AUTO_FILL_MP_NAME_MISMATCH:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Auto-fill transfer unsuccessful';
        richMessage.mainText = this.getFormattedString(
          'Name on the ticket does not match the name on the mileage plan account: {0}. Verify mileage plan number, try a different number, or continue without a mileage plan number. Visit Passenger Name Record (PNR) CCM page to learn more.',
          messageArgs ?? []
        );
        richMessage.links = [
          {
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16319',
            linkText: 'Passenger Name Record',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.AUTO_FILL_MP_PARTIAL_TRANSFERED:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Auto-fill partial transfer';
        richMessage.mainText =
          'Auto-fill transferred available information from Alaska Mileage PlanTM to Secure Flight Information (SFI). Review SFI, add new information that was not auto-filled, and save.';
        richMessage.textFormats = [
          {
            targetText: 'TM',
            format: Format.SUPERSCRIPT,
          },
          {
            targetText: 'TM',
            format: Format.SMALLER,
          },
        ];
        break;
      case MessageKey.AUTO_FILL_MP_NOT_FOUND:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Auto-fill transfer unsuccessful';
        richMessage.mainText =
          'Secure Flight Information (SFI) was not transferred from Alaska Mileage PlanTM. Verify and re-enter mileage plan number or continue without a mileage plan number and add required SFI manually.';
        richMessage.textFormats = [
          {
            targetText: 'TM',
            format: Format.SUPERSCRIPT,
          },
          {
            targetText: 'TM',
            format: Format.SMALLER,
          },
        ];
        break;
      case MessageKey.RESERVATION_INFO_SAVE_PARTIAL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Reservation information partially saved';
        richMessage.mainText = 'Techy wires crossed and contact information is not fully saved. Verify and re-save contact information.';
        break;
      case MessageKey.RESERVATION_INFO_SAVE_SIMULTANEOUS_CHANGES:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Reservation information not saved';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation. Refresh the page, verify and re-save contact information.';
        break;
      case MessageKey.RESERVATION_INFO_SAVE_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Reservation information experienced a delay and did not save';
        richMessage.mainText = 'Verify and re-save all contact information.';
        break;
      case MessageKey.RESERVATION_INFO_SAVE_FAILURE_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Reservation information not saved';
        richMessage.mainText =
          'An unknown error occurred. Verify and re-save all contact information. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.INITIAL_BOOKING_NO_PRICE_QUOTE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Price Quote (PQ) experienced a diversion and is not available';
        richMessage.mainText =
          'Complete initial booking flow in RAIN, including guest information. Use IMAGERES to complete payment and ticketing.';
        break;
      case MessageKey.MILEAGE_PLAN_MAINTENANCE:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Mileage plan maintenance in progress';
        richMessage.mainText = 'Add/remove mileage plan numbers is temporarily unavailable. Add or update at a later time.';
        break;
      case MessageKey.FLIGHT_DETAILS_FAILURE_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Zoinks!';
        richMessage.mainText =
          'Unable to retrieve additional flight details like on-time percentage, amenities, and more. Visit Meals/Onboard Services CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Meals/Onboard Services',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16183',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.BAGGAGE_WEIGHT_BASED_CARRIER:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Baggage details could not be returned';
        richMessage.mainText = `Itinerary has another airline flight segment that may use total bag weight to determine baggage allowance. Visit the other airline's website to learn more.`;
        break;
      case MessageKey.BAGGAGE_NO_ITIN:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Reservation has no active itinerary and baggage information is not available';
        richMessage.mainText = 'Review baggage information below or visit Checked Baggage CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Checked Baggage',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16146',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.BAGGAGE_GROUPS:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Group reservation detected';
        richMessage.mainText = 'Review checked baggage fees by visiting Group Baggage Policies and Procedures CCM page.';
        richMessage.links = [
          {
            linkText: 'Group Baggage Policies and Procedures',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16523',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.BAGGAGE_GUEST_COUNT:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Baggage details could not be returned for the number of guests ticketed';
        richMessage.mainText = 'Review baggage information below or visit Checked Baggage CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Checked Baggage',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16146',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.BAGGAGE_GENERIC:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Ruh-roh! Baggage details could not be fetched';
        richMessage.mainText = 'Review baggage information below or visit Checked Baggage CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Checked Baggage',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16146',
            externalLink: true,
          },
        ];
        break;
      /**
       * Ticket Search Messages
       */
      case MessageKey.TICKET_SEARCH_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Unable to load search results.';
        richMessage.mainText = 'Please try searching again to continue.';
        richMessage.closable = false;
        break;
      case MessageKey.TICKET_SEARCH_UNCAUGHT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'An unhandled error occurred.';
        richMessage.mainText = 'If the issue persists report it to Contact Center Technology.';
        richMessage.closable = false;
        break;
      case MessageKey.TICKET_SEARCH_NO_MESSAGE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'An unknown error occurred.';
        richMessage.mainText =
          'If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.closable = false;
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      /**
       * Manual Association Messages
       */
      case MessageKey.MANUAL_ASSOCIATION_DEFAULT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'An unknown error occurred.';
        richMessage.mainText =
          'If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.closable = true;
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.RESERVATION_CREATION_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'The create reservation request has timed out.';
        richMessage.mainText = 'Try your request again and if the error persists contact Call Center Technology.';
        richMessage.closable = true;
        break;
      case MessageKey.RESERVATION_CREATION_SYSTEM_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'The create reservation system is currently down.';
        richMessage.mainText = 'Try your request again and if the error persists contact Call Center Technology.';
        richMessage.closable = true;
        break;
      case MessageKey.RESERVATION_CREATION_NOOP:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'The searched flight route is not operating on the selected date.';
        richMessage.mainText = 'Please go into IMAGE and sell in a flight manually to continue.';
        richMessage.closable = true;
        break;
      case MessageKey.RESERVATION_CREATION_FLIGHT_NUM_NOOP:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `A reservation can't be created.`;
        richMessage.mainText = `The flight number being used to create the reservation is no longer operating in the date range we're attempting to use. Please go into IMAGE and sell in a flight manually.`;
        richMessage.closable = true;
        break;
      case MessageKey.RESERVATION_CREATION_INVALID_CLASS:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `A reservation can't be created.`;
        richMessage.mainText = `The original class of service is no longer available. Please offer a refund or credit to the guest as applicable.`;
        richMessage.closable = true;
        break;
      case MessageKey.RESERVATION_CREATION_INVALID_BOARD_POINT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Unable to create reservation due to an invalid boarding point.';
        richMessage.mainText = 'Complete the process in IMAGE.';
        richMessage.closable = true;
        break;
      case MessageKey.ASSOCIATION_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = this.getFormattedString('Failed to associate to {0}.', messageArgs ?? []);
        richMessage.mainText =
          'The associate ticket request has timed out. Pull up the reservation in IMAGE and manually associate the ticket.';
        richMessage.closable = true;
        break;
      case MessageKey.ASSOCIATION_BEYOND_TRAVEL_VALIDITY:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = this.getFormattedString('Failed to associate to {0}.', messageArgs ?? []);
        richMessage.mainText = 'The date of travel is beyond the ticket validity. Assist the guest in booking a new reservation.';
        richMessage.closable = true;
        break;
      case MessageKey.ASSOCIATION_SYSTEM_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = this.getFormattedString('Failed to associate to {0}.', messageArgs ?? []);
        richMessage.mainText =
          'The associate ticket system is currently down. Pull up the reservation in IMAGE and manually associate the ticket.';
        richMessage.closable = true;
        break;
      case MessageKey.ASSOCIATION_ADVISORY_UNUSED_TRAVEL_APPLIED_TO_FUTURE_TRAVEL:
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Virtual Coupon Record (VCR) is partially used.';
        richMessage.mainText = `Unused ticket value can be applied to future travel.`;
        richMessage.closable = true;
        break;
      case MessageKey.INELIGIBLE_NOT_ALL_COUPONS_OK_OR_USED:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Virtual Coupon Record (VCR) is ineligible for manual association.';
        richMessage.mainText = `Coupons must have an eligible status with at least one coupon in 'OK' status.`;
        richMessage.closable = true;
        break;
      case MessageKey.INELIGIBLE_TICKET_FLOWN_OUT_OF_COUPON_ORDER:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Virtual Coupon Record (VCR) is ineligible for manual association.';
        richMessage.mainText = `Ticket is flown out of coupon order.`;
        richMessage.closable = true;
        break;
      case MessageKey.INELIGIBLE_NOT_ALL_ROUTES_MATCH:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'The selected tickets are not eligible for manual association.';
        richMessage.mainText = 'All routes and class of service must match.';
        richMessage.closable = true;
        break;
      case MessageKey.INELIGIBLE_NOT_ALL_RESERVATIONS_INACTIVE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `This ticket is active or is partially used and a placeholder reservation can't be created.`;
        richMessage.mainText = this.getFormattedString(
          'Please go back to search results and select a fully unused, expired ticket. {0}',
          messageArgs ?? []
        );
        richMessage.closable = true;
        break;
      case MessageKey.INELIGIBLE_NOT_ALL_SEGMENT_STATUS_OK:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `The selected tickets are not eligible for manual association due to the segment status of a standby ticket.`;
        richMessage.mainText = `Advise the guest to rebook through their standby employee channels.`;
        richMessage.closable = true;
        break;
      /**
       * Mileage Plan Enrollment Messages
       */
      case MessageKey.MILEAGE_PLAN_ENROLLMENT_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage Plan™ (MP™) enrollment experienced a delay and did not create an MP™ number.';
        richMessage.mainText = 'Verify MP™ information and retry enrollment.';
        break;
      case MessageKey.MILEAGE_PLAN_ENROLLMENT_SUCCESS:
        richMessage.closable = false;
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Mileage Plan™ (MP™) successfully created and perks are a flight away.';
        richMessage.mainText = this.getFormattedString(`MP™ number: {0}`, messageArgs ?? []);
        if (messageArgs && messageArgs.length > 0 && typeof messageArgs[0] === 'string') {
          richMessage.links = [
            {
              linkText: messageArgs[0],
              route: `/mileage-plan-profile-search/${messageArgs[0]}/profile/summary`,
              externalLink: false,
            },
          ];
        }
        break;
      case MessageKey.MILEAGE_PLAN_ENROLLMENT_MEMBER_EXIST:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Existing Mileage Plan™ (MP™) account found.';
        richMessage.mainText = this.getFormattedString('MP™ number: {0}', messageArgs ?? []);
        if (messageArgs && messageArgs.length > 0 && typeof messageArgs[0] === 'string') {
          richMessage.links = [
            {
              linkText: messageArgs[0],
              route: `/mileage-plan-profile-search/${messageArgs[0]}/profile/summary`,
              externalLink: false,
            },
          ];
        }
        break;
      case MessageKey.NEWSFEED_ERROR:
        richMessage.closable = true;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Updates experienced turbulence and cannot be fetched';
        richMessage.mainText = `Try to fetch the latest RAIN updates again by clicking the Chrome refresh icon or pressing the F5 key to refresh the page.`;
        break;
      case MessageKey.ADVISORY_ERROR:
        richMessage.closable = true;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Advisories experienced turbulence and cannot be fetched';
        richMessage.mainText = `Visit the Animals CCM page to learn more.`;
        richMessage.links = [
          {
            linkText: 'Animals',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16126',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.SEAT_UPGRADE_INVALID_COUPON_STATUS:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = `Payment unavailable.`;
        richMessage.mainText = `One or more Virtual Coupon Records (VCRs) may have coupons with ineligible statuses. Return to the seat map to remove unpaid seats. Review the VCR, update statuses of eligible coupons, and return to the seat map to retry paid seat purchase.
        Visit Seating CCM page to learn more.`;
        richMessage.links = [
          {
            linkText: 'Seating',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16192',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.SEAT_UPGRADE_TICKET_VALIDATION_ERROR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Flight coupon validation experienced a delay.`;
        richMessage.mainText = `Virtual Coupon Records (VCRs) could not be validated and payment is unavailable. The error has been automatically logged. Retry paid seat purchases by returning to the seat map and reassigning seats.`;
        break;
      case MessageKey.MP_LOOKUP_PARTIAL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = `Mileage Plan™ returned partial account details`;
        richMessage.mainText = `Mileage PlanTM returned previously saved, available information. Some account details may be unavailable.`;
        richMessage.textFormats = [
          {
            targetText: 'TM',
            format: Format.SUPERSCRIPT,
          },
          {
            targetText: 'TM',
            format: Format.SMALLER,
          },
        ];
        break;
      case MessageKey.MP_LOOKUP_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Mileage Plan™ (MP™) number not returned';
        richMessage.mainText = `MP™ experienced a delay. Verify MP™ number and retry lookup.`;
        break;
      case MessageKey.MP_LOOKUP_NOT_FOUND:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Mileage Plan™ (MP™) number not found`;
        richMessage.mainText = `Verify MP™ number and retry lookup.`;
        break;
      case MessageKey.RESERVATION_LOOKUP_DOES_NOT_EXIST:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Confirmation code not found`;
        richMessage.mainText = `Active confirmation code not found. Verify confirmation code or use Alaska Airlines ticket number to search for the Virtual Coupon Record (VCR). Visit Electronic Ticket CCM page to learn more.`;
        richMessage.links = [
          {
            linkText: 'Electronic Ticket',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16331',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.RESERVATION_LOOKUP_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = `Confirmation code not returned`;
        richMessage.mainText = `Reservation lookup experienced a delay. Verify confirmation code and retry search.`;
        break;
      case MessageKey.RESERVATION_LOOKUP_FORMAT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Confirmation code not returned';
        richMessage.mainText =
          'Character check! Confirmation codes must be 6 characters and without special characters. Verify confirmation code and retry search.';
        break;
      case MessageKey.UNKNOWN_WITH_LINK:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'An unknown error occurred.';
        richMessage.mainText =
          'If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      case MessageKey.GUEST_REMOVE_TIMEOUT:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Remove experienced a delay and was not successful';
        richMessage.mainText = 'Refresh the page and try to remove again.';
        break;
      case MessageKey.REMOVE_CONTACT_TRACING_FULL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not removed';
        richMessage.mainText =
          'An unknown error occurred and contact tracing information not removed. Verify contact tracing information and retry removal.';
        break;
      case MessageKey.REMOVE_CONTACT_TRACING_PARTIAL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information partially removed';
        richMessage.mainText =
          'Techy wires crossed and contact tracing information is not fully removed. Verify and re-save contact tracing information or retry removal.';
        break;
      case MessageKey.REMOVE_CONTACT_TRACING_SIMULTANEOUS_CHANGES:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not removed';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation and contact tracing information was not removed. Refresh the page, verify contact tracing information and retry removal.';
        break;
      case MessageKey.REMOVE_CONTACT_TRACING_TAC_ERROR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Contact tracing information not removed';
        richMessage.mainText =
          'Reservation has invalid ticket time limit (TAC). Use IMAGERES to remove TAC by marking the reservation as ticketed (SF5/f7). After removing TAC, refresh the page, verify contact tracing information and retry removal.';
        break;
      case MessageKey.TCP_OSI_PARTIAL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.WARN;
        richMessage.emphasisText = 'Not all TCPs were added successfully.';
        richMessage.mainText = 'See details below.';
        break;
      case MessageKey.TCP_OSI_FULL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Failed to add TCPs to reservation.';
        richMessage.mainText = '';
        break;
      case MessageKey.PAYMENT_LINK_GENERATION_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Payment link generation failed.';
        richMessage.mainText = '';
        break;

      //DELETE REMARKS
      case MessageKey.DELETE_REMARK_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.mainText =
          'Remarks successfully removed from the reservation and etched into the digital archives under Passenger Name Record (PNR) History.';
        break;
      case MessageKey.DELETE_REMARK_TIMEOUT_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Remarks experienced turbulence and were not removed';
        richMessage.mainText = 'Refresh the page and try to remove remarks again.';
        break;
      case MessageKey.DELETE_REMARK_SIMULATENOUS_CHANGES_ERROR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Remarks not removed';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation. Refresh the page and try to remove remarks again.';
        break;
      case MessageKey.DELETE_REMARK_FULL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Remarks not removed';
        richMessage.mainText =
          'An unknown error occurred. Refresh the page and try to remove remarks again. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      //END DELETE REMARKS

      //ADD REMARKS
      case MessageKey.ADD_REMARK_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.mainText =
          'Remarks successfully added to the reservation and etched into the digital archives under Passenger Name Record (PNR) History.';
        break;
      case MessageKey.ADD_REMARK_TIMEOUT_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Remarks experienced turbulence and were not added';
        richMessage.mainText = 'Refresh the page and try to add remarks again.';
        break;
      case MessageKey.ADD_REMARK_SIMULATENOUS_CHANGES_ERROR:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Remarks not added';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation. Refresh the page and try to add remarks again.';
        break;
      case MessageKey.ADD_REMARK_FULL_FAILURE:
        richMessage.closable = false;
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Remarks not added';
        richMessage.mainText =
          'An unknown error occurred. Refresh the page and try to add remarks again. If the issue continues, help us investigate by submitting a detailed report through Contact Center Technology.';
        richMessage.links = [
          {
            linkText: 'submitting a detailed report through Contact Center Technology',
            route: 'https://alaskaair.sharepoint.com/sites/CallCenterIssues/SitePages/Home.aspx',
            externalLink: true,
          },
        ];
        break;
      // END ADD REMARKS

      //ADD RAIN BOOKING REMARKS
      case MessageKey.ADD_RAIN_BOOKING_REMARKS_SUCCESS:
        richMessage.type = MessageType.SUCCESS;
        richMessage.emphasisText = 'Reservation Remarks successfully added';
        richMessage.mainText =
          'Some itineraries may require additional remarks to be added before ticketing. Visit Passenger Name Record (PNR) CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Passenger Name Record (PNR)',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16319',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'Passenger Name Record (PNR)',
            format: Format.BOLD,
          },
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.ADD_RAIN_BOOKING_REMARKS_FAILURE:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Reservation Remarks not added';
        richMessage.mainText =
          'Reservation Remarks experienced a delay and failed to auto-add to the reservation. Visit Passenger Name Record (PNR) CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Passenger Name Record (PNR)',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16319',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'Passenger Name Record (PNR)',
            format: Format.BOLD,
          },
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.ADD_RAIN_BOOKING_REMARKS_SIMULATENOUS_CHANGES:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Reservation Remarks not added';
        richMessage.mainText =
          'Multiple changes occurred at the same time on the reservation and Reservation Remarks failed to auto-add to the reservation. Visit Passenger Name Record (PNR) CCM page to learn more.';
        richMessage.links = [
          {
            linkText: 'Passenger Name Record (PNR)',
            route: 'https://alaskaair.sharepoint.com/sites/reservations/Pages/sub.aspx?i=16319',
            externalLink: true,
          },
        ];
        richMessage.textFormats = [
          {
            targetText: 'Passenger Name Record (PNR)',
            format: Format.BOLD,
          },
          {
            targetText: 'icon',
            format: Format.AURO_ICON,
            auroIconAttribute: {
              category: 'interface',
              name: 'external-link-stroke',
              color: '#0074c8',
            },
          },
        ];
        break;
      case MessageKey.RESHOP_FLIGHT_SEARCH_ERROR:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Reshop flight search failed';
        richMessage.mainText = 'Something went wrong. Please try again.';
        break;
      case MessageKey.RESHOP_FLIGHT_SEARCH_TIMEOUT:
        richMessage.type = MessageType.ERROR;
        richMessage.emphasisText = 'Flight search experienced turbulence and results could not be fetched.';
        richMessage.mainText = 'Try to re-fetch flight results by returning to flight search, verify criteria, and search again.';
        break;
      //END ADD RAIN BOOKING REMARKS
      default:
        richMessage = null;
        throw new Error(`Unhandled message key [${MessageKey[key]}].`);
    }
    return richMessage;
  }

  private getFormattedString(format: string, args: any[]): string {
    let formatted = format;
    const len = args ? args.length : 0;
    for (let i = 0; i < len; i++) {
      formatted = formatted.replace(RegExp('\\{' + i + '\\}', 'g'), args[i]);
    }
    return formatted;
  }
}
